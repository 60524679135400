import * as React from "react";

import Stack from "@mui/material/Stack";
import { HiPlus } from "react-icons/hi2";
import {
  PageContainer,
  PageTitle,
} from "../../../layouts/common/general/components";
import Button from "@mui/material/Button";
import DataImport from "../../../layouts/common/import/view";
import RouterBreadcrumbs from "../../../layouts/common/breadcrumbs/view";
import Table from "./table/view";
import AddUser from "../new-user/view";

export default function Users() {
  const [importDialog, setImportDialog] = React.useState({
    open: false,
    resource: "user",
  });

  const [addUserDialogOpen, setAddUserDialogOpen] = React.useState();

  return (
    <PageContainer>
      <Stack
        direction="row"
        style={{
          alignItems: "baseline",
          justifyContent: "space-between",
          mt: 4,
        }}
      >
        <Stack>
          <Stack>
            <PageTitle title="Users"></PageTitle>
          </Stack>
          <RouterBreadcrumbs />
        </Stack>
        <Stack direction="row" sx={{ alignItems: "center" }}>
          <Button
            onClick={() => {
              setAddUserDialogOpen(true);
            }}
            disableElevation
            sx={{
              textTransform: "none",
              height: 40,
              borderRadius: 32,
            }}
            startIcon={<HiPlus />}
          >
            Add User
          </Button>
        </Stack>
      </Stack>

      <Table />
      <AddUser open={addUserDialogOpen} setOpen={setAddUserDialogOpen} />
      <DataImport dialog={importDialog} setDialog={setImportDialog} />
    </PageContainer>
  );
}
