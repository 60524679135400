import * as React from "react";
import IconButton from "@mui/material/IconButton";
import TemporaryDrawer from "../root/menu.mobile";
import moment from "moment";
import { HiUsers } from "react-icons/hi2";

import Stack from "@mui/material/Stack";
import { MenuRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import NotificationCounter from "../../components/notificationCounter";
import logo from "../../assets/logo_action_aid.png";
import { Typography } from "@mui/material";

export default function TopBar({ setNotificationsOpen }) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const navigate = useNavigate();
  return (
    <Stack
      direction="row"
      sx={{
        pt: 3,
        pb: 2,
        flex: "0 0 auto",
        pl: { xs: 2, sm: 2, md: 0, lg: 0 },
        pr: { xs: 2, sm: 2, md: 4, lg: 4 },
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <IconButton
        onClick={() => {
          setState({ left: true });
        }}
        sx={{
          display: { xs: "flex", sm: "flex", md: "none", lg: "none" },
          alignSelf: "center",
        }}
      >
        <MenuRounded />
      </IconButton>

      <Stack
        sx={{
          ml: 6,
          display: { xs: "none", sm: "none", md: "flex", lg: "flex" },
        }}
      >
        <Stack
          sx={{
            p: 1.5,
            borderRadius: 32,
            border: 1,
            background: "black",
            py: 1,
            color: "white",
          }}
        >
          <Typography variant="caption">
            <b>{moment(new Date()).format("LL")}</b>
          </Typography>
        </Stack>
      </Stack>
      <Stack
        direction="row"
        sx={{
          alignItems: "center",
          display: "none",
        }}
      >
        <IconButton
          onClick={() => {
            navigate("/users");
          }}
          sx={{ mr: 1 }}
          size="medium"
          color="inherit"
        >
          <HiUsers />
        </IconButton>
        <NotificationCounter setNotificationsOpen={setNotificationsOpen} />
      </Stack>

      <TemporaryDrawer state={state} setState={setState} />
    </Stack>
  );
}
