import {
  Stack,
  Typography,
  IconButton,
  Collapse,
  Divider,
  LinearProgress,
} from "@mui/material";
import React from "react";
import AddOrUpdateManagerialLine from "./addOrUpdate";
import { HiPlusCircle, HiWrench } from "react-icons/hi2";
import { MdDelete } from "react-icons/md";
import { useGetManagerialLinesQuery } from "../../../api/api-endpoints/managerialLine";
import { responsiveProps } from "../../../utils/functions";

export default function PlatformSettings() {
  const {
    data: lines = [],
    isLoading,
    isFetching,
  } = useGetManagerialLinesQuery();
  const loading = isLoading || isFetching;

  const [openAdd, setOpenAdd] = React.useState(false);

  return (
    <>
      <Stack
        sx={{
          border: 1,
          borderStyle: "dashed",
          borderColor: "rgb(0, 0, 0, 0.1)",
          p: responsiveProps(2, 2, 2, 4, 4, 4),
          borderRadius: 3,
          mt: 2,
          boxShadow: "5px 5px 24px 1px rgba(0, 0, 0, 0.05)",
        }}
      >
        <Stack
          alignItems="center"
          justifyContent="space-between"
          direction="row"
        >
          <Stack>
            <Typography variant="h6">
              <b>Teams</b>
            </Typography>
            <Typography variant="caption">
              {lines ? `${lines.length} active` : "Nothing here yet"}
            </Typography>
          </Stack>
          <IconButton
            onClick={() => {
              setOpenAdd(true);
            }}
            color="success"
          >
            <HiPlusCircle />
          </IconButton>
        </Stack>
        <Collapse in={lines && !loading}>
          <Divider sx={{ mt: 2, mb: 2, opacity: 0.15 }} />
        </Collapse>
        <Collapse in={loading}>
          <LinearProgress
            color="inherit"
            sx={{ mt: 2, mb: 2, borderRadius: 32 }}
          />
        </Collapse>
        <Collapse in={lines && !loading}>
          <Stack spacing={3} sx={{}}>
            {lines.map(({ lineManager, name, createdAt }) => (
              <Stack>
                <Stack sx={{ flex: 1 }}>
                  <Typography variant="body1">{name}</Typography>
                  <Typography sx={{ color: "#00322F" }} variant="caption">
                    Managed by{" "}
                    {`${lineManager.firstName} ${lineManager.lastName}`}
                  </Typography>
                </Stack>
                <Stack alignItems="center" direction="row" spacing={1}>
                  <IconButton size="small">
                    <HiWrench />
                  </IconButton>
                  <IconButton size="small">
                    <MdDelete />
                  </IconButton>
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Collapse>
      </Stack>
      <AddOrUpdateManagerialLine
        open={openAdd}
        onClose={() => {
          setOpenAdd(false);
        }}
      />
    </>
  );
}
