import * as React from "react";

import Stack from "@mui/material/Stack";

import { HiPlus } from "react-icons/hi2";
import Button from "@mui/material/Button";
import NewProduct from "../addOrUpdate";
import Table from "./table/view";
import RouterBreadcrumbs from "../../../layouts/common/breadcrumbs/view";
import {
  PageTitle,
  PageContainer,
} from "../../../layouts/common/general/components";

export default function Products() {
  const [open, setOpen] = React.useState(false);

  return (
    <PageContainer>
      <Stack
        direction="row"
        style={{
          alignItems: "baseline",
          justifyContent: "space-between",
          mt: 4,
        }}
      >
        <Stack>
          <Stack>
            <PageTitle title="Products" />
          </Stack>
          <RouterBreadcrumbs />
        </Stack>
        <Stack direction="row" sx={{ alignItems: "center" }}>
          <Button
            onClick={() => {
              setOpen(true);
            }}
            disableElevation
            sx={{
              textTransform: "none",
              height: 40,
              borderRadius: 32,
            }}
            startIcon={<HiPlus />}
          >
            Add Product
          </Button>
        </Stack>
      </Stack>

      <Table />
      <NewProduct
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </PageContainer>
  );
}
