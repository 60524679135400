import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  Route,
} from "react-router-dom";

import ErrorPage from "./pages/error-page";
import Root from "./layouts/root/view";
import Account from "./pages/account/view";
import Outlets from "./pages/outlets/view";
import ManageButchery from "./pages/outlets/manage-outlet/view";
import ManageOrderExternally from "./pages/external-order";
import Products from "./pages/products/list/view";
import Orders from "./pages/orders/list/view";
import Settings from "./pages/settings";
import Reporting from "./pages/reporting/view";
import Signup from "./pages/accounts/signup/view";
import Signin from "./pages/accounts/sign-in/view";
import Users from "./pages/users/list/view";
import ViewUser from "./pages/users/view-user/view";
import Join from "./pages/accounts/join/view";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./state/store/store";
import Auth from "./pages/auth";
import Unauthorized from "./pages/unauthorized";

import "./index.css";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";

let theme = createTheme({
  palette: {
    primary: {
      main: "#159E43",
    },
    secondary: {
      main: "#00FF13",
    },
    success: {
      main: "#00B431",
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      xsm: 480, // New breakpoint between xs and sm
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

theme = responsiveFontSizes(theme);

const App = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route errorElement={<ErrorPage />}>
        <Route path="/" element={<Root />}>
          <Route path="account" element={<Account />}></Route>
          <Route path="products" element={<Auth allowedPermissions={[]} />}>
            <Route index element={<Products />} />
          </Route>
          <Route path="orders" element={<Auth allowedPermissions={[]} />}>
            <Route index element={<Orders />} />
          </Route>

          <Route path="reporting" element={<Auth allowedPermissions={[]} />}>
            <Route index element={<Reporting />} />
          </Route>
          <Route path="settings" element={<Auth allowedPermissions={[]} />}>
            <Route index element={<Settings />} />
          </Route>
          <Route path="users" element={<Auth allowedPermissions={[]} />}>
            <Route index element={<Users />} />

            <Route path=":userID" element={<ViewUser />} />
          </Route>

          <Route path="outlets" element={<Auth allowedPermissions={[]} />}>
            <Route index element={<Outlets />} />
            <Route path=":id" element={<ManageButchery />} />
          </Route>

          <Route path="unauthorized" element={<Unauthorized />}></Route>

          <Route path="*" element={<ErrorPage />}></Route>
        </Route>
        <Route path="signup" element={<Signup />}></Route>
        <Route path="join" element={<Join />}></Route>
        <Route path="login" element={<Signin />}></Route>
        <Route path="manage-order" element={<ManageOrderExternally />}></Route>
      </Route>
    )
  );

  //
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate
          loading={
            <div>
              <p>Loading...</p>
            </div>
          }
          persistor={persistor}
        >
          <RouterProvider router={router} />
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
