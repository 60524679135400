import { apiSlice } from "../api.service";
let params = { limit: 25, skip: 0 };

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrders: builder.query({
      query: (options) => {
        params = options;
        return {
          url: `/order?${new URLSearchParams(options).toString()}`,
          method: "GET",
        };
      },
      providesTags: [{ type: "Order", id: "LIST" }],
    }),
    getOrder: builder.query({
      query: (orderID) => {
        return { url: `/order/${orderID}`, method: "GET" };
      },
      providesTags: (result, error, arg) => [{ type: "Order", id: arg }],
    }),

    editOrder: builder.mutation({
      query: (data) => ({
        url: `/order/${data._id}`,
        method: "PATCH",
        body: data.order,
        invalidatesTags: [{ type: "Order", id: "LIST" }],
      }),
      async onQueryStarted({ _id, order }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedOrder } = await queryFulfilled;

          try {
            dispatch(
              apiSlice.util.updateQueryData("getOrders", params, (draft) => {
                const order = draft.find((e) => e._id === updatedOrder._id);
                if (order) {
                  Object.keys(updatedOrder).forEach((key) => {
                    order[key] = updatedOrder[key];
                  });
                }
              })
            );
          } catch (e) {}
          dispatch(
            apiSlice.util.updateQueryData("getOrder", _id, (draft) => {
              Object.assign(draft, updatedOrder);
            })
          );
        } catch {}
      },
    }),
    editOrderExternally: builder.mutation({
      query: (data) => ({
        url: `/order-external/${data._id}`,
        method: "PATCH",
        body: data.order,
      }),
      async onQueryStarted({ _id }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedOrder } = await queryFulfilled;
          console.log("Updated", updatedOrder);

          dispatch(
            apiSlice.util.updateQueryData("getOrder", _id, (draft) => {
              console.log("Draft");
              Object.assign(draft, updatedOrder);
            })
          );
        } catch {}
      },
    }),

    deleteOrder: builder.mutation({
      query: (_id) => ({
        url: `/order/${_id}`,
        method: "DELETE",
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetOrdersQuery,
  useGetOrderQuery,
  useLazyGetOrderQuery,
  useEditOrderMutation,
  useDeleteOrderMutation,
  useEditOrderExternallyMutation,
} = extendedApi;
