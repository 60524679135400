import React from "react";
import { IconButton, Badge } from "@mui/material";
import {} from "react-icons/ci";
import { useGetAlertCountQuery } from "../api/api-endpoints/alerts";
import { setNotificationCount } from "../state/slices/notify";
import { useSelector, useDispatch } from "react-redux";
import { HiBell } from "react-icons/hi2";

function NotificationCounter({ setNotificationsOpen }) {
  const notificationCount = useSelector((state) => state.notificationCount);
  const dispatch = useDispatch();
  const { data } = useGetAlertCountQuery();

  React.useEffect(() => {
    if (data?.unreadCount) {
      dispatch(setNotificationCount(data?.unreadCount));
    }
  }, [data, dispatch]);

  return (
    <IconButton
      onClick={() => {
        setNotificationsOpen(true);
      }}
    >
      <Badge badgeContent={notificationCount} color="error">
        <HiBell />
      </Badge>
    </IconButton>
  );
}

export default NotificationCounter;
