import Typography from "@mui/material/Typography";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Button, DialogActions } from "@mui/material";
import { HiCheck } from "react-icons/hi2";

const ConfirmationDialog = ({ dialog, setDialog }) => {
  return (
    <Dialog
      PaperProps={{
        sx: {
          p: 3,
          borderRadius: 3,
        },
      }}
      onClose={() => {
        setDialog((dialog) => ({ ...dialog, open: false }));
      }}
      fullWidth
      maxWidth="xs"
      open={dialog.open}
    >
      <DialogTitle>
        <b>Confirm action</b>
      </DialogTitle>
      <DialogContent sx={{}}>{dialog.msg}</DialogContent>
      <DialogActions sx={{ pb: 2 }}>
        <Button
          startIcon={<HiCheck />}
          disableElevation
          onClick={dialog.action}
          color="success"
          sx={{
            textTransform: "none",
            borderRadius: 32,
            minWidth: 100,
            height: 50,
          }}
        >
          {dialog.positive || "Continue"}
        </Button>
        <Button
          disableElevation
          onClick={() => {
            setDialog((dialog) => ({ ...dialog, open: false }));
          }}
          color="warning"
          sx={{
            textTransform: "none",
            borderRadius: 32,
            minWidth: 100,
            height: 50,
          }}
        >
          {dialog.negative || "Cancel"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
