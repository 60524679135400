import { createSlice } from "@reduxjs/toolkit";

const progresslice = createSlice({
  name: "uploadProgress",
  initialState: {
    trip_add: [],
    product_add: [],
    product_edit: [],
    new_bill: [],
  },
  reducers: {
    setUploadProgress(state, action) {
      const {
        resource = "vehicle_add",
        key,
        progress,
        status,
      } = action.payload;
      console.log("", action.payload);
      const index = state[resource].findIndex((a) => a.key === key);
      if (index === -1) {
        state[resource].push({ key, progress });
      } else {
        state[resource][index] = { key, progress, status };
      }
    },
    removeItemFromProgress(state, action) {
      let resource = action.payload.resource || "vehicle_add";
      return {
        ...state,
        [resource]: [
          ...state[resource].filter((a) => a.key !== action.payload.key),
        ],
      };
    },
    resetUploadProgress(state, action) {
      return {
        vehicle_add: [],
        quotation_add: [],
        vehicle_edit: [],
        product_add: [],
        product_edit: [],
      };
    },
  },
});

export const {
  setUploadProgress,
  removeItemFromProgress,
  resetUploadProgress,
} = progresslice.actions;
export default progresslice.reducer;
