import * as React from "react";
import Stack from "@mui/material/Stack";
import { Button } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import Table from "./table/view";
import AddLocation from "./add-outlet/view";
import AddOrUpdateButchery from "./addOrUpdate";
import RouterBreadcrumbs from "../../layouts/common/breadcrumbs/view";
import { AddCircle } from "@mui/icons-material";
import {
  PageTitle,
  PageContainer,
} from "../../layouts/common/general/components";

export default function Butcheries() {
  const [addLocationDrawerOpen, setAddLocationDrawerOpen] =
    React.useState(false);

  const [open, setOpen] = React.useState(false);

  return (
    <PageContainer>
      <Stack
        direction="row"
        style={{
          alignItems: "baseline",
          justifyContent: "space-between",
        }}
      >
        <Stack>
          <Stack>
            <PageTitle title="Butcheries" />
          </Stack>
          <RouterBreadcrumbs />
        </Stack>

        <Stack spacing={1} sx={{ alignItems: "center" }} direction="row">
          <Button
            onClick={() => {
              setOpen(true);
              //  setAddLocationDrawerOpen(true);
            }}
            sx={{ textTransform: "none", borderRadius: 32 }}
            variant="text"
            startIcon={<AddCircle />}
          >
            Add Butchery
          </Button>
        </Stack>
      </Stack>
      <Table />

      <React.Fragment key={"right-2"}>
        <Drawer
          elevation={0}
          anchor={"right"}
          open={addLocationDrawerOpen}
          onClose={() => {
            setAddLocationDrawerOpen(false);
          }}
        >
          <AddLocation setOpen={setAddLocationDrawerOpen} />
        </Drawer>
      </React.Fragment>
      <AddOrUpdateButchery
        onClose={() => {
          setOpen(false);
        }}
        open={open}
        setOpen={setOpen}
      />
    </PageContainer>
  );
}
