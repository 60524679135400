import { apiSlice } from "../api/api.service";
import { authHeader } from "../api/auth-header";
import axios from "axios";
import { constants } from "../config/constants";

const mappings = {
  product: {
    tag: {
      type: "Product",
      id: "LIST",
    },
    listFetcher: "getProducts",
    itemFetcher: "getProduct",
    endpoint: "/products",
  },
  user: {
    tag: {
      type: "User",
      id: "LIST",
    },
    listFetcher: "getUsers",
    itemFetcher: "getUser",
    endpoint: "/users",
  },
  vendor: {
    tag: {
      type: "Vendor",
      id: "LIST",
    },
    listFetcher: "getVendors",
    itemFetcher: "getVendor",
    endpoint: "/vendors",
  },
  location: {
    tag: {
      type: "Location",
      id: "LIST",
    },
    listFetcher: "getLocations",
    itemFetcher: "getLocation",
    endpoint: "/locations",
  },
  requisition: {
    tag: {
      type: "Requisition",
      id: "LIST",
    },
    listFetcher: "getRequisitions",
    itemFetcher: "getRequisition",
    endpoint: "/requisitions",
  },
  lineItem: {
    tag: {
      type: "Line Item",
      id: "LIST",
    },
    listFetcher: "getLineItems",
    itemFetcher: "getLineItem",
    endpoint: "/line-items",
  },
  purchaseOrder: {
    tag: {
      type: "Purchase Order",
      id: "LIST",
    },
    listFetcher: "getPurchaseOrders",
    itemFetcher: "getPurchaseOrder",
    endpoint: "/purchase-orders",
  },
  invoice: {
    tag: {
      type: "Invoice",
      id: "LIST",
    },
    listFetcher: "getInvoices",
    itemFetcher: "getInvoice",
    endpoint: "/invoices",
  },
};

const refillList = async (options, api) => {
  let authorization = authHeader();
  const user = api.getState().user.user;
  let skip = "";
  let queryTail = "";
  let remaining_items = 0;
  let skipRefill = false;

  const pagination = api.getState().pagination[options.resource];
  let unchanged = {};

  const { rowsPerPage, skip_id, q, page, filters } = pagination;
  let params = {
    skip: q.trim() === "" ? skip_id : page * rowsPerPage,
    limit: rowsPerPage,
    ...filters,
    q: q.trim(),
  };

  api.dispatch(
    apiSlice.util.updateQueryData(
      mappings[options.resource].listFetcher,
      params,
      (draft) => {
        let filtered = draft.filter(
          (e) => options.data.findIndex((x) => x === e._id) === -1
        );
        if (filtered.length > 0) {
          skip = filtered[filtered.length - 1]?._id;
        } else {
          if (pagination.page === 0) {
            skip = "";
          } else {
            skip = skip_id;
          }
        }

        if (rowsPerPage === filtered.length) {
          skipRefill = true;
        }
        remaining_items = filtered.length;
        switch (options.resource) {
          case "product":
            queryTail = `/products?limit=${options.data.length}&skip=${
              params.q.trim() !== "" ? params.skip + remaining_items : skip
            }${q.trim() !== "" ? `&q=${q}` : ""}${
              params.category && params.category !== ""
                ? `&category=${params.category}`
                : ""
            }`;
            break;
          case "user":
            queryTail = `/users/?limit=${options.data.length}&skip=${
              q.trim() !== "" ? params.skip + remaining_items : skip
            }${q.trim() !== "" ? `&q=${q}` : ""}${
              params.q && params.q !== "" ? `&q=${params.q}` : ""
            }${
              params.role && params.role !== "" ? `&role=${params.role}` : ""
            }`;
            break;
          case "vendor":
            queryTail = `/vendors/?limit=${options.data.length}&skip=${
              q.trim() !== "" ? params.skip + remaining_items : skip
            }${q.trim() !== "" ? `&q=${q}` : ""}`;
            break;
          case "location":
            queryTail = `/locations/?limit=${options.data.length}&skip=${
              q.trim() !== "" ? params.skip + remaining_items : skip
            }${q.trim() !== "" ? `&q=${q}` : ""}${
              params.status && params.status !== ""
                ? `&status=${params.status}`
                : ""
            }`;
            break;
          case "workflow":
            queryTail = `/workflows/?limit=${options.data.length}&skip=${
              q.trim() !== "" ? params.skip + remaining_items : skip
            }${q.trim() !== "" ? `&q=${q}` : ""}`;

            break;
          case "invoice":
            queryTail = `/invoices/?limit=${options.data.length}&skip=${
              q.trim() !== "" ? params.skip + remaining_items : skip
            }${q.trim() !== "" ? `&q=${q}` : ""}${
              params.status && params.status !== ""
                ? `&status=${params.status}`
                : ""
            }`;
            break;
          case "purchase order":
            queryTail = `/purchase-orders/?limit=${options.data.length}&skip=${
              q.trim() !== "" ? params.skip + remaining_items : skip
            }${q.trim() !== "" ? `&q=${q}` : ""}${
              params.status && params.status !== ""
                ? `&status=${params.status}`
                : ""
            }`;
            break;
          case "line item":
            queryTail = `/line-items/?limit=${options.data.length}&skip=${
              q.trim() !== "" ? params.skip + remaining_items : skip
            }${q.trim() !== "" ? `&q=${q}` : ""}${
              params.status && params.status !== ""
                ? `&status=${params.status}`
                : ""
            }`;
            break;

          default:
        }

        unchanged = params;
        return filtered;
      }
    )
  );

  if (skipRefill) return { data: [] };
  try {
    const result = await axios.get(`${constants.apiUrl}${queryTail}`, {
      headers: {
        Authorization: authorization,
        workspace: user.workspace._id,
      },
    });

    if (result.data && result.data.length > 0) {
      api.dispatch(
        apiSlice.util.updateQueryData(
          mappings[options.resource].listFetcher,
          unchanged,
          (draft) => {
            result.data.forEach((item) => {
              draft.push(item);
            });
          }
        )
      );
    }

    return { data: result.data };
  } catch (axiosError) {
    let err = axiosError;

    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message,
      },
    };
  }
};

export { refillList };
