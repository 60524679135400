/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import TablePaginationComponent from "../../../layouts/common/pagination/view";
import Stack from "@mui/material/Stack";
import {
  TableContainer,
  TableHeader,
  TableRow,
} from "../../../layouts/common/table/view";
import { useGetButcheriesQuery } from "../../../api/api-endpoints/butchery";
import { useDispatch, useSelector } from "react-redux";
import TableOptions from "./table_options";
import Collapse from "@mui/material/Collapse";
import Drawer from "@mui/material/Drawer";
import RowActionItem from "./row_action_item";
import ManageButchery from "../manage-outlet/view";
import { Chip } from "@mui/material";

const resource = "butchery";

export default function Table() {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [activeButchery, setActiveButchery] = React.useState(null);

  const { rowsPerPage, skip_id, q, page, filters } = useSelector(
    (state) => state.pagination
  ).butchery;

  const {
    data: butcheries = [],
    isLoading,
    isFetching,
    refetch,
  } = useGetButcheriesQuery({
    skip: q === "" ? skip_id : page * rowsPerPage,
    limit: rowsPerPage,
    ...filters,
    q,
  });

  const [selected, setSelected] = React.useState([]);

  const dispatch = useDispatch();

  const animateIn = true;

  const tableConfig = {
    headings: [
      {
        title: "Butchery Name",
        key: "name",
        grid: {
          xs: 7,
          sm: 4,
          md: 3,
          lg: 3.5,
        },
      },
      {
        title: "Address",
        key: "address",
        grid: {
          xs: 0,
          sm: 3,
          md: 3.5,
          lg: 3,
        },
      },
      {
        title: "City",
        key: "city",
        grid: {
          xs: 0,
          sm: 2.5,
          md: 3,
          lg: 2,
        },
      },
      {
        title: "Contact Person",
        key: "contact",
        grid: {
          xs: 0,
          sm: 0,
          md: 0,
          lg: 2,
        },
      },
      {
        title: "Status",
        key: "status",
        grid: {
          xs: 0,
          sm: 0,
          md: 0,
          lg: 0,
        },
      },
      {
        title: "Action",
        grid: {
          xs: 3,
          sm: 1,
          md: 1,
          lg: 1.5,
        },
      },
    ],
    actionGrid: {
      xs: 3,
      sm: 1,
      md: 1,
      lg: 1.5,
    },
    schema: ["name", "address", "city", "contact", "status"],
    enableCheck: false,
    preprocessColumnData: (item, schemaItem) => {
      return schemaItem === "city" ? (
        item?.city?.name
      ) : schemaItem === "status" ? (
        item[schemaItem] === "active" ? (
          <Chip sx={{ alignSelf: "flex-start" }} label="Active" color="info" />
        ) : (
          <Chip
            sx={{ alignSelf: "flex-start" }}
            label="Archived"
            color="warning"
          />
        )
      ) : (
        item[schemaItem] || "Not set"
      );
    },
  };

  return (
    <Collapse in={animateIn}>
      <TableContainer>
        {false && (
          <TableOptions
            setSelected={setSelected}
            selected={selected}
            filters={filters}
            resource={resource}
            refetch={refetch}
            isLoading={isLoading}
          />
        )}

        {(isLoading || isFetching) && <LinearProgress />}

        <TableHeader
          enableCheck={tableConfig.enableCheck}
          headings={tableConfig.headings}
          disable={isLoading}
          allSelected={butcheries.length === selected.length}
          setSelected={(all) => {
            if (all) {
              setSelected(butcheries.map((e) => e._id));
            } else {
              setSelected([]);
            }
          }}
        />

        <Stack
          sx={{
            borderTopWidth: 0,
            borderRadius: 2,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        >
          {butcheries.map((item, index) => {
            return (
              <TableRow
                size={butcheries.length}
                key={item._id}
                actionGrid={tableConfig.actionGrid}
                disableLink={true}
                enableCheck={tableConfig.enableCheck}
                index={index}
                schema={tableConfig.headings.filter((x) => x.key != null)}
                disable={isLoading}
                preprocessColumnData={tableConfig.preprocessColumnData}
                setSelected={setSelected}
                selected={selected.includes(item._id)}
                item={item}
                additionalColumn={
                  <RowActionItem
                    openDrawer={() => {
                      setDrawerOpen(true);
                      setActiveButchery(item._id);
                    }}
                    item={item}
                  />
                }
              />
            );
          })}
        </Stack>
        <React.Fragment key={"right-1"}>
          <Drawer
            elevation={0}
            anchor={"right"}
            open={drawerOpen}
            onClose={() => {
              setDrawerOpen(false);
            }}
          >
            <ManageButchery setOpen={setDrawerOpen} _id={activeButchery} />
          </Drawer>
        </React.Fragment>
        <TablePaginationComponent
          filters={filters}
          deletions={butcheries.filter((butchery) => butchery._deleted)}
          loading={isFetching || isLoading}
          skip_id={skip_id}
          refetch={refetch}
          lastItemId={
            butcheries.length > 0 ? butcheries[butcheries.length - 1]._id : ""
          }
          resource={resource}
          data_size={butcheries.length}
        />
      </TableContainer>
    </Collapse>
  );
}
