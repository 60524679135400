import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { HiXMark } from "react-icons/hi2";
import { Check } from "@mui/icons-material";
import FormContainer from "./form";
import LinearProgress from "@mui/material/LinearProgress";
import { useAddNewUserMutation } from "../../../api/api-endpoints/user";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseTwoTone from "@mui/icons-material/CloseTwoTone";
import Alert from "@mui/material/Alert";
import { DialogContent, Divider, Slide } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddUser({ open, setOpen }) {
  const [addNewUser, { isLoading: isSaving }] = useAddNewUserMutation();

  const [showAlert, setShowAlert] = React.useState(false);

  const submitForm = async () => {
    const form = document.getElementById("new-user-form");
    const user = {};

    for (let i = 0; i < form.elements.length; i++) {
      var e = form.elements[i];
      if (e.name) {
        if (e.value.trim() !== "") {
          user[e.name] = e.value.trim();
        }
      }
    }

    try {
      let result = await addNewUser({
        ...user,
      });
      if (result.error) {
        throw new Error(result.error);
      }

      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
      document
        .getElementById("container_new_user_dialog")
        .scrollTo({ left: 0, top: 0 });
      for (let i = 0; i < form.elements.length; i++) {
        form.elements[i].value = "";
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          borderRadius: 2,
        },
      }}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Stack
        alignItems="center"
        justifyContent="space-between"
        direction="row"
        sx={{ pl: 3, pt: 2, pr: 3 }}
      >
        <h3 style={{ margin: 0, marginTop: 16 }}>Add User</h3>
        <IconButton
          onClick={() => {
            setOpen(false);
          }}
        >
          <HiXMark />
        </IconButton>
      </Stack>
      <Divider sx={{ opacity: 0.3, mt: 2 }} />
      <DialogContent id="container_new_user_dialog">
        <Stack
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                background: "rgba(0, 0, 0, 0.015)",
                borderRadius: 2,
                borderColor: "white",
              },
              "&.Mui-focused fieldset": {
                boxShadow: "5px 5px 24px 1px rgba(0, 134, 211, 0.1)",
              },
            },
          }}
        >
          <Collapse sx={{ mt: 1, mr: 1, ml: 1 }} in={showAlert}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setShowAlert(false);
                  }}
                >
                  <CloseTwoTone fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              New user added successfully!
            </Alert>
          </Collapse>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              submitForm();
            }}
            action="/"
            id="new-user-form"
          >
            <FormContainer open={open} />

            <Collapse in={!isSaving}>
              <Divider sx={{ mt: 2, mb: 2, opacity: 0.25 }} />
            </Collapse>

            <Collapse in={isSaving}>
              <Stack sx={{ mt: 2 }}>
                <LinearProgress sx={{ borderRadius: 32 }} />
              </Stack>
            </Collapse>
            <Stack sx={{ my: 1, justifyContent: "flex-end" }} direction="row">
              {/**
               * || !managerialLine
               */}
              <Button
                startIcon={<Check />}
                disabled={isSaving}
                variant="contained"
                sx={{
                  borderRadius: 32,
                  height: 50,
                  textTransform: "none",
                  mr: 1,
                }}
                type="submit"
                disableElevation
              >
                Save User
              </Button>
              <Button
                onClick={() => {
                  setOpen(false);
                }}
                variant="outlined"
                color="warning"
                sx={{ borderRadius: 32, height: 50, textTransform: "none" }}
                disableElevation
              >
                Cancel
              </Button>
            </Stack>
          </form>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
