import { createSlice } from "@reduxjs/toolkit";

const permissionsSlice = createSlice({
  name: "permissions",
  initialState: [],
  reducers: {
    addPermissions(state, action) {
      return action.payload;
    },
  },
});

export const { addPermissions } = permissionsSlice.actions;
export default permissionsSlice.reducer;
