export function numberWithCommas(x = "") {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const systemRoles = [];

export const systemDesignations = [
  { label: "Line manager", value: "line_manager" },
  { label: "HR manager", value: "hr_manager" },
  { label: "Fleet manager", value: "fleet_manager" },
  { label: "Country director", value: "country_director" },
];

const liveUrl = "https://chatbot.mozbife.shop";
const localUrl = "http://localhost:4000";

const localNotifier = "ws://127.0.0.1:4000";
const liveNotifier = "wss://chatbot.mozbife.shop";
const constants = {
  apiUrl: liveUrl,
  notifier: liveNotifier,
  currency: {
    name: "MZN",
    symbol: "MZN",
  },
};

export { constants };
