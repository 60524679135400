import * as React from "react";

import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";

import { IoMdCheckmark } from "react-icons/io";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";

const txtStyle = {
  "white-space": "nowrap",
  overflow: "hidden",
  "text-overflow": "ellipsis",
  width: "100%",
  pr: 2,
};

export const CheckedIcon = () => (
  <div
    style={{
      height: 18,
      width: 18,
      borderRadius: 5,
      background: "#00D43A",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      borderStyle: "solid",
      borderColor: "#00D43A",
    }}
  >
    <IoMdCheckmark color="white" />
  </div>
);

const calculateDisplay = (grid) => {
  let display = {};
  let keys = Object.keys(grid);
  keys.forEach((key) => {
    if (grid[key] === 0) {
      display[key] = "none";
    } else {
      display[key] = "flex";
    }
  });

  return display;
};

function TableHeader({
  setSelected = () => {
    return;
  },
  headings = [],
  enableCheck = false,
  allSelected = null,
  selectOption = "_id",
}) {
  //  background: "#F6F7FA",
  return (
    <Stack
      sx={{
        pr: 1,
        pl: 1,
        borderBottomStyle: "dotted",
        borderBottomWidth: 1,
        borderBottomColor: "#E7E7E7",
        pt: 0.5,
      }}
    >
      <Grid spacing={1.5} container sx={{ alignItems: "center", pt: 1, pb: 1 }}>
        {enableCheck && (
          <Checkbox
            sx={{ mr: 1, mt: 1, ml: 1 }}
            checked={allSelected}
            onChange={(e) => {
              if (e.target.checked) {
                setSelected(true);
              } else {
                setSelected(false);
              }
            }}
            icon={
              <div
                style={{
                  borderStyle: "solid",
                  height: 18,
                  width: 18,
                  borderRadius: 5,
                  borderColor: "#515F6E",
                }}
              />
            }
            checkedIcon={<CheckedIcon />}
          />
        )}
        {headings.map(({ title, grid, flex = 1 }, index) => {
          return (
            <Box
              display={calculateDisplay(grid)}
              component={Grid}
              sx={{}}
              item
              {...grid}
            >
              <Typography
                variant="caption"
                sx={{
                  width: "100%",
                  ...(title.toLowerCase() === "actions" ||
                  title.toLowerCase() === "action"
                    ? { textAlign: "center" }
                    : {}),

                  //  fontSize: 15,
                  pl: !enableCheck && index === 0 ? 2 : 0,
                }}
              >
                {title.toUpperCase()}
              </Typography>
            </Box>
          );
        })}
      </Grid>
    </Stack>
  );
}

/**else if (addItemBtn && index === 0) {
            return (
              <Grid
                {...grid}
                item
                sx={{
                  alignItems: "center",
                }}
              >
                {addItemBtn}
                <Typography
                  sx={{
                    width: "100%",
                    ...txtStyle,
                  }}
                >
                  {preprocessColumnData(item, schemaItem)}
                </Typography>
              </Grid>
            );
          } */

// addItemBtn is added on the purchase request page where items
// are being selected from the catalogue
function TableRow({
  item,
  setSelected,
  selected,
  disable,
  enableCheck,
  schema,
  preprocessColumnData,
  additionalColumn = null,
  disableLink = false,
  addItemBtn,
  highlightRow = false,
  actionGrid,
  checkGrid,
  disableTextCrop,
  selectOption = "_id",
  firstColumn,
}) {
  const { _deleted } = item;

  return (
    <Stack
      sx={{
        background: highlightRow ? "#FFFAAB" : "white",
        alignItems: "center",
        pr: 1,
        pl: 1,
        pt: 1.5,
        pb: 1.5,
        borderBottomStyle: "dotted",
        borderBottomWidth: 1,
        borderBottomColor: "#E7E7E7",
        position: "relative",
        "&:hover": {
          //  backgroundColor: "#F6F7FA",
        },
      }}
    >
      <Grid sx={{ alignItems: "center" }} container spacing={1.5}>
        {enableCheck && (
          <Checkbox
            sx={{ mr: 1, mt: 1, ml: 1, opacity: disable ? 0.2 : 1 }}
            disabled={disable || _deleted}
            onChange={(e) => {
              if (selectOption === "_id") {
                if (e.target.checked) {
                  setSelected((selectedItems) => [...selectedItems, item._id]);
                } else {
                  setSelected((selectedItems) => [
                    ...selectedItems.filter((a) => a !== item._id),
                  ]);
                }
              } else {
                if (e.target.checked) {
                  setSelected((selectedItems) => [...selectedItems, item]);
                } else {
                  setSelected((selectedItems) => [
                    ...selectedItems.filter((a) => a._id !== item._id),
                  ]);
                }
              }
            }}
            checked={selected}
            icon={
              <div
                style={{
                  borderStyle: "solid",
                  height: 18,
                  width: 18,
                  borderRadius: 5,
                  borderColor: "#515F6E",
                }}
              />
            }
            checkedIcon={<CheckedIcon />}
          />
        )}
        {schema.map(({ key: schemaItem, grid }, index) => {
          return (
            <Box
              display={calculateDisplay(grid)}
              component={Grid}
              item
              sx={{ alignItems: "center" }}
              {...grid}
            >
              {firstColumn && index === 0 && firstColumn()}
              {(!firstColumn || index !== 0) && (
                <>
                  {index === 0 && addItemBtn}
                  <Typography
                    sx={{
                      width: "100%",
                      pl: index === 0 && !enableCheck && !addItemBtn ? 2 : 0,
                      ...(disableTextCrop ? { pr: 2 } : txtStyle),
                      ...(disableTextCrop
                        ? {
                            display: "-webkit - box",
                            "-webkit-line-clamp": 3 /* number of lines to show */,
                            "line-clamp": 3,
                            "-webkit-box-orient": "vertical",
                          }
                        : {}),
                    }}
                  >
                    {preprocessColumnData(item, schemaItem)}
                  </Typography>
                </>
              )}
            </Box>
          );
        })}
        {additionalColumn && (
          <Grid
            item
            sx={{
              justifyContent: "center",

              flex: 1,
              alignItems: "center",
              display: "flex",
            }}
            {...actionGrid}
          >
            {additionalColumn}
          </Grid>
        )}
        {_deleted && (
          <Stack
            sx={{
              position: "absolute",
              background: "rgba(255, 255, 255, 0.75)",
              top: 1,
              left: 0,
              right: 0,
              bottom: 1,
              zIndex: 1.2,
              alignItems: "center",
              justifyContent: "center",
            }}
          ></Stack>
        )}
      </Grid>
    </Stack>
  );
}

function TableContainer(props) {
  return (
    <div
      style={{
        borderStyle: "solid",
        borderColor: "#E8E4F8",
        borderWidth: 0.65,
        marginTop: 16,
        borderRadius: 16,
        boxShadow: "5px 5px 24px 1px rgba(0, 0, 0, 0.04)",
        transition: "all .8s ease",
        WebkitTransition: "all .8s ease",
        MozTransition: "all .8s ease",
        maxWidth: "100%",
      }}
    >
      {props.children}
    </div>
  );
}

export { TableHeader, TableRow, TableContainer };
