import { useEditUserMutation } from "../../../../../api/api-endpoints/user";
import React from "react";
import { useTheme } from "@emotion/react";
import { HiCheck } from "react-icons/hi2";
import { IoEye, IoEyeOff } from "react-icons/io5";
import {
  Stack,
  Typography,
  IconButton,
  Collapse,
  CircularProgress,
  Button,
  Alert,
  useMediaQuery,
} from "@mui/material";
import { CustomInput } from "../../../../../components/global";

const UpdatePassword = ({ _id }) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  // Add useEditUserMutation hook
  const [editUser, { isLoading: updatingPassword }] = useEditUserMutation();

  const [password, setPassword] = React.useState({
    confirm: "",
    new: "",
    password: true,
  });

  const [resultAlert, setResultAlert] = React.useState({
    open: false,
    message: null,
    type: null,
    target: null,
  });

  const showResultAlert = (payload) => {
    setResultAlert(payload);
    setTimeout(() => {
      resetResultAlert();
    }, 5000);
  };

  const resetResultAlert = () => {
    setResultAlert({
      open: false,
      message: null,
      type: null,
      target: null,
    });
  };

  const handleSave = () => {
    if (password.new.length < 7) {
      setPasswordError("The password must be at least 7 characters long");
      setTimeout(() => {
        setPasswordError(null);
      }, 5000);
      return;
    }
    if (password.new !== password.confirm) {
      setPasswordError(
        "The passwords you entered in the 'New Password' and 'Confirm New Password' fields do not match."
      );
      setTimeout(() => {
        setPasswordError(null);
      }, 5000);
      return;
    }
    editUser({
      user: {
        password: password.new,
      },
      _id,
    })
      .unwrap()
      .then((result) => {
        if (result?.error) {
          showResultAlert({
            open: true,
            message: result.error,
            type: "error",
            target: "password",
          });
          return;
        }
        showResultAlert({
          open: true,
          message: "Password updated successfully!",
          type: "success",
          target: "password",
        });
      })
      .catch((error) => {
        showResultAlert({
          open: true,
          message: "Sorry, there was an error updating your password",
          type: "error",
          target: "password",
        });
      });
  };

  const [passwordError, setPasswordError] = React.useState(null);

  const [passwordVisible, setPasswordVisible] = React.useState(false);

  return (
    <Stack
      direction={{
        xs: "column",
        xsm: "column",
        sm: "column",
        md: "row",
        lg: "row",
        xl: "row",
      }}
      spacing={2}
      sx={{
        mt: 3,
        borderRadius: 5,
        p: {
          xsm: 2,
          xs: 3,
          sm: 4,
          md: 5,
          lg: 8,
          xl: 8,
        },
        // border: 1,
        borderColor: "rgba(0, 0, 0, 0.04)",
        boxShadow: "0 0 5px rgba(0, 0, 0, 0.07)",
      }}
    >
      <Typography
        sx={{
          width: {
            xsm: "auto",
            sm: "auto",
            xs: "auto",
            md: 300,
            lg: 400,
            xl: 400,
          },
          pb: 1,
        }}
        variant="h6"
      >
        <b>Update user's password</b>
      </Typography>

      <form
        style={{ flex: 1 }}
        onSubmit={(e) => {
          e.preventDefault();
          if (!updatingPassword) {
            handleSave("password");
          }
        }}
      >
        <Stack sx={{ flex: 1, maxWidth: 500 }}>
          <Stack
            spacing={2}
            sx={{ alignItems: "center", mb: 3 }}
            direction="row"
          >
            <CustomInput
              required
              endAdornment={
                <IconButton
                  onClick={() => {
                    setPasswordVisible((prev) => !prev);
                  }}
                >
                  {passwordVisible ? <IoEyeOff /> : <IoEye />}
                </IconButton>
              }
              onChange={(e) => {
                setPassword((prev) => ({ ...prev, new: e.target.value }));
              }}
              value={password.new}
              name="newPassword"
              type={!passwordVisible ? "password" : "text"}
              label="New password"
              fullWidth
            />
          </Stack>
          <Stack spacing={2} sx={{ alignItems: "center" }} direction="row">
            <CustomInput
              required
              onChange={(e) => {
                setPassword((prev) => ({ ...prev, confirm: e.target.value }));
              }}
              value={password.confirm}
              type={!passwordVisible ? "password" : "text"}
              name="confirmPassword"
              label="Confirm password"
              fullWidth
            />
          </Stack>

          <Collapse in={passwordError != null}>
            <Typography
              variant="body2"
              sx={{
                mt: 3,
                ml: 1,
                color: "#E27720",
              }}
            >
              {passwordError}
            </Typography>
          </Collapse>

          <Collapse in={resultAlert.open && resultAlert.target === "password"}>
            <Alert
              sx={{ mt: 3, borderRadius: 3, p: 2 }}
              onClose={() => {
                resetResultAlert();
              }}
              severity={resultAlert.type || "info"}
            >
              {resultAlert.message}
            </Alert>
          </Collapse>

          <Button
            disableElevation
            disabled={updatingPassword}
            startIcon={
              updatingPassword ? <CircularProgress size={15} /> : <HiCheck />
            }
            color="inherit"
            type="submit"
            sx={{
              textTransform: "none",
              alignSelf: isMobile ? "flex-end" : "flex-start",
              borderRadius: 32,
              height: 55,
              mt: 3,
            }}
          >
            Save Changes
          </Button>
        </Stack>
      </form>
    </Stack>
  );
};

export default UpdatePassword;
