import React, { useState, useEffect } from "react";
import { useEditButcheryMutation } from "../../../api/api-endpoints/butchery";
import { useGetProductAvailabilityResourcesQuery } from "../../../api/api-endpoints/butchery";
import {
  Stack,
  Divider,
  Grid,
  Button,
  CircularProgress,
  Checkbox,
  TextField,
  FormControlLabel,
  Typography,
  Collapse,
  LinearProgress,
} from "@mui/material";
import { NumericFormatCustom } from "../../../layouts/common/inputs/view";
import { CheckedIcon } from "../addOrUpdate";
import { constants, numberWithCommas } from "../../../config/constants";
import { HiCheck } from "react-icons/hi2";

const currency = constants.currency;

export default function ProductAvailability({
  butcheryId,
  productAvailability = [],
}) {
  const [editOutlet, { isLoading: isUpdating }] = useEditButcheryMutation();
  const { data, isLoading, isFetching } =
    useGetProductAvailabilityResourcesQuery();
  const { products } = data || { products: [] };

  const [availabilityMap, setAvailabilityMap] = useState({});

  useEffect(() => {
    // Map existing product availability to a more accessible format
    const initialAvailability = {};
    productAvailability.forEach((item) => {
      initialAvailability[item.product] = {
        ...item,
      };
    });

    // Initialize availability for all products to not available
    const allProductsAvailability = {};
    products.forEach((product) => {
      allProductsAvailability[product._id] = initialAvailability[
        product._id
      ] || {
        product: product._id,
        price: "",
        available: false,
      };
    });

    setAvailabilityMap(allProductsAvailability);
  }, [products, productAvailability]);

  const handleInputChange = (productId, field, value) => {
    setAvailabilityMap((prevMap) => ({
      ...prevMap,
      [productId]: {
        ...prevMap[productId],
        [field]: value,
      },
    }));
  };

  const handleSave = async () => {
    let productAvailabilityToUpdate = Object.values({ ...availabilityMap });

    try {
      await editOutlet({
        _id: butcheryId,
        butchery: {
          productAvailability: productAvailabilityToUpdate.map(
            ({ price, available, product }) =>
              price === "" || price == null
                ? {
                    available,
                    product,
                  }
                : {
                    price,
                    available,
                    product,
                  }
          ),
        },
      }).unwrap();
      //  alert("Product availability updated successfully!");
    } catch (error) {
      //alert("Failed to update product availability!");
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Stack
      sx={{
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            background: "rgba(0, 0, 0, 0.02)",
            borderRadius: 2,
            borderColor: "white",
          },
        },
        py: 2,
      }}
      spacing={2}
    >
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Stack>
          <h4 style={{ margin: 0 }}>Product Availability</h4>
          <Typography sx={{ maxWidth: 350, mt: 1 }} variant="body2">
            For each product, if the price at this butchery matches the default
            price, you may leave the respective price input field empty.{" "}
          </Typography>
        </Stack>
      </Stack>
      <Collapse in={!(isLoading || isFetching)}>
        <Divider sx={{ opacity: 0.25 }} />
      </Collapse>

      <Collapse in={isLoading || isFetching}>
        <LinearProgress color="inherit" sx={{ borderRadius: 32 }} />
      </Collapse>
      <Grid container spacing={2}>
        {products.map((product) => (
          <Grid item xs={12} sm={6} md={4} lg={4} key={product._id}>
            <Stack spacing={2} direction="row">
              {false && (
                <img
                  style={{
                    height: 60,
                    width: 60,
                    borderRadius: 12,
                    objectFit: "cover",
                  }}
                  alt=""
                  src={`${constants.apiUrl}/documents/${product?.gallery[0]}`}
                />
              )}
              <Stack spacing={1}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checkedIcon={<CheckedIcon />}
                      icon={
                        <div
                          style={{
                            borderStyle: "solid",
                            height: 18,
                            width: 18,
                            borderRadius: 5,
                            borderColor: "#515F6E",
                          }}
                        />
                      }
                      checked={availabilityMap[product._id]?.available === true}
                      onChange={(e) =>
                        handleInputChange(
                          product._id,
                          "available",
                          e.target.checked
                        )
                      }
                    />
                  }
                  label={`${product.name} (${
                    constants.currency?.symbol
                  }${numberWithCommas(product.defaultPrice?.toFixed(2))})`}
                />
                <TextField
                  size="small"
                  sx={{ width: 200 }}
                  label={`Price (${currency?.symbol})`}
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                  value={availabilityMap[product._id]?.price}
                  onChange={(e) =>
                    handleInputChange(product._id, "price", e.target.value)
                  }
                  // disabled={!availabilityMap[product._id]?.available}
                  //required={availabilityMap[product._id]?.available}
                  style={{}}
                />
              </Stack>
            </Stack>
          </Grid>
        ))}
      </Grid>
      <Divider sx={{ opacity: 0.25, my: 2 }} />
      <Button
        variant="contained"
        disableElevation
        endIcon={isUpdating ? <CircularProgress size={14} /> : null}
        startIcon={<HiCheck size={15} />}
        sx={{
          borderRadius: 32,
          textTransform: "none",
          alignSelf: "flex-end",
          height: 50,
        }}
        onClick={handleSave}
        disabled={isUpdating}
      >
        Save Changes
      </Button>
    </Stack>
  );
}
