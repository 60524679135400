import { apiSlice } from "../api.service";

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCities: builder.query({
      query: () => {
        return {
          url: `/city`,
          method: "GET",
        };
      },
      providesTags: [{ type: "City", id: "LIST" }],
    }),
    getCity: builder.query({
      query: (cityID) => {
        return { url: `/city/${cityID}`, method: "GET" };
      },
      providesTags: (result, error, arg) => [{ type: "City", id: arg }],
    }),

    addNewCity: builder.mutation({
      query: (city) => ({
        url: "/city",
        method: "POST",
        body: city,
      }),
      invalidatesTags: [{ type: "City", id: "LIST" }],
    }),
    editCity: builder.mutation({
      query: (data) => ({
        url: `/city/${data._id}`,
        method: "PATCH",
        body: data.city,
      }),
      async onQueryStarted({ _id, city }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedCity } = await queryFulfilled;

          dispatch(
            apiSlice.util.updateQueryData("getCities", undefined, (draft) => {
              const city = draft.find((e) => e._id === updatedCity._id);
              if (city) {
                Object.keys(updatedCity).forEach((key) => {
                  city[key] = updatedCity[key];
                });
              }
            })
          );
          dispatch(
            apiSlice.util.updateQueryData("getCity", _id, (draft) => {
              Object.assign(draft, updatedCity);
            })
          );
        } catch {}
      },
    }),

    deleteCity: builder.mutation({
      query: (_id) => ({
        url: `/city/${_id}`,
        method: "DELETE",
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            apiSlice.util.updateQueryData("getCities", undefined, (draft) => {
              const index = draft.findIndex((e) => e._id === _id);
              if (index !== -1) {
                draft.splice(index, 1);
              }
            })
          );
        } catch {}
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCitiesQuery,
  useGetCityQuery,
  useAddNewCityMutation,
  useEditCityMutation,
  useDeleteCityMutation,
} = extendedApi;
