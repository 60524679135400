/* eslint-disable react-hooks/exhaustive-deps */
import Stack from "@mui/material/Stack";
import { Button, Collapse, Alert } from "@mui/material";
import * as React from "react";
import Grow from "@mui/material/Grow";
import { Check } from "@mui/icons-material";
import FormContainer from "./form";
import { useEditUserMutation } from "../../../../../api/api-endpoints/user";
import { HiTrash } from "react-icons/hi2";

export default function GeneralTab({ user }) {
  const [updateUser, { isLoading: isUpdating }] = useEditUserMutation();

  const [resultAlert, setResultAlert] = React.useState({
    open: false,
    message: null,
    type: null,
    target: null,
  });

  const showResultAlert = (payload) => {
    setResultAlert(payload);
    setTimeout(() => {
      resetResultAlert();
    }, 5000);
  };

  const resetResultAlert = () => {
    setResultAlert({
      open: false,
      message: null,
      type: null,
      target: null,
    });
  };

  const submitForm = async () => {
    const form = document.getElementById("edit-user-form");
    const userUpdate = {};
    for (let i = 0; i < form.elements.length; i++) {
      var e = form.elements[i];
      if (e.name) {
        if (e.value.trim() !== "") {
          userUpdate[e.name] = e.value.trim();
        }
      }
    }

    try {
      let result = await updateUser({ user: userUpdate, _id: user._id });
      if (result?.error) {
        showResultAlert({
          open: true,
          message: result.error,
          type: "error",
        });
        return;
      }
      showResultAlert({
        open: true,
        message: "User updated successfully!",
        type: "success",
      });
    } catch (e) {
      showResultAlert({
        open: true,
        message: e.message,
        type: "error",
      });
    }
  };

  const checked = true;

  return (
    <Grow
      in={checked}
      style={{ transformOrigin: "0 0 0" }}
      {...(checked ? { timeout: 1000 } : {})}
    >
      <Stack
        sx={{
          mt: 3,
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              background: "rgba(0, 0, 0, 0.015)",
              borderRadius: 2,
              borderColor: "white",
            },
            "&.Mui-focused fieldset": {
              boxShadow: "5px 5px 24px 1px rgba(0, 134, 211, 0.1)",
            },
          },
        }}
      >
        <form
          style={{ margin: 0, padding: 0 }}
          onSubmit={(e) => {
            e.preventDefault();
            submitForm();
          }}
          action="/"
          id="edit-user-form"
        >
          <FormContainer isUpdating={isUpdating} user={user} />

          <Collapse in={resultAlert.open}>
            <Alert
              sx={{ mt: 3, borderRadius: 3, p: 2 }}
              onClose={() => {
                resetResultAlert();
              }}
              severity={resultAlert.type || "info"}
            >
              {resultAlert.message}
            </Alert>
          </Collapse>

          <Stack sx={{ mt: 3, mb: 1 }} spacing={2} direction="row">
            <Button
              startIcon={<Check />}
              disabled={isUpdating}
              variant="contained"
              sx={{
                borderRadius: 32,
                height: 50,
                textTransform: "none",
                mr: 1,
              }}
              type="submit"
              disableElevation
            >
              Save Changes
            </Button>
            <Button
              startIcon={<HiTrash />}
              disabled={isUpdating}
              //  variant="outlined"
              color="error"
              sx={{
                borderRadius: 32,
                height: 50,
                textTransform: "none",
                mr: 1,
                display: "none",
              }}
              type="reset"
              disableElevation
            >
              Delete User
            </Button>
          </Stack>
        </form>
      </Stack>
    </Grow>
  );
}
