/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import {
  TableContainer,
  TableHeader,
  TableRow,
} from "../../../../layouts/common/table/view";
import LinearProgress from "@mui/material/LinearProgress";
import TablePaginationComponent from "../../../../layouts/common/pagination/view";
import Stack from "@mui/material/Stack";
import TableOptions from "./table_options";
import {
  useGetUsersQuery,
  useDeleteUsersMutation,
} from "../../../../api/api-endpoints/user";
import RowActionItem from "./row_action_item";
import { useSelector } from "react-redux";
import { Collapse } from "@mui/material";

const resource = "user";

export default function Table() {
  const { rowsPerPage, page, q, skip_id, filters } = useSelector(
    (state) => state.pagination
  ).user;

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [activeUser, setActiveUser] = React.useState(null);

  const {
    data: users = [],
    isLoading,
    isFetching,
    refetch,
  } = useGetUsersQuery({
    skip: q === "" ? skip_id : page * rowsPerPage,
    limit: rowsPerPage,
    ...filters,
    q,
  });

  const [deleteUsers, { isLoading: isDeleting }] = useDeleteUsersMutation();

  const [selected, setSelected] = React.useState([]);

  const tableConfig = {
    headings: [
      {
        title: "First Name",
        key: "firstName",
        grid: {
          xs: 3.5,
          sm: 2,
          md: 2,
          lg: 2.5,
        },
      },
      {
        title: "Last Name",
        key: "lastName",
        grid: {
          xs: 3.5,
          sm: 2,
          md: 2,
          lg: 2.5,
        },
      },
      {
        title: "Email Address",
        key: "email",
        grid: {
          xs: 0,
          sm: 2.5,
          md: 2.5,
          lg: 3,
        },
      },

      {
        title: "Company Position",
        key: "companyPosition",
        grid: {
          xs: 0,
          sm: 0,
          md: 0,
          lg: 2,
        },
      },

      {
        title: "Actions",
        grid: {
          xs: 1,
          sm: 1,
          md: 1,
          lg: 1,
        },
      },
    ],
    enableCheck: false,
    actionGrid: {
      xs: 1,
      sm: 1,
      md: 1,
      lg: 1,
    },
    preprocessColumnData: (item, schemaItem) => {
      return schemaItem === "name"
        ? item.firstName + " " + item.lastName
        : schemaItem === "office"
        ? item.office?.name || "Not set"
        : item[schemaItem] && item[schemaItem] !== ""
        ? item[schemaItem]
        : "Not set";
    },
  };

  return (
    <TableContainer>
      <Collapse in={isLoading || isFetching}>
        <LinearProgress
          color="info"
          sx={{ borderRadius: 32, mx: 1, mt: 0.1 }}
        />
      </Collapse>
      <TableHeader
        enableCheck={tableConfig.enableCheck}
        headings={tableConfig.headings}
        allSelected={users.length === selected.length}
        disable={isLoading || isDeleting}
        setSelected={(all) => {
          if (all) {
            setSelected(users.map((e) => e._id));
          } else {
            setSelected([]);
          }
        }}
      />
      <Stack
        sx={{
          borderTopWidth: 0,
          borderRadius: 2,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          overflow: "scroll",
        }}
      >
        {users.map((item, index) => {
          return (
            <TableRow
              size={users.length}
              enableCheck={tableConfig.enableCheck}
              index={index}
              actionGrid={tableConfig.actionGrid}
              key={item._id}
              disable={isLoading || isDeleting}
              preprocessColumnData={tableConfig.preprocessColumnData}
              setSelected={setSelected}
              selected={selected.includes(item._id)}
              item={item}
              schema={tableConfig.headings.filter((x) => x.key != null)}
              additionalColumn={
                <RowActionItem
                  openDrawer={() => {
                    setDrawerOpen(true);
                    setActiveUser(item._id);
                  }}
                  item={item}
                />
              }
            />
          );
        })}
      </Stack>
      {false && (
        <Stack sx={{ pr: 3, mt: 1, mb: 1 }}>
          <TablePaginationComponent
            filters={filters}
            deletions={users.filter((user) => user._deleted)}
            loading={isFetching || isLoading}
            refetch={refetch}
            lastItemId={users.length > 0 ? users[users.length - 1]._id : ""}
            resource={resource}
            data_size={users.length}
          />
        </Stack>
      )}
    </TableContainer>
  );
}
