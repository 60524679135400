import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

const formItems = {
  name: {
    type: "text",
    required: true,
    label: "Location name",
  },
  contact: {
    type: "contact",
    label: "Contact person",
    required: true,
  },
  phone: {
    type: "phone",
    label: "Contact number",

    required: true,
  },
  address: {
    type: "text",
    required: true,
    label: "Street address",
  },
};

const size = "medium";

const keys = [...Object.keys(formItems)];

const initialLocation = {};

keys.forEach((key) => {
  initialLocation[key] = "";
});

export default function LocationFields({ populate }) {
  const handleBlur = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <Grid container columns={{ xs: 12, sm: 12, md: 12 }}>
        {Object.keys(formItems).map((key, index) => (
          <Grid item sx={{ p: 1.5 }} xs={12} sm={12} md={6} key={index}>
            <Typography variant="caption">
              {formItems[key].label}
              {formItems[key].required && (
                <span style={{ color: "red", fontSize: 16 }}>*</span>
              )}
            </Typography>
            <TextField
              name={key}
              nBlur={handleBlur}
              defaultValue={
                key !== "locationName"
                  ? populate && populate[key]
                    ? populate[key]
                    : ""
                  : populate && populate.name
                  ? populate.name
                  : ""
              }
              required={formItems[key].required || false}
              sx={{ mt: 1 }}
              size={size}
              type={formItems[key].type}
              fullWidth
              placeholder={formItems[key].label}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
