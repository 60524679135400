import React from "react";
import {
  Stack,
  Drawer,
  Typography,
  IconButton,
  Divider,
  Avatar,
  Collapse,
  LinearProgress,
  Button,
  ButtonBase,
  Badge,
} from "@mui/material";
import { apiSlice } from "../api/api.service";
import { CiDeliveryTruck, CiMemoPad } from "react-icons/ci";
import { useLazyGetAlertsQuery } from "../api/api-endpoints/alerts";
import moment from "moment";
import { resetNotificationCount } from "../state/slices/notify";
import { useDispatch } from "react-redux";
import { HiArrowLongRight, HiCreditCard, HiXMark } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";

export default function NotificationDrawer({ open, setOpen }) {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [triggerFetch, { isLoading, isFetching, data: alerts = [] }] =
    useLazyGetAlertsQuery();

  React.useEffect(() => {
    const fetchAlerts = async () => {
      try {
        dispatch(resetNotificationCount());
        await triggerFetch();

        dispatch(
          apiSlice.util.updateQueryData("getAlertCount", undefined, (draft) => {
            if (draft) {
              draft.unreadCount = 0;
            }
          })
        );
      } catch (e) {}
    };

    if (open) {
      fetchAlerts();
    }
  }, [dispatch, open, triggerFetch]);

  React.useEffect(() => {}, []);

  return (
    <Drawer
      fullWidth
      onClose={() => {
        setOpen(false);
      }}
      anchor="right"
      open={open}
      maxWidth="sm"
    >
      <Stack
        sx={{
          width: "100vw",
          maxWidth: "450px",
          p: 3,
          pb: 2,
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
        >
          <Typography>
            <b>Notifications</b>
          </Typography>
          <IconButton
            onClick={() => {
              setOpen(false);
            }}
          >
            <HiXMark />
          </IconButton>
        </Stack>
      </Stack>
      <Divider sx={{ opacity: 0.45 }} />
      <Collapse in={isLoading || isFetching}>
        <LinearProgress color="success" sx={{ borderRadius: 32 }} />
      </Collapse>
      <Stack
        sx={{
          width: "100vw",
          maxWidth: "450px",
          p: 2,
          pt: 2,
        }}
      >
        <Collapse in={!isLoading && !isFetching && alerts.length === 0}>
          <Typography>No alerts to show</Typography>
        </Collapse>
        {alerts.map((item) => {
          return (
            <ButtonBase
              onClick={() => {
                setOpen(false);
                setTimeout(() => {
                  if (item.link) {
                    navigate(item.link);
                  }
                }, [500]);

                //   navigate("/profile?target=subscription");
              }}
              sx={{ mb: 2, p: 1, borderRadius: 3, pb: 0 }}
            >
              <Stack
                sx={{
                  borderBottom: 1,
                  borderColor: "rgb(0, 0, 0, 0.075)",
                  textAlign: "left",
                  pb: 2,
                }}
              >
                <Stack alignItems="center" spacing={2} direction="row">
                  <Stack>
                    <Badge
                      badgeContent={item.read ? 0 : 1}
                      color="primary"
                      variant="dot"
                    >
                      <Avatar
                        sx={{
                          background:
                            item.severity === "success"
                              ? "#00C42E"
                              : item.severity === "neutral"
                              ? "white"
                              : "red",
                          boxShadow: "5px 5px 24px 1px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        {item.type === "task" && <CiMemoPad color="black" />}
                        {item.type === "maintenance" && (
                          <CiDeliveryTruck color="black" />
                        )}
                      </Avatar>
                    </Badge>
                  </Stack>
                  <Stack sx={{ flex: 1 }}>
                    <Typography>
                      <b>{item.title}</b> -{" "}
                      <Typography variant="caption">
                        {moment(item.date).format("LL")}
                      </Typography>
                    </Typography>
                    <Typography variant="body2">{item.content}</Typography>
                  </Stack>

                  <HiArrowLongRight />
                </Stack>
                {item.actionCode === "charge_failed" && (
                  <Button
                    color="inherit"
                    sx={{
                      borderRadius: 32,
                      textTransform: "none",
                      alignSelf: "flex-end",
                      mt: 1,
                    }}
                    endIcon={<HiArrowLongRight />}
                  >
                    Update billing details
                  </Button>
                )}
                {item.actionCode === "lesson_add" && (
                  <Button
                    color="inherit"
                    onClick={async () => {
                      try {
                        setOpen(false);
                        setTimeout(() => {
                          navigate(`/revision/`, {
                            state: {},
                          });
                        }, [500]);
                      } catch (e) {}
                    }}
                    sx={{
                      borderRadius: 32,
                      textTransform: "none",
                      alignSelf: "flex-end",
                      mt: 1,
                    }}
                    endIcon={<HiArrowLongRight />}
                  >
                    View lesson
                  </Button>
                )}
              </Stack>
            </ButtonBase>
          );
        })}
      </Stack>
    </Drawer>
  );
}
