import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import * as React from "react";
import {
  Button,
  CircularProgress,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  DialogActions,
  Checkbox,
  FormControlLabel,
  FormControl,
  FormGroup,
  List,
  ListItem,
} from "@mui/material";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers";
import { useState } from "react";
import { constants } from "../../config/constants";
import { IoMdCheckmark } from "react-icons/io";
import Grid from "@mui/material/Grid";
import {
  useAddNewButcheryMutation,
  useEditButcheryMutation,
  useLazyGetButcheryResourcesQuery,
} from "../../api/api-endpoints/butchery";
import LinearProgress from "@mui/material/LinearProgress";
import DeleteIcon from "@mui/icons-material/Delete";

import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseTwoTone from "@mui/icons-material/CloseTwoTone";
import Alert from "@mui/material/Alert";
import { Divider, InputAdornment } from "@mui/material";
import { NumericFormatCustom } from "../../layouts/common/inputs/view";
import { HiCheck, HiPlus, HiPlusCircle, HiXMark } from "react-icons/hi2";

const TitleDescription = ({ title, description }) => (
  <>
    <Typography>
      <b>{title}</b>
    </Typography>
    <Typography variant="caption">{description}</Typography>
  </>
);

const PhoneNumbersComponent = ({ outlet, setOutlet }) => {
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");

  const handleAddPhone = () => {
    const phoneRegex = /^\+[0-9]{1,3}[0-9]{9}$/;

    if (!phoneRegex.test(phone)) {
      setError(
        "Invalid phone number. Must include + sign and country code followed by 9 digits."
      );
    } else if (outlet.whatsappAlertRecipients.includes(phone)) {
      setError("Phone number already exists in the list.");
    } else {
      setError("");
      setOutlet((prevState) => ({
        ...prevState,
        whatsappAlertRecipients: [...prevState.whatsappAlertRecipients, phone],
      }));
      setPhone("");
    }
  };

  const handleRemovePhone = (index) => {
    setOutlet((prevState) => ({
      ...prevState,
      whatsappAlertRecipients: prevState.whatsappAlertRecipients.filter(
        (_, i) => i !== index
      ),
    }));
  };

  return (
    <Stack>
      <Stack spacing={1} direction="row">
        <TextField
          fullWidth
          type="tel"
          sx={{ flex: 1 }}
          label="Phone Number"
          value={phone}
          onChange={(e) => {
            setError("");
            setPhone(e.target.value.replace(/[^0-9+]/g, ""));
          }}
          error={Boolean(error)}
          helperText={error}
        />
        <Button
          startIcon={<HiPlusCircle />}
          sx={{ alignSelf: "flex-start", height: 55 }}
          color="primary"
          onClick={handleAddPhone}
        >
          Add
        </Button>
      </Stack>
      <List>
        {outlet.whatsappAlertRecipients.map((number, index) => (
          <ListItem
            key={index}
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => handleRemovePhone(index)}
              >
                <DeleteIcon />
              </IconButton>
            }
          >
            {number}
          </ListItem>
        ))}
      </List>
    </Stack>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initOutlet = {
  name: "",
  address: "",
  contact: "",
  phone: "",
  city: null,
  deliveryRadius: "",
  deliveryEnabled: false,
  deliveryFeePerKm: "",
  whatsappAlertRecipients: [],
};

const processTime = (time) => {
  let date = new Date(time);
  let hrs = date.getHours().toString();
  let mins = date.getMinutes().toString();
  let res = `${hrs.padStart(2, "0")}:${mins.padStart(2, "0")}`;
  return res;
};
const convertHoursToSchemaFormat = (hourState) => {
  const formattedHours = {};

  Object.keys(hourState).forEach((day) => {
    const key = day.toLowerCase();
    const { isOpen, open, close } = hourState[day];

    if (isOpen && open && close) {
      formattedHours[key] = {
        open: processTime(open),
        close: processTime(close),
      };
    }
  });

  return formattedHours;
};

const convertHoursFromSchemaFormat = (schema) => {
  const initialState = dayLabels.reduce((acc, day) => {
    acc[day] = { isOpen: false, open: null, close: null };
    return acc;
  }, {});

  Object.keys(schema).forEach((key) => {
    const day = key.charAt(0).toUpperCase() + key.slice(1); // Capitalize the first letter
    if (schema[key]) {
      initialState[day] = {
        isOpen: true,
        open: schema[key].open
          ? new Date(moment(schema[key].open, "HH:mm").toISOString())
          : null,
        close: schema[key].close
          ? new Date(moment(schema[key].close, "HH:mm").toISOString())
          : null,
      };
    }
  });

  return initialState;
};

export const CheckedIcon = () => (
  <div
    style={{
      height: 18,
      width: 18,
      borderRadius: 5,
      background: "#00D43A",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      borderStyle: "solid",
      borderColor: "#00D43A",
    }}
  >
    <IoMdCheckmark color="white" />
  </div>
);

const dayLabels = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const initHours = dayLabels.reduce((acc, day) => {
  acc[day] = { isOpen: false, open: null, close: null };
  return acc;
}, {});

const currency = constants.currency;

export default function AddOrUpdateOutlet({
  open,
  onClose,
  populate = null,
  _id = null,
}) {
  const [outlet, setOutlet] = useState(initOutlet);

  const [addOutlet, { isLoading: isSaving }] = useAddNewButcheryMutation();

  const [editOutlet, { isLoading: isUpdating }] = useEditButcheryMutation();

  const [showAlert, setShowAlert] = React.useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOutlet((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleBlur = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const [getRes, { isLoading: gettingRes, data: butcheryRes }] =
    useLazyGetButcheryResourcesQuery();

  React.useEffect(() => {
    open && getRes();
  }, [open, getRes]);

  const [hours, setHours] = useState(initHours);

  const handleCheckboxChange = (day) => {
    setHours((prev) => ({
      ...prev,
      [day]: {
        ...prev[day],
        isOpen: !prev[day].isOpen,
        open: null,
        close: null,
      },
    }));
  };

  console.log(outlet);

  React.useEffect(() => {
    if (populate && open) {
      let { operatingHours, city, _id, location, ...rest } = populate;
      setOutlet({ ...rest, ...location, city: city?._id });

      if (operatingHours) {
        setHours(convertHoursFromSchemaFormat(operatingHours));
      }
    }
  }, [populate, open, setOutlet]);

  const handleTimeChange = (day, type, newValue) => {
    setHours((prev) => ({
      ...prev,
      [day]: { ...prev[day], [type]: newValue },
    }));
  };

  const saveOutlet = async () => {
    try {
      let result = populate
        ? await editOutlet({
            _id,
            butchery: {
              ...outlet,
              location: {
                latitude: outlet.latitude,
                longitude: outlet.longitude,
              },
              operatingHours: convertHoursToSchemaFormat({ ...hours }),
            },
          })
        : await addOutlet({
            ...outlet,
            location: {
              latitude: outlet.latitude,
              longitude: outlet.longitude,
            },
            operatingHours: convertHoursToSchemaFormat({ ...hours }),
          });
      if (result.error) {
        if (result.error.data) {
          throw new Error(result.error.data.error);
        } else {
          throw new Error("Something went wrong...");
        }
      }
      if (!populate) {
        setOutlet(initOutlet);
      }
      /**
 *       setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 4000);


 */
      onClose();
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      keepMounted={false}
      onClose={onClose}
      open={open}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          borderRadius: 3,
        },
      }}
    >
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">
            <b>{populate ? "Update" : "Add an"} outlet</b>
          </Typography>
          <IconButton onClick={onClose} disabled={isSaving || isUpdating}>
            <HiXMark />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Divider sx={{ mb: 1, opacity: 0.35 }} />
      <DialogContent>
        <Stack
          sx={{
            flex: 1,
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                background: "rgba(0, 0, 0, 0.02)",
                borderRadius: 2,
                borderColor: "white",
              },
            },
          }}
        >
          <form
            id="outlet_form"
            onSubmit={(e) => {
              e.preventDefault();
              saveOutlet();
            }}
          >
            <Grid sx={{}} container spacing={3}>
              <Grid item xs={12} sm={12} md={4}>
                <TitleDescription
                  description="Name, address, contact"
                  title="Outlet Details"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={8}>
                <Stack spacing={2}>
                  <TextField
                    required
                    fullWidth
                    onBlur={handleBlur}
                    name="name"
                    label="Outlet name"
                    value={outlet.name}
                    onChange={handleChange}
                  />
                  <TextField
                    fullWidth
                    name="address"
                    onBlur={handleBlur}
                    label="Address"
                    required
                    value={outlet.address}
                    onChange={handleChange}
                  />
                  <TextField
                    fullWidth
                    name="latitude"
                    required
                    onBlur={handleBlur}
                    label="Latitude"
                    value={outlet.latitude}
                    onChange={handleChange}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                    }}
                  />
                  <TextField
                    fullWidth
                    name="longitude"
                    onBlur={handleBlur}
                    label="Longitude"
                    required
                    value={outlet.longitude}
                    onChange={handleChange}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                    }}
                  />

                  <TextField
                    fullWidth
                    name="contact"
                    onBlur={handleBlur}
                    label="Contact person"
                    required
                    value={outlet.contact}
                    onChange={handleChange}
                  />
                  <TextField
                    fullWidth
                    name="phone"
                    onBlur={handleBlur}
                    label="Phone"
                    required
                    value={outlet.phone}
                    onChange={handleChange}
                  />
                </Stack>
              </Grid>
            </Grid>

            <Grid container sx={{ mt: 3 }} spacing={3}>
              <Grid item xs={12} sm={12} md={4}>
                <TitleDescription
                  description="Configure recipients"
                  title="Order alerts"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={8}>
                <Stack spacing={2}>
                  <PhoneNumbersComponent
                    outlet={outlet}
                    setOutlet={setOutlet}
                  />
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={3} sx={{ mt: 3 }}>
              <Grid item xs={12} sm={12} md={4}>
                <TitleDescription
                  title="City"
                  description="Select the city in which the outlet is located"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={8}>
                <Autocomplete
                  value={
                    (outlet.city &&
                      butcheryRes?.cities?.find(
                        (a) => a._id === outlet.city
                      )) ||
                    null
                  }
                  onChange={(e, newValue) => {
                    setOutlet((prev) => ({
                      ...prev,
                      city: newValue ? newValue._id : null,
                    }));
                  }}
                  getOptionLabel={(item) => `${item.name} `}
                  options={butcheryRes?.cities || []}
                  loading={gettingRes}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      onBlur={handleBlur}
                      label="Select city"
                      inputPros={{
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                      InputProps={{
                        ...params.InputProps,

                        endAdornment: (
                          <React.Fragment>
                            {gettingRes ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    ></TextField>
                  )}
                ></Autocomplete>
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: 3 }}>
              <Grid item xs={12} sm={12} md={4}>
                <TitleDescription
                  title="Delivery settings"
                  description="Enable/disable delivery and set delivery radius"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={8}>
                <Stack spacing={2}>
                  <FormControlLabel
                    label="Enable Deliveries"
                    control={
                      <Checkbox
                        checked={outlet.deliveryEnabled === true}
                        onChange={({ target }) => {
                          const { checked } = target;
                          setOutlet((prev) => ({
                            ...prev,
                            deliveryEnabled: checked,
                          }));
                        }}
                        checkedIcon={<CheckedIcon />}
                        icon={
                          <div
                            style={{
                              borderStyle: "solid",
                              height: 18,
                              width: 18,
                              borderRadius: 5,
                              borderColor: "#515F6E",
                            }}
                          />
                        }
                      />
                    }
                  ></FormControlLabel>
                  <TextField
                    fullWidth
                    name="deliveryRadius"
                    onBlur={handleBlur}
                    disabled={outlet.deliveryEnabled === false}
                    label="Delivery radius"
                    required={outlet.deliveryEnabled === true}
                    value={outlet.deliveryRadius}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: <Typography>km</Typography>,
                      inputComponent: NumericFormatCustom,
                    }}
                  />
                  <TextField
                    fullWidth
                    name="deliveryFeePerKm"
                    onBlur={handleBlur}
                    disabled={outlet.deliveryEnabled === false}
                    label="Delivery fee per km"
                    required={outlet.deliveryEnabled === true}
                    value={outlet.deliveryFeePerKm}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <Typography>{currency?.symbol}</Typography>
                      ),
                      inputComponent: NumericFormatCustom,
                    }}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: 3 }}>
              <Grid item xs={12} sm={12} md={4}>
                <TitleDescription
                  title="Business hours"
                  description="Add business hours for each day of the week"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={8}>
                <Stack spacing={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    {dayLabels.map((day) => (
                      <FormControl component="fieldset">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checkedIcon={<CheckedIcon />}
                                icon={
                                  <div
                                    style={{
                                      borderStyle: "solid",
                                      height: 18,
                                      width: 18,
                                      borderRadius: 5,
                                      borderColor: "#515F6E",
                                    }}
                                  />
                                }
                                checked={hours[day].isOpen}
                                onChange={() => handleCheckboxChange(day)}
                                name={day}
                              />
                            }
                            label={day}
                          />
                          {hours[day].isOpen && (
                            <React.Fragment>
                              <Stack sx={{ mt: 1 }} direction="row" spacing={1}>
                                <TimePicker
                                  ampm={false}
                                  sx={{ flex: 1 }}
                                  label="Open Time"
                                  value={hours[day].open}
                                  onChange={(newValue) =>
                                    handleTimeChange(day, "open", newValue)
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      required
                                      size="small"
                                      {...params}
                                    />
                                  )}
                                />
                                <TimePicker
                                  ampm={false}
                                  sx={{ flex: 1 }}
                                  label="Close Time"
                                  value={hours[day].close}
                                  onChange={(newValue) =>
                                    handleTimeChange(day, "close", newValue)
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      required
                                      size="small"
                                      {...params}
                                    />
                                  )}
                                />
                              </Stack>
                            </React.Fragment>
                          )}
                        </FormGroup>
                      </FormControl>
                    ))}
                  </LocalizationProvider>
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12}>
                <Collapse in={showAlert}>
                  <Alert
                    sx={{ mt: 3 }}
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setShowAlert(false);
                        }}
                      >
                        <CloseTwoTone fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    {populate
                      ? "Changes saved successfully!"
                      : "New outlet added successfully!"}
                  </Alert>
                </Collapse>
              </Grid>
            </Grid>
            <button id="btnSubmit" type="submit" style={{ display: "none" }} />
          </form>
        </Stack>
      </DialogContent>{" "}
      <Collapse in={isSaving || isUpdating}>
        <LinearProgress sx={{ borderRadius: 32, my: 1 }} />
      </Collapse>
      <Collapse in={!(isSaving || isUpdating)}>
        <Divider sx={{ my: 1, opacity: 0.35 }} />
      </Collapse>
      <DialogActions sx={{ pb: 2 }}>
        <Button
          onClick={() => {
            document.getElementById("btnSubmit").click();
          }}
          startIcon={<HiCheck />}
          disabled={isSaving || isUpdating}
          disableElevation
          sx={{
            mr: 1,
            ml: 1,
            height: 50,
            borderRadius: 32,
            textTransform: "none",
          }}
          //  variant="contained"
        >
          {populate ? "Save Changes" : "Save Outlet"}
        </Button>
        <Button
          type="reset"
          color="warning"
          onClick={onClose}
          startIcon={<HiXMark />}
          disabled={isSaving}
          disableElevation
          sx={{
            mr: 1,
            ml: 1,
            height: 50,
            borderRadius: 32,
            textTransform: "none",
          }}
          // variant="outlined"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
