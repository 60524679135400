import Stack from "@mui/material/Stack";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import Grow from "@mui/material/Grow";
import { useDropzone } from "react-dropzone";
import Zoom from "react-medium-image-zoom";
import { useUploadMutation } from "../../../api/api-endpoints/documents";
import Button from "@mui/material/Button";
import TabScrollButton from "@mui/material/TabScrollButton";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Refresh } from "@mui/icons-material";
import { HiXMark } from "react-icons/hi2";
import Check from "@mui/icons-material/Check";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import FileViewer from "react-file-viewer";
import React from "react";
import { LinearProgress } from "@mui/material";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { responsiveProps } from "../../../utils/functions";
import { Tooltip, tooltipClasses, useMediaQuery } from "@mui/material";
import "react-medium-image-zoom/dist/styles.css";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import "react-medium-image-zoom/dist/styles.css";
import { useTheme } from "@emotion/react";

function LinearProgressWithLabel(props) {
  return (
    <Stack
      spacing={1}
      direction="row"
      sx={{ display: "flex", alignItems: "center" }}
    >
      <Stack sx={{ flex: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Stack>
      <Stack sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Stack>
    </Stack>
  );
}

export const PageTitle = ({ title }) => (
  <Typography
    sx={{
      fontSize: responsiveProps(20, 24, 24, 24, 28, 28),
    }}
    variant="h4"
    style={{}}
  >
    <b>{title}</b>
  </Typography>
);

export const FileUploader = ({
  files,
  setFiles,
  accept,
  maxFiles,
  resource,
  label = "",
  isImage = true,
}) => {
  const [uploadDocument] = useUploadMutation();
  const [controllers, setControllers] = React.useState([]);
  const uploadProgress = useSelector((state) => state.uploadProgress[resource]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length === 0) {
        return;
      }
      const newFiles = acceptedFiles.map((file) => ({
        file,
        key: Date.now() + file.name,
      }));
      if (maxFiles === 1) {
        setFiles([newFiles[0]]);
        uploadFiles([newFiles[0]]);
      } else {
        setFiles((prevFiles) => [...prevFiles, ...newFiles]);
        uploadFiles(newFiles);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const uploadFiles = (files = []) => {
    files.forEach(async (item) => {
      const controller = new AbortController();
      var formdata = new FormData();
      formdata.append("file", item.file, item.file.name);
      let newControllers = [...controllers, { key: item.key, controller }];
      setControllers(newControllers);
      let result = await uploadDocument({
        data: formdata,
        key: item.key,
        controller,
        resource,
      });
      if (!result.error && result.data) {
        setFiles((x) =>
          x.map((prev) =>
            prev.key === item.key ? { ...prev, _id: result.data._id } : prev
          )
        );
      }
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: isImage
      ? {
          "image/*": [".jpeg", ".jpg", ".png"],
        }
      : {
          "application/pdf": [".pdf"],
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            [".docx", ".doc"],
        },
    maxFiles,
  });

  const removeFile = (key) => {
    setFiles((prev) => prev.filter((file) => file.key !== key));
    controllers.forEach((controller) => {
      if (controller.key === key) {
        controller.controller.abort();
      }
    });
  };

  return (
    <Stack
      sx={{
        border: "1px dashed rgb(0, 0, 0, 0.1)",
        textAlign: "center",
        borderRadius: "5px",
      }}
    >
      {(files.length === 0 || maxFiles > 1) && (
        <Stack {...getRootProps()} sx={{ padding: "16px", cursor: "pointer" }}>
          <input {...getInputProps()} />
          <Typography variant="body2">{label}</Typography>
          {accept && (
            <Typography variant="caption">
              (Accepted types: {accept})
            </Typography>
          )}
          {maxFiles && (
            <Typography variant="caption">, Max files: {maxFiles}</Typography>
          )}
        </Stack>
      )}
      <Stack
        sx={{
          padding: files.length > 0 ? "16px" : 0,
          paddingTop: 0,
          mt: maxFiles === 1 && files.length > 0 ? 2 : 0,
        }}
      >
        {files.map(({ file, key, url }, index) => {
          let activeFileProgress = url
            ? {
                progress: 100,
                key,
              }
            : uploadProgress?.find((a) => a.key === key) || {
                progress: 0,
                key,
              };

          const src = url || URL.createObjectURL(file);

          return (
            <Stack
              sx={{
                boxShadow: "2px 2px 8px 12px rgba(25, 137, 255, 0.045)",
                p: 1.5,
                borderRadius: 2,
                pb: 0.5,
                mb: 1,
                flex: 1,
              }}
              alignItems="center"
              spacing={2}
              direction="row"
            >
              {(url || file?.type?.indexOf("image") !== -1) && (
                <Stack>
                  <Zoom>
                    <img
                      src={src}
                      alt=""
                      style={{
                        height: 60,
                        width: 65,
                        objectFit: "cover",
                        borderRadius: 8,
                      }}
                    />
                  </Zoom>
                </Stack>
              )}
              <Stack sx={{ flex: 1 }}>
                <Typography
                  sx={{
                    textAlign: "left",
                    flex: 1,
                    maxWidth: "100%",
                    wordBreak: "break-word",
                  }}
                  flexWrap="wrap"
                  variant="caption"
                >
                  {file?.name || `Upload ${index + 1}`}
                </Typography>
                {activeFileProgress.status && (
                  <Stack
                    direction="row"
                    sx={{
                      p: 1.5,
                      borderRadius: 1,
                      background: "#F6E6E7",
                      justifyContent: "space-between",
                      alignItems: "center",
                      pt: 0.5,
                      pb: 0.5,
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{ fontSize: 12, color: "red" }}
                    >
                      Upload Failed
                    </Typography>
                    <IconButton
                      color="inherit"
                      size="medium"
                      onClick={async () => {
                        const controller = new AbortController();
                        controllers.forEach((controller) => {
                          if (controller.key === key) {
                            controller.controller.abort();
                          }
                        });
                        let newControllers = [
                          ...controllers,
                          { key, controller },
                        ];
                        setControllers(newControllers);
                        var formdata = new FormData();
                        formdata.append("file", file, file.name);

                        let result = await uploadDocument({
                          data: formdata,
                          key,
                          controller:
                            newControllers[newControllers.length - 1]
                              .controller,
                          resource,
                        });
                        if (!result.error && result.data) {
                          setFiles((x) =>
                            x.map((prev) =>
                              prev.key === key
                                ? { ...prev, _id: result.data._id }
                                : prev
                            )
                          );
                        }
                      }}
                    >
                      <Refresh />
                    </IconButton>
                  </Stack>
                )}
                {!activeFileProgress.status && (
                  <Stack
                    sx={{
                      width: 155,
                      top: 0,
                      mb: 2,
                    }}
                    direction="column"
                  >
                    <LinearProgressWithLabel
                      sx={{ borderRadius: 32 }}
                      color={
                        activeFileProgress.progress === 100 ? "success" : "info"
                      }
                      value={activeFileProgress.progress}
                    />
                  </Stack>
                )}{" "}
              </Stack>
              <IconButton
                onClick={() => {
                  removeFile(key);
                }}
              >
                <HiXMark />
              </IconButton>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};

export const FileViewerModal = ({ doc, setDoc }) => {
  return (
    <Dialog sx={{ zIndex: 1800 }} open={doc} fullScreen>
      <AppBar elevation={0} sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              setDoc(null);
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Doc Viewer
          </Typography>
        </Toolbar>
      </AppBar>

      {doc && (
        <>
          {doc?.type === "application/pdf" ? (
            <iframe
              title="Document"
              src={doc?.url}
              style={{ height: "100%", width: "100%", zIndex: 1675 }}
            />
          ) : (
            <FileViewer
              style={{ zIndex: 1780 }}
              fileType={doc?.type}
              filePath={doc?.url}
            />
          )}
        </>
      )}
    </Dialog>
  );
};

export const PageContainer = (props) => {
  const checked = true;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grow
      in={checked}
      style={{ transformOrigin: "0 0 0" }}
      {...(checked ? { timeout: 1000 } : {})}
    >
      {isSmallScreen ? (
        <div
          style={{
            flex: 1,
            overflow: "scroll",
            padding: "20px",
            paddingTop: "8px",
            paddingBottom: "164px",
          }}
        >
          {props.children}
        </div>
      ) : (
        <Stack
          sx={{
            p: responsiveProps(2, 2, 2, 6, 6, 6),
            flex: "1 1 auto",
            overflowY: "scroll",
            pl: responsiveProps(2, 2, 2, 7, 7, 7),
            pt: `8px !important`,
          }}
        >
          {props.children}
        </Stack>
      )}
    </Grow>
  );
};

export const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(14),
    padding: 16,
    //   border: "1px solid #dadde9",
  },
}));

export const AntTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  marginRight: theme.spacing(1),
  fontSize: 15,
  color: "rgba(0, 0, 0, 0.85)",
  "&:hover": {
    color: "#1D1E24",
    opacity: 1,
  },
  "&.Mui-selected": {
    color: "black",
    fontWeight: "bold",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "#144C2B",
  },
}));

const MyTabScrollButton = styled(TabScrollButton)({
  "&.Mui-disabled": {
    width: 0,
  },
  overflow: "hidden",
  transition: "width 0.5s",
  width: 28,
});

export const AntTabs = styled((props) => (
  <Tabs
    ScrollButtonComponent={MyTabScrollButton}
    variant="scrollable"
    scrollButtons
    allowScrollButtonsMobile
    {...props}
  />
))(({ theme }) => ({
  borderBottom: "1px solid #F6F6F6",
  "& .MuiTabs-indicator": {},
  //background: "RGBA(243, 253, 244, 0.63)",
  borderTopLeftRadius: 16,
  borderTopRightRadius: 16,
  maxWidth: "100%",
}));

export const CustomToggle = ({
  options,
  selectedOption,
  setSelectedOption,
}) => {
  return (
    <Stack
      style={{
        borderRadius: 32,
        display: "flex",
        justifyContent: "space-between",
        padding: "8px",
        background: "rgb(0, 255, 127, 0.07)",
      }}
      sx={{ mt: 3 }}
      direction="row"
      spacing={2}
    >
      {options.map((option) => (
        <Button
          key={option.value}
          variant={selectedOption === option.value ? "contained" : "outlined"}
          color="primary"
          disableElevation
          style={{
            borderRadius: 32,
            flex: 1,
            height: 45,
          }}
          sx={{
            textTransform: "none",
          }}
          onClick={() => setSelectedOption(option.value)}
        >
          {option.label}
        </Button>
      ))}
    </Stack>
  );
};

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#00A600",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#00A600",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  zIndex: 2,
}));

export const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#00A600",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#00A600",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

export function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};
