import { apiSlice } from "../api.service";
import { refillList } from "../../utils/refillList";

let params = { limit: 25, skip: 0 };

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getButcheries: builder.query({
      query: (options) => {
        params = options;
        return {
          url: `/butchery?${new URLSearchParams(options).toString()}`,
          method: "GET",
        };
      },
      providesTags: [{ type: "Butchery", id: "LIST" }],
    }),
    getButchery: builder.query({
      query: (butcheryID) => {
        return { url: `/butchery/${butcheryID}`, method: "GET" };
      },
      providesTags: (result, error, arg) => [{ type: "Butchery", id: arg }],
    }),
    getButcheryResources: builder.query({
      query: () => {
        return { url: `/resources/butchery`, method: "GET" };
      },
    }),

    getProductAvailabilityResources: builder.query({
      query: () => {
        return {
          url: `/resources/productAvailability`,
          method: "GET",
        };
      },
    }),

    addNewButchery: builder.mutation({
      query: (butchery) => ({
        url: "/butchery",
        method: "POST",
        body: butchery,
      }),
      invalidatesTags: [{ type: "Butchery", id: "LIST" }, "Count"],
    }),
    editButchery: builder.mutation({
      query: (data) => ({
        url: `/butchery/${data._id}`,
        method: "PATCH",
        body: data.butchery,
      }),
      async onQueryStarted({ _id, butchery }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedButchery } = await queryFulfilled;

          dispatch(
            apiSlice.util.updateQueryData("getButcheries", params, (draft) => {
              const butchery = draft.find((e) => e._id === updatedButchery._id);
              if (butchery) {
                Object.keys(updatedButchery).forEach((key) => {
                  butchery[key] = updatedButchery[key];
                });
              }
            })
          );
          dispatch(
            apiSlice.util.updateQueryData("getButchery", _id, (draft) => {
              Object.assign(draft, updatedButchery);
            })
          );
        } catch {}
      },
    }),

    refillList: builder.query({
      queryFn: async (options, api) => {
        let result = await refillList(options, api);
        return result;
      },
    }),
    deleteButchery: builder.mutation({
      query: (_id) => ({
        url: `/butchery/${_id}`,
        method: "DELETE",
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            apiSlice.util.updateQueryData("getButcheries", params, (draft) => {
              const index = draft.findIndex((e) => e._id === _id);
              if (index !== -1) {
                draft.splice(index, 1);
              }
            })
          );
        } catch {}
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetButcheriesQuery,
  useGetButcheryQuery,
  useAddNewButcheryMutation,
  useEditButcheryMutation,
  useDeleteButcheryMutation,
  useRefillListQuery,
  useGetButcheryResourcesQuery,
  useLazyGetButcheryResourcesQuery,
  useGetProductAvailabilityResourcesQuery,
} = extendedApi;
