import { apiSlice } from "../api.service";

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getManagerialLines: builder.query({
      query: () => {
        return {
          url: `/managerial-lines`,
          method: "GET",
        };
      },
      providesTags: [{ type: "ManagerialLine", id: "LIST" }],
    }),
    getManagerialLine: builder.query({
      query: (managerialLineID) => {
        return { url: `/managerial-lines/${managerialLineID}`, method: "GET" };
      },
      providesTags: (result, error, arg) => [
        { type: "ManagerialLine", id: arg },
      ],
    }),
    getManagerialLineResources: builder.query({
      query: () => {
        return { url: `/resources/managerialLine`, method: "GET" };
      },
    }),
    addNewManagerialLine: builder.mutation({
      query: (managerialLine) => ({
        url: "/managerial-lines",
        method: "POST",
        body: managerialLine,
      }),
      invalidatesTags: [{ type: "ManagerialLine", id: "LIST" }],
    }),
    editManagerialLine: builder.mutation({
      query: (data) => ({
        url: `/managerial-lines/${data._id}`,
        method: "PATCH",
        body: data.managerialLine,
      }),
      async onQueryStarted(
        { _id, managerialLine },
        { dispatch, queryFulfilled }
      ) {
        try {
          const { data: updatedManagerialLine } = await queryFulfilled;

          dispatch(
            apiSlice.util.updateQueryData(
              "getManagerialLines",
              undefined,
              (draft) => {
                const managerialLine = draft.find(
                  (e) => e._id === updatedManagerialLine._id
                );
                if (managerialLine) {
                  Object.keys(updatedManagerialLine).forEach((key) => {
                    managerialLine[key] = updatedManagerialLine[key];
                  });
                }
              }
            )
          );
          dispatch(
            apiSlice.util.updateQueryData("getManagerialLine", _id, (draft) => {
              Object.assign(draft, updatedManagerialLine);
            })
          );
        } catch {}
      },
    }),

    deleteManagerialLine: builder.mutation({
      query: (_id) => ({
        url: `/managerial-lines/${_id}`,
        method: "DELETE",
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            apiSlice.endpoints.refillList.initiate({
              endpoint: "/managerial-lines",
              resource: "managerialLine",
              data: [_id],
            })
          );
        } catch {}
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetManagerialLinesQuery,
  useGetManagerialLineQuery,
  useGetManagerialLineResourcesQuery,
  useLazyGetManagerialLineResourcesQuery,
  useAddNewManagerialLineMutation,
  useEditManagerialLineMutation,
  useDeleteManagerialLineMutation,
} = extendedApi;
