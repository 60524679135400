import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

import LinearProgress from "@mui/material/LinearProgress";
import { HiXMark } from "react-icons/hi2";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { Check } from "@mui/icons-material";
import { constants } from "../../../config/constants";
import { useDispatch } from "react-redux";
import ButcheryFields from "../outlet-fields/view";
import { updateSnack } from "../../../state/slices/snack";
import { useAddNewButcheryMutation } from "../../../api/api-endpoints/butchery";

export default function AddButchery({ setOpen }) {
  const dispatch = useDispatch();

  const [addButchery, { isLoading }] = useAddNewButcheryMutation();

  const submitForm = async () => {
    const form = document.getElementById("new-butchery-form");
    const butchery = {};
    for (let i = 0; i < form.elements.length; i++) {
      var e = form.elements[i];
      if (e.name) {
        if (e.name !== "butcheryName") {
          if (e.value.trim() !== "") {
            butchery[e.name] = e.value.trim();
          }
        } else {
          butchery.name = e.value;
        }
      }
    }
    try {
      let result = await addButchery(butchery);
      if (result.error) {
        throw Error(result.error);
      }
      setOpen(false);
      dispatch(updateSnack({ open: true, msg: "Butchery added successully" }));
    } catch (e) {
      console.log("error", e.message);
    }
  };

  const handleBlur = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <Box
      sx={{
        width: "100vw",
        maxWidth: "500px",
        overflow: "scroll",
        "& fieldset": {
          borderRadius: 2,
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "rgba(0, 0, 0, 0.03)",
            color: "black",
          },
          "&.Mui-focused fieldset": {
            boxShadow: "5px 5px 24px 1px rgba(0, 134, 211, 0.1)",
          },
        },
      }}
    >
      <Stack sx={{ p: 2 }}>
        <Stack
          spacing={2}
          direction="row"
          sx={{ pl: 1, alignItems: "center", mb: 2, mt: 1 }}
        >
          <IconButton
            onClick={() => {
              setOpen(false);
            }}
          >
            <HiXMark />
          </IconButton>
          <Typography variant="h6">
            <b>Add a butchery</b>
          </Typography>
        </Stack>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitForm();
          }}
          action="/"
          id="new-butchery-form"
        >
          <ButcheryFields handleBlur={handleBlur} />
          <Divider sx={{ opacity: 0.3, mt: 3 }} />
          {isLoading && <LinearProgress />}
          <Stack
            sx={{ mt: 3, mb: 1, justifyContent: "flex-end" }}
            direction="row"
          >
            <Button
              disabled={isLoading}
              startIcon={<Check />}
              variant="contained"
              sx={{
                borderRadius: 32,
                height: 50,
                textTransform: "none",
                mr: 1,
              }}
              type="submit"
              disableElevation
            >
              Save Butchery
            </Button>
            <Button
              onClick={() => {
                setOpen(false);
              }}
              variant="outlined"
              color="warning"
              sx={{ borderRadius: 32, height: 50, textTransform: "none" }}
              disableElevation
            >
              Cancel
            </Button>
          </Stack>
        </form>
      </Stack>
    </Box>
  );
}
