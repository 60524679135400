import {
  Stack,
  Typography,
  IconButton,
  Collapse,
  Divider,
  LinearProgress,
} from "@mui/material";
import React from "react";
import ConfirmationDialog from "../../../layouts/common/dialogs/confirm";
import AddOrUpdateCity from "./addOrUpdate";
import { HiPencil, HiPlusCircle, HiWrench } from "react-icons/hi2";
import { MdDelete } from "react-icons/md";
import {
  useGetCitiesQuery,
  useDeleteCityMutation,
} from "../../../api/api-endpoints/city";
import { responsiveProps } from "../../../utils/functions";
import { load } from "langchain/load";

export default function CityManagement() {
  const {
    data: cities = [],
    isLoading,
    isFetching,
    refetch,
  } = useGetCitiesQuery();

  const [openAdd, setOpenAdd] = React.useState(false);

  const [confirmationDialog, setConfirmationDialog] = React.useState({
    open: false,
    msg: "",
    action: () => {},
  });

  const [removeCity, { isLoading: isRemoving }] = useDeleteCityMutation();
  const loading = isLoading || isFetching || isRemoving;
  const deleteCity = async (id) => {
    try {
      await removeCity(id);
    } catch (e) {}
  };

  const [activeCity, setActiveCity] = React.useState(null);
  return (
    <>
      <Stack
        sx={{
          border: 1,
          borderStyle: "dashed",
          borderColor: "rgb(0, 0, 0, 0.1)",
          p: responsiveProps(2, 2, 2, 4, 4, 4),
          borderRadius: 3,
          mt: 2,
          boxShadow: "5px 5px 24px 1px rgba(0, 0, 0, 0.05)",
        }}
      >
        <Stack
          alignItems="center"
          justifyContent="space-between"
          direction="row"
        >
          <Stack>
            <Typography variant="h6">
              <b>Operational Cities</b>
            </Typography>
            <Typography variant="caption">
              {cities ? `${cities.length} added` : "Nothing here yet"}
            </Typography>
          </Stack>
          <IconButton
            onClick={() => {
              setActiveCity(null);
              setOpenAdd(true);
            }}
            color="success"
          >
            <HiPlusCircle />
          </IconButton>
        </Stack>
        <Collapse in={cities && !loading}>
          <Divider sx={{ mt: 2, mb: 2, opacity: 0.15 }} />
        </Collapse>
        <Collapse in={loading}>
          <LinearProgress
            color="inherit"
            sx={{ mt: 2, mb: 2, borderRadius: 32 }}
          />
        </Collapse>
        <Collapse in={cities}>
          <Stack spacing={3} sx={{}}>
            {cities.map(({ name, province, active, _id }) => (
              <Stack>
                <Stack sx={{ flex: 1 }}>
                  <Typography variant="body1">{name}</Typography>
                  <Typography sx={{ color: "#00322F" }} variant="caption">
                    {province}
                  </Typography>
                </Stack>
                <Stack alignItems="center" direction="row" spacing={1.5}>
                  <IconButton
                    disabled={loading}
                    onClick={() => {
                      setActiveCity({ name, province, active, _id });
                      setOpenAdd(true);
                    }}
                    size="small"
                  >
                    <HiPencil size={16} />
                  </IconButton>
                  <IconButton
                    disabled={loading}
                    onClick={() => {
                      setConfirmationDialog({
                        open: true,
                        msg: `Are you sure you would like to remove '${name}' from your list of operational cities?`,
                        action: () => {
                          setConfirmationDialog((prev) => ({
                            ...prev,
                            open: false,
                          }));
                          deleteCity(_id);
                        },
                      });
                    }}
                    size="small"
                  >
                    <MdDelete />
                  </IconButton>
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Collapse>
      </Stack>
      <AddOrUpdateCity
        activeCity={activeCity}
        open={openAdd}
        onClose={() => {
          setOpenAdd(false);
        }}
      />
      <ConfirmationDialog
        dialog={confirmationDialog}
        setDialog={setConfirmationDialog}
      />
    </>
  );
}
