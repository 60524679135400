import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import * as React from "react";
import Grow from "@mui/material/Grow";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { useGetUserQuery } from "../../api/api-endpoints/user";
import { Chip, Divider, Grid, LinearProgress } from "@mui/material";
import {
  CiBellOn,
  CiGrid42,
  CiMail,
  CiRoute,
  CiSettings,
} from "react-icons/ci";
import Badge from "@mui/material/Badge";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { PageContainer } from "../../layouts/common/general/components";
import { useSelector } from "react-redux";
import { useRouteLoaderData } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Account() {
  let { _id } = useSelector((state) => state.user.user);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const {
    data: user,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetUserQuery(_id);

  console.log(user);

  return (
    <PageContainer>
      <Stack
        sx={{
          p: 4,
          borderRadius: 4,
          mt: 2,
          //   boxShadow: "5px 5px 24px 1px rgba(0, 0, 0, 0.09)",
          pb: 0,
          //   borderStyle: "solid",
          //  borderColor: "rgba(0, 0, 0, 0.08)",
          borderColor: "rgb(0, 0, 0, 0.02)",
          borderStyle: "solid",
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
      >
        <Stack spacing={3} direction="row">
          <Stack sx={{ position: "relative" }}>
            <img
              style={{
                width: "100%",
                maxWidth: "140px",
                height: "135px",
                borderRadius: 16,
                objectFit: "cover",
              }}
              src="https://preview.keenthemes.com/metronic8/demo1/assets/media/avatars/300-1.jpg"
              alt="profile"
            />
            <Stack
              sx={{
                position: "absolute",
                top: 65,
                right: -5,
                borderColor: "white",
                height: 10,
                width: 10,
                borderRadius: 5,
                background: "#1A9134",
                borderWidth: 2,
                borderStyle: "solid",
              }}
            ></Stack>
          </Stack>
          <Stack>
            <Typography sx={{ mt: 1 }} componen="h6" variant="h6">
              <b>
                {user?.firstName} {user?.lastName}
              </b>
            </Typography>
            <Chip
              sx={{ pl: 0.5 }}
              icon={<CiMail style={{ height: 16, width: 16 }} />}
              label={user?.email}
            />
          </Stack>
        </Stack>

        <Box sx={{ mt: 3 }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              icon={<CiGrid42 />}
              iconPosition="start"
              sx={{ textTransform: "none", fontSize: 16 }}
              label="Overview"
              {...a11yProps(0)}
            />
            <Tab
              icon={<CiSettings />}
              iconPosition="start"
              sx={{ textTransform: "none", fontSize: 16 }}
              label="Settings"
              {...a11yProps(1)}
            />
            <Tab
              icon={<CiRoute />}
              iconPosition="start"
              sx={{ textTransform: "none", fontSize: 16 }}
              label="Activity"
              {...a11yProps(2)}
            />
            <Tab
              icon={<CiBellOn />}
              iconPosition="start"
              sx={{ textTransform: "none", fontSize: 16 }}
              label="Notifications"
              {...a11yProps(3)}
            />
          </Tabs>
        </Box>
      </Stack>
      <Stack
        sx={{
          p: 4,
          borderRadius: 4,

          //   boxShadow: "5px 5px 24px 1px rgba(0, 0, 0, 0.02)",

          borderColor: "rgb(0, 0, 0, 0.02)",

          borderStyle: "solid",
          borderTopRightRadius: 0,
          borderTopLeftRadius: 0,
          borderTopWidth: 0,
        }}
      >
        <Typography component="h6" variant="h6">
          <b>Profile Details</b>
        </Typography>

        <Divider sx={{ opacity: 0.1, mt: 2, mb: 3 }} />

        <Grid
          spacing={{
            xs: 0,
            sm: 2,
            md: 2,
            lg: 2,
          }}
          container
        >
          {[
            {
              title: "Full name",
              value: `${user?.firstName} ${user?.lastName}`,
            },
            {
              title: "Company position",
              value: `${user?.companyPosition || "Not set"}`,
            },
            {
              title: "Procurement role",
              value: `${user?.role?.name || "Not set"}`,
            },
            {
              title: "Phone number",
              value: `${user?.phone || "Not set"}`,
            },
            {
              title: "Email address",
              value: `${user?.email || "Not set"}`,
            },
            {
              title: "Location",
              value: `${user?.location?.name || "Not set"}`,
            },
            {
              title: "Department",
              value: `${
                user?.location?.departments?.find(
                  (x) => x._id === user?.department
                )?.name || "Not set"
              }`,
            },
          ].map(({ title, value }, index, arr) => (
            <>
              <Grid xs={12} sm={12} md={3} lg={3} item>
                <Typography>{title}</Typography>
              </Grid>
              <Grid xs={12} sm={12} md={9} lg={9} item>
                <Typography>
                  <b>{value}</b>
                </Typography>
              </Grid>
              {index !== arr.length - 1 && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    display: { sm: "flex", xs: "flex", md: "none", lg: "none" },
                  }}
                >
                  <Divider sx={{ flex: 1, opacity: 0.2, mt: 2, mb: 2 }} />
                </Grid>
              )}
            </>
          ))}
        </Grid>
      </Stack>
      <Grow in={isLoading}>
        <LinearProgress sx={{ mt: 2 }} />
      </Grow>
      {isSuccess && <Stack></Stack>}
    </PageContainer>
  );
}
