import * as React from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";

export default function RouterBreadcrumbs({ item = null }) {
  const location = useLocation();

  const pathnames = location.pathname.split("/").filter((x) => x);

  const navigate = useNavigate();

  if (pathnames.length < 2 || location?.key === "default") {
    return <div />;
  }
  /**
   *  {pathnames[pathnames.length - 2].charAt(0).toUpperCase()}
        {pathnames[pathnames.length - 2].slice(
          1,
          pathnames[pathnames.length - 2].length
        )}
   */

  return (
    <Stack>
      <Button
        onClick={() => {
          navigate(-1);
        }}
        color="inherit"
        startIcon={<ArrowBack />}
        sx={{
          alignSelf: "flex-start",
          textTransform: "none",
          fontSize: 16,
          borderRadius: 32,
        }}
      >
        <b>Back</b>
      </Button>
    </Stack>
  );
}
