import { apiSlice } from "../api.service";
import { authHeader } from "../auth-header";
import { refillList } from "../../utils/refillList";
import { constants } from "../../config/constants";
import axios from "axios";

let params = { limit: 25, skip: 0 };

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: (options) => {
        params = options;
        return {
          url: `/users?${new URLSearchParams(options).toString()}`,
          method: "GET",
        };
      },
      providesTags: [{ type: "User", id: "LIST" }],
    }),
    getUser: builder.query({
      query: (userID) => {
        return { url: `/users/${userID}`, method: "GET" };
      },
      providesTags: (result, error, arg) => [{ type: "User", id: arg }],
    }),
    getUserResources: builder.query({
      query: () => {
        return { url: `/resources/user`, method: "GET" };
      },
    }),
    getAssignDriverResources: builder.query({
      query: () => {
        return { url: `/resources/assign-driver`, method: "GET" };
      },
    }),
    getUserRoleResources: builder.query({
      query: () => {
        return { url: `/resources/role`, method: "GET" };
      },
    }),
    saveImport: builder.mutation({
      query: (body) => ({
        url: `/resources/import`,
        method: "POST",
        body,
      }),
    }),
    addNewUser: builder.mutation({
      query: (user) => ({
        url: "/users",
        method: "POST",
        body: user,
      }),
      invalidatesTags: [{ type: "User", id: "LIST" }],
    }),
    editUser: builder.mutation({
      query: (data) => ({
        url: `/users/${data._id}`,
        method: "PATCH",
        body: data.user,
      }),
      async onQueryStarted({ _id, user }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedUser } = await queryFulfilled;

          dispatch(
            apiSlice.util.updateQueryData("getUsers", params, (draft) => {
              const user = draft.find((e) => e._id === updatedUser._id);
              if (user) {
                Object.keys(updatedUser).forEach((key) => {
                  user[key] = updatedUser[key];
                });
              }
            })
          );
          dispatch(
            apiSlice.util.updateQueryData("getUser", _id, (draft) => {
              Object.assign(draft, updatedUser);
            })
          );
        } catch {}
      },
    }),
    getResourceCount: builder.query({
      queryFn: async (options, api) => {
        try {
          const pagination = api.getState().pagination[options.resource];

          let extraFilters = {};

          let tail = `${options.resource}?${
            pagination.filters
              ? "filters=" +
                JSON.stringify({ ...pagination.filters, ...extraFilters })
              : ""
          }${pagination?.q !== "" ? `&q=${pagination.q}` : ""}`;

          const result = await axios.get(
            constants.apiUrl + "/resources/count/" + tail,

            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          return { data: result.data };
        } catch (axiosError) {
          let err = axiosError;

          return {
            error: {
              status: err.response?.status,
              data: err.response?.data || err.message,
            },
          };
        }
      },
      providesTags: ["Count"],
    }),
    refillList: builder.query({
      queryFn: async (options, api) => {
        let result = await refillList(options, api);
        return result;
      },
    }),
    deleteUser: builder.mutation({
      query: (_id) => ({
        url: `/users/${_id}`,
        method: "DELETE",
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            apiSlice.endpoints.refillList.initiate({
              endpoint: "/users",
              resource: "user",
              data: [_id],
            })
          );
        } catch {}
      },
    }),
    deleteUsers: builder.mutation({
      query: (data) => ({
        url: `/users/many`,
        method: "DELETE",
        body: data,
      }),
    }),
    getApprovers: builder.query({
      query: () => {
        return { url: "/roles/approvers", method: "GET" };
      },
    }),
    archiveUsers: builder.mutation({
      query: (data) => ({
        url: `/users/archive`,
        method: "POST",
        body: data,
      }),
      async onQueryStarted({ list, status }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            apiSlice.util.updateQueryData("getUsers", params, (draft) => {
              list.forEach((item) => {
                const user = draft.find((e) => e._id === item);
                if (user) {
                  user.status = status;
                }
              });
            })
          );
        } catch (e) {
          console.log("Error", e.message);
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUsersQuery,
  useGetUserQuery,
  useGetUserResourcesQuery,
  useLazyGetUserResourcesQuery,
  useAddNewUserMutation,
  useEditUserMutation,
  useDeleteUserMutation,
  useDeleteUsersMutation,
  useGetResourceCountQuery,
  useGetApproversQuery,
  useGetUserRoleResourcesQuery,
  useSaveImportMutation,
  useRefillListQuery,
  useArchiveUsersMutation,
  useLazyGetAssignDriverResourcesQuery,
} = extendedApi;
