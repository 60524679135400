import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import * as React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
} from "@mui/material";
import { useState } from "react";
import { CustomInput } from "../../../components/global";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import {
  useAddNewCityMutation,
  useEditCityMutation,
} from "../../../api/api-endpoints/city";

import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseTwoTone from "@mui/icons-material/CloseTwoTone";
import Alert from "@mui/material/Alert";

import { HiCheck, HiXMark } from "react-icons/hi2";

const TitleDescription = ({ title, description }) => (
  <>
    <Typography>
      <b>{title}</b>
    </Typography>
    <Typography variant="caption">{description}</Typography>
  </>
);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddOrUpdateCity({ open, onClose, activeCity }) {
  const [city, setCity] = useState({
    name: "",
    province: "",
  });

  React.useEffect(() => {
    if (open && activeCity) {
      setCity({ name: activeCity.name, province: activeCity.province });
    }
  }, [open, activeCity]);

  const [addCity, { isLoading: isSaving }] = useAddNewCityMutation();
  const [editCity, { isLoading: isUpdating }] = useEditCityMutation();
  const createOrUpdateCity = async () => {
    try {
      let result = activeCity
        ? await editCity({
            _id: activeCity._id,
            city: {
              ...city,
              active: activeCity.active,
            },
          })
        : await addCity(city);
      if (result.error) {
        if (result.error.data) {
          throw new Error(result.error.data.error);
        } else {
          throw new Error("Something went wrong...");
        }
      }
      setCity({
        name: "",
        province: "",
      });
      onClose();

      /**
      *  setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 4000);
      */
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleBlur = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const [showAlert, setShowAlert] = React.useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCity((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const closeModal = () => {
    setCity({
      name: "",
      province: "",
    });
    onClose();
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      keepMounted={false}
      onClose={closeModal}
      open={open}
      fullWidth
      maxWidth="xs"
      PaperProps={{
        sx: {
          borderRadius: 2,
        },
      }}
    >
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">
            {activeCity ? <b>Update city</b> : <b>Add a city</b>}
          </Typography>
          <IconButton onClick={closeModal} disabled={isSaving}>
            <HiXMark />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent>
        <Stack
          sx={{
            flex: 1,
            py: 3,
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                background: "rgba(0, 0, 0, 0.02)",
                borderRadius: 2,
                borderColor: "white",
              },
            },
          }}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              createOrUpdateCity();
            }}
          >
            <Grid sx={{}} container spacing={3}>
              <Grid item xs={12} sm={12} md={12}>
                <Stack spacing={3}>
                  <CustomInput
                    required
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={city.name}
                    name="name"
                    label="City name"
                    fullWidth
                  />
                  <CustomInput
                    required
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={city.province}
                    name="province"
                    label="Province"
                    fullWidth
                  />
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12}>
                <Collapse in={showAlert}>
                  <Alert
                    sx={{ mt: 3 }}
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setShowAlert(false);
                        }}
                      >
                        <CloseTwoTone fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    New city added successfully!
                  </Alert>
                </Collapse>
              </Grid>
            </Grid>

            <Stack sx={{ mt: 3 }}>
              <Collapse in={isSaving || isUpdating}>
                <LinearProgress sx={{ borderRadius: 32, mt: 2, mb: 1 }} />
              </Collapse>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                  <Stack spacing={2} justifyContent="flex-end" direction="row">
                    <Button
                      type="submit"
                      endIcon={<HiCheck />}
                      disabled={isSaving || isUpdating}
                      sx={{
                        mr: 1,
                        ml: 1,
                        height: 50,
                        borderRadius: 32,
                        textTransform: "none",
                      }}
                    >
                      Save Details
                    </Button>
                    <Button
                      type="reset"
                      color="warning"
                      onClick={closeModal}
                      endIcon={<HiXMark />}
                      disabled={isSaving}
                      disableElevation
                      sx={{
                        mr: 1,
                        ml: 1,
                        height: 50,
                        borderRadius: 32,
                        textTransform: "none",
                      }}
                    >
                      Close
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </form>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
