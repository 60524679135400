import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Stack } from "@mui/material";
import * as React from "react";
import Grid from "@mui/material/Grid";

const formItems = {
  firstName: {
    type: "text",
    required: true,
    label: "First name",
  },
  lastName: {
    type: "text",
    required: true,
    label: "Last name",
  },
  email: {
    type: "email",
    label: "Email address",
    required: true,
  },
  phone: {
    type: "phone",
    label: "Phone number",
    required: true,
  },
  companyPosition: {
    type: "text",
    label: "Company position",
    required: true,
  },
};

export default function FormContainer({ open }) {
  const handleBlur = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <Grid container spacing={2} sx={{}}>
        <Grid
          sx={{
            alignItems: "stretch",
            display: "flex",
          }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          item
        >
          <Stack
            sx={{
              //  boxShadow: "5px 5px 24px 1px rgba(0, 0, 0, 0.04)",
              borderRadius: 3,
              //  p: 3,
              flex: 1,
            }}
          >
            <Grid container>
              {Object.keys(formItems).map((key, index) => (
                <Grid
                  item
                  sx={{ p: 1.5, pt: 0 }}
                  xs={12}
                  sm={12}
                  md={6}
                  key={index}
                >
                  <Typography variant="caption">
                    {formItems[key].label}
                    {formItems[key].required && (
                      <span style={{ color: "red", fontSize: 16 }}>*</span>
                    )}
                  </Typography>
                  <TextField
                    name={key}
                    onBlur={handleBlur}
                    required={formItems[key].required || false}
                    sx={{ mt: 1 }}
                    type={formItems[key].type}
                    fullWidth
                    placeholder={formItems[key].label}
                  />
                </Grid>
              ))}
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
