import React, { useState } from "react";
import "./CircularProgress.css";

import { Typography, Box, InputBase } from "@mui/material";

export const CustomInput = ({ label, dropdown, endAdornment, ...props }) => {
  const [focused, setFocused] = useState(false);

  const value = props.inputProps?.value || props.value || props.defaultValue;

  const { InputProps, ...otherProps } = props;
  const { value: v, ...rest } = InputProps || {};

  return (
    <label style={{ width: "100%" }} htmlFor={"id" + props.name}>
      <Box
        sx={{
          width: "100%",
          borderRadius: 2,
          transition: "all ease 0.2s",
          border: focused
            ? "2px solid #8a2be2"
            : "1px solid rgba(0, 0, 0, 0.05)",
          position: "relative",
          "&:hover": {
            borderColor: "rgba(0, 0, 0, 0.87)",
          },
          "&:focus-within": {
            borderColor: "rgba(0, 0, 0, 0.87)",
          },
        }}
      >
        <Typography
          variant="body2"
          component="span"
          sx={{
            position: "absolute",
            left: 10,
            top: value || focused ? 15 : "50%",
            zIndex: 1,
            padding: "0 5px",
            transform:
              value || focused
                ? "scale(0.8) translateY(-50%)"
                : "translateY(-50%)",
            transition: "0.3s",
          }}
        >
          {label}
        </Typography>
        <InputBase
          autoComplete={dropdown ? "new-password" : "on"} // Set autoComplete based on dropdown prop
          {...otherProps}
          {...rest}
          value={value}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          sx={{
            padding: "24px 5px 5px 5px",
            pl: 3,
            pr: endAdornment ? "35px" : 3, // Provide extra padding if there's an adornment
          }}
          id={"id" + props.name}
        />
        {endAdornment && (
          <Box
            sx={{
              position: "absolute",
              right: 10,
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            {endAdornment}
          </Box>
        )}
      </Box>
    </label>
  );
};
