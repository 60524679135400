import React from "react";
import { Typography, Grid, Stack } from "@mui/material";
import { styled } from "@mui/system";
import { CSSTransition } from "react-transition-group";
import moment from "moment";
import { responsiveProps } from "../../utils/functions";
import "./ReportComponent.css"; // Add the CSS file for animations
import { constants, numberWithCommas } from "../../config/constants";

const ReportPaper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: "center",
  //color: theme.palette.text.secondary,
}));

const formatDuration = (durationInMs) => {
  const duration = moment.duration(durationInMs);
  const days = Math.floor(duration.asDays());
  const hours = duration.hours();
  const minutes = duration.minutes();
  return `${days}d ${hours}h ${minutes}m`;
};

const ReportComponent = ({ report }) => {
  return (
    <CSSTransition
      in={report !== null}
      timeout={300}
      classNames="fade"
      unmountOnExit
    >
      <Stack
        sx={{
          borderStyle: "dashed",
          borderColor: "rgb(0, 0, 0, 0.1)",
          p: responsiveProps(2, 2, 2, 4, 4, 4),
          borderRadius: 3,
          mt: 2,
        }}
      >
        <Grid container spacing={3}>
          {report && (
            <>
              <Grid item xs={12} sm={6}>
                <ReportPaper>
                  <Typography variant="h6">Efficiency</Typography>
                  <Typography>
                    Avg. Received to Ready for Pickup:{" "}
                    {formatDuration(
                      report.efficiency.avgReceivedToReadyForPickup
                    )}
                  </Typography>
                  {false && (
                    <Typography>
                      Avg. Ready for Pickup to Picked Up:{" "}
                      {formatDuration(
                        report.efficiency.avgReadyForPickupToPickedUp
                      )}
                    </Typography>
                  )}
                  <Typography>
                    Avg. Received to Dispatched:{" "}
                    {formatDuration(report.efficiency.avgReceivedToDispatched)}
                  </Typography>
                  {false && (
                    <Typography>
                      Avg. Dispatched to Delivered:{" "}
                      {formatDuration(
                        report.efficiency.avgDispatchedToDelivered
                      )}
                    </Typography>
                  )}
                </ReportPaper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ReportPaper>
                  <Typography variant="h6">Kilos Per Item</Typography>
                  {report.kilosPerItem.map((item) => (
                    <Typography key={item._id}>
                      {item.name}:{" "}
                      {numberWithCommas(item.totalQuantity?.toFixed(2))} kg
                    </Typography>
                  ))}
                </ReportPaper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ReportPaper>
                  <Typography variant="h6">Pickup vs Delivery</Typography>
                  {report.pickupVsDelivered.map((item) => (
                    <Typography key={item.status}>
                      {item.status === "pickedUp" ? "Pickup" : "Delivery"}:{" "}
                      {item.percentage?.toFixed(1)}%
                    </Typography>
                  ))}
                </ReportPaper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ReportPaper>
                  <Typography variant="h6">Revenue Per Month</Typography>
                  {report.revenuePerMonth.map((monthData) => {
                    const date = moment()
                      .year(monthData.year)
                      .month(monthData.month - 1); // Month is 0-indexed in Moment.js
                    return (
                      <Typography key={`${monthData.year}-${monthData.month}`}>
                        {date.format("MMMM YYYY")}:{" "}
                        <b>
                          {constants.currency.symbol}
                          {numberWithCommas(monthData.totalRevenue?.toFixed(2))}
                        </b>
                      </Typography>
                    );
                  })}
                </ReportPaper>
              </Grid>
            </>
          )}
        </Grid>
      </Stack>
    </CSSTransition>
  );
};

export default ReportComponent;
