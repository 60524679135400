import Stack from "@mui/material/Stack";
import { useState } from "react";
import FormContainer from "./components/form";
import { Navigate, useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { constants } from "../../../config/constants";
import { setUser } from "../../../state/slices/user";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
const Join = () => {
  const [profile, setProfile] = useState({
    password: "",
    confirmationPassword: "",
  });
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(null);

  const [searchParams, setSearchParams] = useSearchParams();

  let invitation_key = searchParams.getAll("invitation_key");

  const handJoin = () => {
    setError(null);
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      password: profile.password,
      invitation: invitation_key[0],
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(constants.apiUrl + "/users/join", requestOptions)
      .then(async (response) => response.json())
      .then((result) => {
        if (result.error) {
          setError(result.error);
        } else {
          if (result.user) {
            Cookies.set("token", result.token, { expires: 5 });
            dispatch(setUser({ loaded: true, user: result.user }));
            navigate("/configuration");
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setError(error.message);
      });
  };

  if (invitation_key.length === 0) {
    return <Navigate to="/signin" replace="true" />;
  }

  return (
    <Stack>
      <Stack sx={{ height: "100vh", width: "100vw" }} direction="row">
        <FormContainer
          error={error}
          handJoin={handJoin}
          loading={loading}
          profile={profile}
          setProfile={setProfile}
        />
      </Stack>
    </Stack>
  );
};
export default Join;
