import { useRouteError } from "react-router-dom";
import Stack from "@mui/material/Stack";

export default function ErrorPage() {
  const error = useRouteError();

  return (
    <Stack
      sx={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <div id="error-page">
        <h1>Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
        <p>
          <i>{error?.statusText || error?.message}</i>
        </p>
      </div>
    </Stack>
  );
}
