import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import { EmailRounded, Visibility, VisibilityOff } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";

const FormContainer = ({
  profile,
  setProfile,
  loading,
  handleLogin,
  error,
}) => {
  const navigate = useNavigate();

  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleBlur = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <Stack
      sx={{
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            // background: "rgba(0, 0, 0, 0.015)",
            borderRadius: 2,
            borderColor: "rgb(0, 0, 0, 0.02)",
          },
        },

        alignItems: "center",
        flex: 1,
      }}
    >
      <Stack sx={{ width: "100%", maxWidth: "420px" }}>
        <Stack
          sx={{
            p: 3,
            mt: {
              xs: 4,
              xsm: 4,
              sm: 4,
              md: 15,
              lg: 15,
              xl: 15,
            },
            flex: 1,
          }}
        >
          <Typography variant="h4" component="h4">
            <b>Sign in to continue</b>
          </Typography>
          {false && (
            <Typography sx={{ mt: 1 }}>
              Forgot password?
              <Button
                onClick={() => {
                  navigate("../reset");
                }}
                sx={{ textTransform: "none", ml: 0.5, borderRadius: 32 }}
              >
                <span style={{ color: "#009834" }}>Reset</span>
              </Button>
            </Typography>
          )}

          {loading && <LinearProgress sx={{ mt: 2 }} />}

          {error && (
            <Alert sx={{ mt: 2 }} severity="error">
              {error}
            </Alert>
          )}

          <TextField
            sx={{ mt: 3 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailRounded size={10} />
                </InputAdornment>
              ),
            }}
            onBlur={handleBlur}
            value={profile.email}
            onChange={(e) => {
              setProfile((profile) => ({
                ...profile,
                email: e.target.value,
              }));
            }}
            type="email"
            size="medium"
            fullWidth
            placeholder="Email address"
          ></TextField>

          <TextField
            sx={{ mt: 3 }}
            onBlur={handleBlur}
            type={passwordVisible ? "text" : "password"}
            value={profile.password}
            onChange={(e) => {
              setProfile((profile) => ({
                ...profile,
                password: e.target.value,
              }));
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    onClick={() => {
                      setPasswordVisible((passwordVisible) => !passwordVisible);
                    }}
                    size="small"
                  >
                    {passwordVisible ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            placeholder="Password"
            size="medium"
            fullWidth
          ></TextField>

          <Button
            disabled={loading}
            onClick={() => {
              handleLogin();
            }}
            sx={{ mt: 2, height: 55, borderRadius: 2, textTransform: "none" }}
            disableElevation
            variant="contained"
            size="large"
          >
            Sign in
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
export default FormContainer;
