import * as React from "react";
import { HiArrowRight } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";

export default function RowActionItem({ item, openDrawer }) {
  const { _id } = item;
  const navigate = useNavigate();

  return (
    <IconButton
      onClick={() => {
        navigate(_id);
      }}
    >
      <HiArrowRight />
    </IconButton>
  );
}
