import { useLocation, Navigate, Outlet } from "react-router-dom";
import { AllowPageAccess } from "../utils/authorization";

import React from "react";

const Auth = ({ allowedPermissions }) => {
  const location = useLocation();

  return AllowPageAccess(allowedPermissions) ? (
    <Outlet />
  ) : (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  );
};

export default Auth;
