import Typography from "@mui/material/Typography";
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import {
  Button,
  Chip,
  Collapse,
  DialogActions,
  Divider,
  IconButton,
  LinearProgress,
} from "@mui/material";
import { IoIosSend } from "react-icons/io";
import { useSaveImportMutation } from "../../../api/api-endpoints/user";
import { useUploadMutation } from "../../../api/api-endpoints/documents";
import { HiPlusCircle } from "react-icons/hi2";
import { Stack } from "@mui/system";
import { useSelector } from "react-redux";
import CsvIllustration from "../../../assets/illustrations/csv.png";
import { IoClose } from "react-icons/io5";

function truncateString(str = "", num) {
  // If the length of str is less than or equal to num
  // just return str--don't truncate it.
  if (str.length <= num) {
    return str;
  }
  // Return str truncated with '...' concatenated to the end of str.
  return str.slice(0, num) + "...";
}

const DataImport = ({ dialog, setDialog }) => {
  const [addDocument] = useUploadMutation();
  const [saveImport, { isLoading }] = useSaveImportMutation();
  const [fileState, setFileState] = React.useState(null);
  const [file, setFile] = React.useState(null);

  const [dragActive, setDragActive] = React.useState(false);

  const inputRef = React.useRef(null);

  const [controllers, setControllers] = React.useState([]);

  const uploadFile = async (file) => {
    if (!file) {
      return;
    }
    setFileState("uploading");
    const controller = new AbortController();
    var formdata = new FormData();
    formdata.append("file", file.file, file.file.name);
    let newControllers = [...controllers, { key: file.key, controller }];
    setControllers(newControllers);
    let result = await addDocument({
      data: formdata,
      key: file.key,
      controller,
      resource: "data_import",
    });
    if (!result.error && result.data) {
      setFile((file) => ({ ...file, _id: result.data._id }));
      setFileState("uploaded");
    } else {
      setFileState("error");
    }
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (ev) {
    ev.preventDefault();
    ev.stopPropagation();
    setDragActive(false);
    if (file) {
      return;
    }
    if (ev.dataTransfer.items) {
      [...ev.dataTransfer.items].forEach((item, i) => {
        if (item.kind === "file") {
          const file = item.getAsFile();
          if (validateCsv(file)) {
            setFile({ file, key: Date.now() + i.toString() });
            uploadFile({ file, key: Date.now() + i.toString() });
          }
        }
      });
    } else {
      [...ev.dataTransfer.files].forEach((file, i) => {
        if (validateCsv(file)) {
          setFile({ file, key: Date.now() + i.toString() });
          uploadFile({ file, key: Date.now() + i.toString() });
        }
      });
    }
  };

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      let temp = [...e.target.files];
      temp.forEach((file, index) => {
        temp[index] = { file, key: Date.now() + index.toString() };
      });
      temp = temp.filter((a) => validateCsv(a.file));

      temp.length > 0 && setFile(temp[0]);
      temp.length > 0 && uploadFile(temp[0]);
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const uploadProgress = useSelector(
    (state) => state.uploadProgress['"data_import"']
  );
  let myProgress = uploadProgress?.find((a) => a.key === file?.key) || {
    progress: 0,
    key: file?.key,
  };

  function validateCsv(file) {
    if (file.name.indexOf(".csv") === -1) {
      alert("Invalid File Type");
      return false;
    }

    var maxSizeInBytes = 20e6; // 20MB
    if (file.size > maxSizeInBytes) {
      alert("File too large");
      return false;
    }

    return true;
  }

  return (
    <Dialog
      onClose={() => {
        setDialog((dialog) => ({ ...dialog, open: false }));
      }}
      PaperProps={{
        sx: {
          borderRadius: 3,
          p: 4,
        },
      }}
      fullWidth
      maxWidth="sm"
      open={dialog.open}
    >
      <input
        ref={inputRef}
        style={{ display: "none" }}
        type="file"
        accept=".csv"
        id="input-file-upload"
        multiple={false}
        onChange={handleChange}
      />
      <h2 style={{ margin: 0 }}>Import {dialog.resource}s</h2>
      <Typography sx={{ mt: 1 }}>
        Click{" "}
        <span
          style={{
            textDecorationLine: "underline",
            cursor: "pointer",
          }}
        >
          here
        </span>{" "}
        to download the template that you can use to create your import file.
        Please note that only files with the <b>.csv</b> extension are allowed.
        You can prepare and export your data from excel.
      </Typography>
      {false && <Divider sx={{ opacity: 0.2, mt: 2 }} />}
      <Stack
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        sx={{
          background: dragActive
            ? "rgba(68, 171, 255, 0.15)"
            : "rgba(68, 171, 255, 0.05)",
          borderRadius: 5,
          p: 4,
          alignItems: "center",
          mt: 3,
          mb: 3,
          textAlign: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <img
          onClick={onButtonClick}
          src={CsvIllustration}
          alt="csv illustration"
          style={{ height: 100, width: 100, objectFit: "contain" }}
        />

        <h3 style={{ opacity: 0.7, margin: 0 }}>
          {!fileState && !file ? (
            <>
              Select a <b>csv</b> file to upload
            </>
          ) : fileState === "uploading" ? (
            "Uploading file ..."
          ) : fileState === "error" ? (
            <>
              File upload error.{" "}
              <span
                onClick={() => {
                  uploadFile({ ...file });
                }}
                style={{ textDecorationLine: "underline", cursor: "pointer" }}
              >
                <b>Retry</b>
              </span>
            </>
          ) : (
            "Data is ready for import"
          )}
        </h3>
        <Collapse in={!file}>
          <div>
            <Typography sx={{ mt: 0.5, opacity: fileState ? 0.1 : 1 }}>
              * or drag and drop your file here
            </Typography>
            <IconButton
              onClick={onButtonClick}
              disabled={fileState}
              size="large"
            >
              <HiPlusCircle />
            </IconButton>
          </div>
        </Collapse>

        <Collapse in={file}>
          <Stack
            direction="row"
            sx={{
              borderRadius: 32,
              p: 4,
              background: "rgba(68, 171, 255, 0.1)",
              textAlign: "left",
              mt: 1,
              pt: 1.5,
              pb: 1.5,
              alignItems: "center",
            }}
          >
            {file && (
              <Stack sx={{ flex: 1, mr: 2 }}>
                <Typography>
                  {`${truncateString(
                    file?.file?.name.replace(".csv", ""),
                    14
                  )} .csv`}
                </Typography>
                <LinearProgress
                  value={myProgress.progress}
                  sx={{ borderRadius: 32, height: 4, mt: 1 }}
                  variant="determinate"
                />
              </Stack>
            )}
            <IconButton
              onClick={() => {
                inputRef.current.value = "";
                setFile(null);
                setFileState(null);
              }}
            >
              <IoClose />
            </IconButton>
          </Stack>
        </Collapse>
        <Chip
          // color="warning"
          variant="outlined"
          sx={{ position: "absolute", top: 10, left: 10 }}
          label="File Size Limit: 20MB"
        />
      </Stack>
      <Divider sx={{ opacity: 0.2, mb: 2 }} />

      <Collapse in={isLoading}>
        <LinearProgress />
      </Collapse>
      <DialogActions sx={{ pb: 2, justifyContent: "center" }}>
        <Button
          disabled={fileState !== "uploaded" || isLoading}
          startIcon={<IoIosSend />}
          variant="contained"
          disableElevation
          onClick={async () => {
            let result = await saveImport({
              resource: dialog.resource,
              document: file._id,
            });
            if (!result.error) {
              setFile(null);
              setFileState(null);
              setDialog((dialog) => ({ ...dialog, open: false }));
            }
          }}
          sx={{
            textTransform: "none",
            borderRadius: 32,
            minWidth: 100,
            height: 50,
          }}
        >
          Submit
        </Button>
        {false && (
          <Button
            disabled={fileState !== "uploaded"}
            color="info"
            variant="outlined"
            disableElevation
            onClick={dialog.action}
            sx={{
              textTransform: "none",
              borderRadius: 32,
              minWidth: 100,
              height: 50,
            }}
          >
            Preview
          </Button>
        )}
        <Button
          variant="outlined"
          disableElevation
          onClick={() => {
            setDialog((dialog) => ({ ...dialog, open: false }));
          }}
          color="warning"
          sx={{
            textTransform: "none",
            borderRadius: 32,
            minWidth: 100,
            height: 50,
          }}
        >
          {dialog.negative || "Cancel"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DataImport;
