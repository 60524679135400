/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import {
  LinearProgress,
  Chip,
  Typography,
  CircularProgress,
  Tabs,
  Tab,
  Collapse,
} from "@mui/material";
import TablePaginationComponent from "../../../../layouts/common/pagination/view";
import { setFilters } from "../../../../state/slices/pagination";
import { IconButton } from "@mui/material";
import { IoArrowForward } from "react-icons/io5";
import {
  TableRow,
  TableHeader,
  TableContainer,
} from "../../../../layouts/common/table/view";
import Stack from "@mui/material/Stack";
import ManageOrder from "../../orderDetails";
import { useDispatch } from "react-redux";
import { responsiveProps } from "../../../../utils/functions";
import {
  useGetOrdersQuery,
  useLazyGetOrderQuery,
} from "../../../../api/api-endpoints/order";
import TableOptions from "./table_options";
import { useSelector } from "react-redux";
import { constants } from "../../../../config/constants";
import { numberWithCommas } from "../../../../config/constants";
import { Refresh } from "@mui/icons-material";
// Define the resource name for this component.
const resource = "order";

const OrderAction = ({ _id, setEditOpen }) => {
  const [getOrder, state] = useLazyGetOrderQuery();

  const { isLoading, isFetching } = state;

  return (
    <IconButton
      onClick={async () => {
        try {
          let result = await getOrder(_id).unwrap();
          setEditOpen({ open: true, _id, populate: result });
        } catch (e) {}
      }}
    >
      {isLoading || isFetching ? (
        <CircularProgress size={15} />
      ) : (
        <IoArrowForward />
      )}
    </IconButton>
  );
};

/**
 * Functional component that renders a table of orders with various actions.
 */

export const StatusChip = ({ status }) => {
  let chipColor;
  let chipLabel;
  switch (status) {
    case "received":
      chipLabel = "Pending";
      chipColor = "default";
      break;
    case "readyForPickup":
      chipLabel = "Pickup Ready";
      chipColor = "info";
      break;
    case "dispatched":
      chipLabel = "Dispatched";
      chipColor = "info";
      break;
    case "delivered":
      chipLabel = "Fulfilled";
      chipColor = "primary";
      break;
    case "pickedUp":
      chipLabel = "Fulfilled";
      chipColor = "primary";
      break;
    default:
      chipColor = "default";
      chipLabel = "N/A";
  }

  return (
    <Chip
      sx={{ alignSelf: "flex-start" }}
      color={chipColor}
      label={chipLabel}
    />
  );
};
export default function Table() {
  // Retrieve pagination and filter settings from Redux state.
  const { rowsPerPage, skip_id, q, page, filters } = useSelector(
    (state) => state.pagination
  ).order;

  // Use a query hook to fetch order data based on the current pagination and filter settings.
  const {
    data: orders = [],
    isLoading,
    isFetching,
    refetch,
  } = useGetOrdersQuery({
    skip: q.trim() === "" ? skip_id : page * rowsPerPage,
    limit: rowsPerPage,
    ...filters,
    q: q.trim(),
  });

  // Maintain state for selected order items.
  const [selected, setSelected] = React.useState([]);

  // Configuration for the table.
  const tableConfig = {
    headings: [
      {
        title: "Order",
        key: "order",
        grid: {
          xs: 5,
          sm: 5,
          md: 3,
          lg: 4,
        },
      },

      {
        title: "Total Cost",
        key: "total",
        grid: {
          xs: 4,
          sm: 2,
          md: 2,
          lg: 2,
        },
      },
      {
        title: "Status",
        key: "status",
        grid: {
          xs: 0,
          sm: 0,
          md: 2,
          lg: 2,
        },
      },
      {
        title: "Order Type",
        key: "orderType",
        grid: {
          xs: 0,
          sm: 2,
          md: 2,
          lg: 2,
        },
      },

      {
        title: "Action",
        grid: {
          xs: 2,
          sm: 2,
          md: 2,
          lg: 1,
        },
      },
    ],
    actionGrid: {
      xs: 2,
      sm: 2,
      md: 2,
      lg: 1,
    },
    enableCheck: false,
    preprocessColumnData: (item, schemaItem) => {
      try {
        switch (schemaItem) {
          case "status":
            return <StatusChip status={item.status} />;

          case "total":
            return `${constants?.currency?.symbol}${numberWithCommas(
              item.total?.toFixed(2)
            )}`;

          case "orderType":
            if (item.longitude) {
              return "Delivery";
            } else {
              return "Pickup";
            }

          case "order":
            return "";

          default:
            return "Not set";
        }
      } catch (e) {
        return "Column error";
      }
    },
  };

  const [editOpen, setEditOpen] = React.useState({
    open: false,
    _id: null,
    populate: null,
  });

  const [tab, setTab] = React.useState(0);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(
      setFilters({
        target: "order",
        filters: { ...filters, summaryStatus: tab === 0 ? "open" : "closed" },
      })
    );
  }, [tab]);

  return (
    <TableContainer>
      <Collapse in={isLoading || isFetching}>
        <LinearProgress color="info" sx={{ mx: 1.5, borderRadius: 32 }} />
      </Collapse>

      <Stack alignItems="center" direction="row" spacing={1}>
        <Tabs
          onChange={(e, newValue) => {
            setTab(newValue);
          }}
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: "black",
            },
          }}
          value={tab}
        >
          <Tab
            sx={{
              textTransform: "none",
              "&.Mui-selected": {
                // Apply the styles when the tab is selected
                color: "black", // Success color for the text when active
              },
            }}
            value={0}
            label="Open Orders"
          ></Tab>
          <Tab
            color="inherit"
            value={1}
            sx={{
              textTransform: "none",
              "&.Mui-selected": {
                // Apply the styles when the tab is selected
                color: "black", // Success color for the text when active
              },
            }}
            label="Closed Orders"
          ></Tab>
        </Tabs>
        <IconButton onClick={refetch}>
          <Refresh />
        </IconButton>
      </Stack>
      {/* Render table options component */}
      {false && (
        <TableOptions
          filters={filters}
          resource={resource}
          refetch={refetch}
          isLoading={isLoading}
          isDeleting={false}
          selected={selected}
          setSelected={setSelected}
        />
      )}
      {/* Render a linear progress indicator during loading */}

      {/* Render table header */}
      <TableHeader
        enableCheck={tableConfig.enableCheck}
        headings={tableConfig.headings}
        disable={isLoading}
        allSelected={orders.length === selected.length}
        setSelected={(all) => {
          if (all) {
            setSelected(orders.map((e) => e._id));
          } else {
            setSelected([]);
          }
        }}
      />

      <Stack
        sx={{
          borderTopWidth: 0,
          borderRadius: 2,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}
      >
        {/* Render table rows */}
        {orders.map((item, index) => {
          return (
            <TableRow
              size={orders.length}
              key={item._id}
              firstColumn={() => {
                return (
                  <Stack sx={{ pl: 2 }} spacing={1} direction="row">
                    <Stack>
                      <Typography>
                        <b>{item.orderNumber}</b>
                      </Typography>
                      <Typography variant="caption">
                        {item.customerName}
                      </Typography>
                      {tab === 0 && (
                        <Stack
                          sx={{
                            display: responsiveProps("flex", "flex", "none"),
                          }}
                        >
                          <StatusChip status={item.status} />
                        </Stack>
                      )}
                    </Stack>
                  </Stack>
                );
              }}
              actionGrid={tableConfig.actionGrid}
              enableCheck={tableConfig.enableCheck}
              index={index}
              schema={tableConfig.headings.filter((x) => x.key != null)}
              disable={isLoading}
              preprocessColumnData={tableConfig.preprocessColumnData}
              setSelected={setSelected}
              selected={selected.includes(item._id)}
              item={item}
              additionalColumn={
                <OrderAction _id={item._id} setEditOpen={setEditOpen} />
              }
            />
          );
        })}
      </Stack>

      <Stack sx={{ pr: 3, mt: 1, mb: 1, display: "none" }}>
        {/* Render pagination component */}
        <TablePaginationComponent
          loading={isFetching || isLoading}
          deletions={orders.filter((order) => order._deleted)}
          filters={filters}
          refetch={refetch}
          lastItemId={orders.length > 0 ? orders[orders.length - 1]._id : ""}
          resource={resource}
          data_size={orders.length}
        />
      </Stack>
      <ManageOrder
        _id={editOpen._id}
        order={editOpen.populate}
        open={editOpen.open}
        onClose={() => {
          setEditOpen((prev) => ({ ...prev, open: false }));
        }}
      />
    </TableContainer>
  );
}
