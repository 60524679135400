import React from "react";
import Box from "@mui/material/Box";
import _ from "lodash";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Alert from "@mui/material/Alert";
import DialogContent from "@mui/material/DialogContent";
import { useDispatch } from "react-redux";
import { apiSlice } from "../../api/api.service";
import TopBar from "../app-bar/view";
import { useSelector } from "react-redux";
import { incrementNotificationCount } from "../../state/slices/notify";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import { updateDialog } from "../../state/slices/dialog";
import { updateSnack } from "../../state/slices/snack";
import Notifications from "../../components/nofifications";
import { Button, DialogActions } from "@mui/material";
import useWebSocket from "react-use-websocket";
import Menu from "./menu";
import { handleSync } from "../../utils/handleSync";
import { constants } from "../../config/constants";

const timestamp = new Date().getTime().toString();

let lastReceived = "";

export default function Root() {
  const dispatch = useDispatch();

  const snack = useSelector((state) => state.snack);

  const dialog = useSelector((state) => state.dialog);

  const handleClose = (event, reason) => {
    dispatch(updateSnack({ open: false, msg: null }));
  };

  const drawerWidth = 240;

  const user = useSelector((state) => state.user);

  const pagination = useSelector((state) => state.pagination);

  const queries = useSelector((state) => state.api.queries);

  const { lastJsonMessage } = useWebSocket(
    encodeURI(`${constants.notifier}?connection_id=${timestamp}`),
    {
      retryOnError: true,
    },
    true
  );

  React.useEffect(() => {
    if (lastJsonMessage && !_.isEqual(lastJsonMessage, lastReceived)) {
      console.log("Notification received", lastJsonMessage);
      if (!lastJsonMessage.alert) {
        handleSync({
          ...lastJsonMessage,
          pagination: pagination[lastJsonMessage.resource],
          dispatch,
          queries,
        });
      } else {
        dispatch(incrementNotificationCount());
        /**
      *    dispatch(
          apiSlice.endpoints.getTasks.initiate(undefined, {
            forceRefetch: true,
          })
        );
      */
      }
      lastReceived = lastJsonMessage;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastJsonMessage]);

  const location = useLocation();
  const [notificationsOpen, setNotificationsOpen] = React.useState(false);

  if (!user?.user) {
    return <Navigate to="login" />;
  }

  if (location.pathname === "/") {
    return <Navigate to="/orders" />;
  }

  return (
    <div
      style={{
        flexDirection: "row",
        display: "flex",
        transition: "all .8s ease",
        WebkitTransition: "all .8s ease",
        MozTransition: "all .8s ease",
        flexGrow: 1,
        zIndex: 1,
        overflow: "hidden",
        position: "relative",
      }}
    >
      <Box
        style={{}}
        sx={{
          width: drawerWidth,
          height: "100vh",
          display: { xs: "none", sm: "none", md: "flex", lg: "flex" },
        }}
      >
        <Menu />
      </Box>

      <div
        id="detail"
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <TopBar setNotificationsOpen={setNotificationsOpen} />
        <Outlet />
      </div>
      <Snackbar
        open={snack.open}
        autoHideDuration={5000}
        onClose={handleClose}
        message={snack.msg}
      />
      <Dialog
        onClose={() => {
          dispatch(updateDialog({ open: false, msg: null }));
        }}
        PaperProps={{
          sx: {
            background: "rgba(252, 233, 232)",
          },
        }}
        open={dialog.open}
      >
        <DialogTitle>Sorry, an error occurred.</DialogTitle>
        <DialogContent sx={{ pb: 0, mb: 0 }}>
          <Alert
            sx={{ width: 320, borderRadius: 2, mb: 0 }}
            variant="standard"
            severity="error"
          >
            {dialog.msg}
          </Alert>
        </DialogContent>
        <DialogActions sx={{ mt: 0, pt: 0 }}>
          <Button
            onClick={() => {
              dispatch(updateDialog({ open: false, msg: null }));
            }}
            color="warning"
            sx={{ textTransform: "none", m: 1 }}
          >
            Dismiss
          </Button>
        </DialogActions>
      </Dialog>
      <Notifications setOpen={setNotificationsOpen} open={notificationsOpen} />
    </div>
  );
}
