import Typography from "@mui/material/Typography";
import * as React from "react";
import { Divider, Collapse } from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import GeneralTab from "./tabs/general/view";
import { useParams } from "react-router-dom";
import UpdatePassword from "./tabs/password";
import { useGetUserQuery } from "../../../api/api-endpoints/user";
import { LinearProgress } from "@mui/material";
import RouterBreadcrumbs from "../../../layouts/common/breadcrumbs/view";
import { PageContainer } from "../../../layouts/common/general/components";

import { HiLockClosed, HiUser } from "react-icons/hi2";
import { Password } from "@mui/icons-material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ViewUser() {
  let { userID } = useParams();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const {
    data: user,
    isLoading,
    isSuccess,
    isFetching,
  } = useGetUserQuery(userID);

  return (
    <PageContainer>
      <RouterBreadcrumbs />
      <Divider sx={{ mt: 2, opacity: 0.25 }} />
      <Collapse in={isLoading || isFetching}>
        <LinearProgress color="inherit" sx={{ borderRadius: 32 }} />
      </Collapse>
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="campaign tabs"
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: "black",
            },
          }}
        >
          <Tab
            sx={{
              textTransform: "none",
              "&.Mui-selected": {
                // Apply the styles when the tab is selected
                color: "black", // Success color for the text when active
              },
            }}
            color="inherit"
            label={"Profile"}
            iconPosition="start"
            icon={<HiUser size={22} />}
          />
          <Tab
            iconPosition="start"
            sx={{
              "&.Mui-selected": {
                // Apply the styles when the tab is selected
                color: "black", // Success color for the text when active
              },
              textTransform: "none",
            }}
            label="Password"
            icon={<HiLockClosed size={18} />}
          />
        </Tabs>
      </Box>
      <Divider sx={{ mt: -0.2, opacity: 0.2 }} />

      {isSuccess && value === 0 && <GeneralTab user={user} />}

      {value === 1 && <UpdatePassword _id={userID} />}
    </PageContainer>
  );
}
