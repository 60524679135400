import { apiSlice } from "../api.service";

const resourcesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAlerts: builder.query({
      query: () => `/alerts`,
    }),
    getAlertCount: builder.query({
      query: () => `/alerts/count`,
    }),
  }),

  overrideExisting: false,
});

export const { useLazyGetAlertsQuery, useGetAlertCountQuery } = resourcesApi;
