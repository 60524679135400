import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import * as React from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  RadioGroup,
  Radio,
  InputLabel,
} from "@mui/material";
import { useState } from "react";
import Grid from "@mui/material/Grid";
import {
  useAddNewProductMutation,
  useEditProductMutation,
} from "../../api/api-endpoints/product";
import { NumericFormatCustom } from "../../layouts/common/inputs/view";
import { FileUploader } from "../../layouts/common/general/components";
import { useSelector, useDispatch } from "react-redux";

import TextField from "@mui/material/TextField";
import { resetUploadProgress } from "../../state/slices/progress";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseTwoTone from "@mui/icons-material/CloseTwoTone";
import Alert from "@mui/material/Alert";
import { Divider, InputAdornment } from "@mui/material";

import { HiCheck, HiXMark } from "react-icons/hi2";
import { constants } from "../../config/constants";

const TitleDescription = ({ title, description }) => (
  <>
    <Typography>
      <b>{title}</b>
    </Typography>
    <Typography variant="caption">{description}</Typography>
  </>
);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initProduct = {
  name: "",
  translation: "",
  defaultPrice: "",
  productType: "unprocessed",
  unitType: "kg",
};

export default function AddOrUpdateProduct({
  open,
  onClose,
  populate = null,
  _id = null,
}) {
  const dispatch = useDispatch();
  const [product, setProduct] = useState(initProduct);

  React.useEffect(() => {
    populate && setProduct({ ...populate, location: null });
    populate &&
      setFiles([
        ...populate.gallery.map((item) => ({
          _id: item,
          key: item,
          url: `${constants.apiUrl}/documents/${item}`,
        })),
      ]);
  }, [populate, setProduct]);

  const [files, setFiles] = React.useState([]);

  React.useEffect(() => {
    dispatch(resetUploadProgress([]));
  }, [dispatch]);

  const [addProduct, { isLoading: isSaving }] = useAddNewProductMutation();

  const [editProduct, { isLoading: isUpdating }] = useEditProductMutation();

  const uploadProgress = useSelector(
    (state) => state.uploadProgress[populate ? "product_edit" : "product_add"]
  );

  const uploadCount = uploadProgress?.filter(
    (a) => a.progress !== 100 && !a.status
  ).length;

  let processedFiles = files.map((e) => e._id)?.filter((x) => x != null);

  const saveProduct = async () => {
    try {
      let result = populate
        ? await editProduct({
            _id,
            product: {
              ...product,
              gallery: processedFiles,
            },
          })
        : await addProduct({
            ...product,
            gallery: processedFiles,
          });
      if (result.error) {
        if (result.error.data) {
          throw new Error(result.error.data.error);
        } else {
          throw new Error("Something went wrong...");
        }
      }
      if (!populate) {
        setProduct(initProduct);
        setFiles([]);
        dispatch(resetUploadProgress([]));
      }
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 4000);
    } catch (e) {
      console.log(e.message);
    }
  };

  const [showAlert, setShowAlert] = React.useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleBlur = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      keepMounted={false}
      onClose={onClose}
      open={open}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          borderRadius: 3,
        },
      }}
    >
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">
            <b>{populate ? "Update" : "Add a"} product</b>
          </Typography>
          <IconButton onClick={onClose} disabled={isSaving || isUpdating}>
            <HiXMark />
          </IconButton>
        </Stack>
      </DialogTitle>

      <Divider sx={{ mb: 1, opacity: 0.35 }} />

      <DialogContent>
        <Stack
          sx={{
            flex: 1,
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                background: "rgba(0, 0, 0, 0.02)",
                borderRadius: 2,
                borderColor: "white",
              },
            },
          }}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              saveProduct();
            }}
          >
            <Grid sx={{}} container spacing={3}>
              <Grid item xs={12} sm={12} md={4}>
                <TitleDescription
                  description="Product name and default price"
                  title="Basic Details"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={8}>
                <Stack spacing={2}>
                  <TextField
                    required
                    fullWidth
                    onBlur={handleBlur}
                    name="name"
                    label="Product name (ENG)"
                    value={product.name}
                    onChange={handleChange}
                  />

                  <TextField
                    required
                    fullWidth
                    onBlur={handleBlur}
                    name="translation"
                    label="Product name (PT)"
                    value={product.translation}
                    onChange={handleChange}
                  />
                  <Stack sx={{ mt: 3 }}>
                    <Typography variant="body2">Product Type</Typography>

                    <RadioGroup
                      onChange={(e, newValue) => {
                        setProduct((prev) => ({
                          ...prev,
                          productType: newValue,
                        }));
                      }}
                      value={product.productType}
                    >
                      <Stack spacing={1} direction="row">
                        <InputLabel sx={{}}>
                          <Stack
                            sx={{ cursor: "pointer" }}
                            alignItems="center"
                            spacing={0.5}
                            direction="row"
                          >
                            <Radio sx={{ m: 0 }} value={"unprocessed"} />
                            <Typography variant="caption">
                              Unprocessed
                            </Typography>
                          </Stack>
                        </InputLabel>
                        <InputLabel sx={{}}>
                          <Stack
                            sx={{ cursor: "pointer" }}
                            alignItems="center"
                            spacing={0.5}
                            direction="row"
                          >
                            <Radio value={"processed"} />
                            <Typography variant="caption">Processed</Typography>
                          </Stack>
                        </InputLabel>
                      </Stack>
                    </RadioGroup>
                  </Stack>
                  <Stack sx={{ mt: 3 }}>
                    <Typography variant="body2">Unit Type</Typography>

                    <RadioGroup
                      onChange={(e, newValue) => {
                        setProduct((prev) => ({
                          ...prev,
                          unitType: newValue,
                        }));
                      }}
                      value={product.unitType}
                    >
                      <Stack spacing={1} direction="row">
                        <InputLabel sx={{}}>
                          <Stack
                            sx={{ cursor: "pointer" }}
                            alignItems="center"
                            spacing={0.5}
                            direction="row"
                          >
                            <Radio sx={{ m: 0 }} value={"kg"} />
                            <Typography variant="caption">kg</Typography>
                          </Stack>
                        </InputLabel>
                        <InputLabel sx={{}}>
                          <Stack
                            sx={{ cursor: "pointer" }}
                            alignItems="center"
                            spacing={0.5}
                            direction="row"
                          >
                            <Radio value={"each"} />
                            <Typography variant="caption">each</Typography>
                          </Stack>
                        </InputLabel>
                      </Stack>
                    </RadioGroup>
                  </Stack>
                  <Stack sx={{ pt: 1 }}>
                    <TextField
                      onBlur={handleBlur}
                      required
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {constants.currency?.symbol}
                          </InputAdornment>
                        ),
                        inputComponent: NumericFormatCustom,
                      }}
                      placeholder="0.00"
                      name="defaultPrice"
                      label="Default price"
                      value={product.defaultPrice}
                      onChange={handleChange}
                    />
                  </Stack>
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={3} sx={{ mt: 3 }}>
              <Grid item xs={12} sm={12} md={4}>
                <TitleDescription
                  description="Attach one or more pictures of the product"
                  title="Product pictures"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={8}>
                <FileUploader
                  accept="image/jpeg, image/png, image/jpg"
                  resource="product_add"
                  maxFiles={3}
                  label="Drag & drop your image here or click to select"
                  files={files}
                  setFiles={setFiles}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12}>
                <Collapse in={showAlert}>
                  <Alert
                    sx={{ mt: 3 }}
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setShowAlert(false);
                        }}
                      >
                        <CloseTwoTone fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    {populate
                      ? "Changes saved successfully!"
                      : "New product added successfully!"}
                  </Alert>
                </Collapse>
              </Grid>
            </Grid>

            <Stack sx={{ mt: 3 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={4}></Grid>
                <Grid item xs={12} sm={12} md={8}>
                  <Stack spacing={2} justifyContent="flex-end" direction="row">
                    <Button
                      type="submit"
                      startIcon={<HiCheck />}
                      endIcon={
                        isSaving || isUpdating ? (
                          <CircularProgress size={14} />
                        ) : null
                      }
                      disabled={
                        isSaving ||
                        isUpdating ||
                        uploadCount > 0 ||
                        processedFiles.length === 0
                      }
                      disableElevation
                      sx={{
                        mr: 1,
                        ml: 1,
                        height: 50,
                        borderRadius: 32,
                        textTransform: "none",
                      }}
                    >
                      {uploadCount > 0
                        ? "Uploading images. Please wait ..."
                        : populate
                        ? "Save Changes"
                        : "Save Product"}
                    </Button>
                    <Button
                      type="reset"
                      color="warning"
                      onClick={onClose}
                      startIcon={<HiXMark />}
                      disabled={isSaving || uploadCount > 0}
                      disableElevation
                      sx={{
                        mr: 1,
                        ml: 1,
                        height: 50,
                        borderRadius: 32,
                        textTransform: "none",
                      }}
                    >
                      Close
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </form>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
