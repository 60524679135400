import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import { FcGoogle } from "react-icons/fc";
import { FaFacebook } from "react-icons/fa";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import { EmailRounded, Visibility, VisibilityOff } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";

import LinearProgress from "@mui/material/LinearProgress";
import { Chip, Collapse } from "@mui/material";

const FormContainer = ({ profile, setProfile, loading, handJoin, error }) => {
  const styles = {
    formContainer: {
      backgroundColor: "white",
      "& fieldset": {
        borderRadius: 2,
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "rgba(0, 0, 0, 0.03)",
        },
        "&:hover fieldset": {
          borderColor: "black",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#1A9134",
        },
      },
      flex: 2,
      justifyContent: "center",
      alignItems: "center",
    },
  };

  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();
  return (
    <Stack sx={styles.formContainer}>
      <Stack sx={{ maxWidth: "400px", width: "100%" }}>
        <Typography variant="h4">
          <b>
            Complete account
            <span style={{ color: "#DDC33E" }}> setup </span>
          </b>
        </Typography>
        <Typography sx={{ mt: 1, fontSize: 16 }}>
          Hello, and welcome to Ceaxa. To get started, let's create a password
          for your account.
        </Typography>

        {loading && <LinearProgress sx={{ mt: 2 }} />}

        <Collapse in={error}>
          <Alert sx={{ mt: 2 }} severity="error">
            {error}
          </Alert>
        </Collapse>

        <TextField
          sx={{ mt: 3 }}
          type={passwordVisible ? "text" : "password"}
          value={profile.password}
          onChange={(e) => {
            setProfile((profile) => ({
              ...profile,
              password: e.target.value,
            }));
          }}
          helperText="Minimum length: 7"
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <IconButton
                  onClick={() => {
                    setPasswordVisible((passwordVisible) => !passwordVisible);
                  }}
                  size="small"
                >
                  {passwordVisible ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          label="New Password"
          size="medium"
          fullWidth
        ></TextField>

        <TextField
          sx={{ mt: 4 }}
          type={passwordVisible ? "text" : "password"}
          value={profile.confirmationPassword}
          onChange={(e) => {
            setProfile((profile) => ({
              ...profile,
              confirmationPassword: e.target.value,
            }));
          }}
          label="Confirm Password"
          size="medium"
          fullWidth
        ></TextField>

        <Typography sx={{ mt: 1 }}>
          Already have an account?
          <Button
            onClick={() => {
              navigate("../signin");
            }}
            sx={{ textTransform: "none", ml: 0.5, borderRadius: 32 }}
          >
            <span style={{ color: "#009834" }}>Sign in</span>
          </Button>
        </Typography>

        <Collapse
          in={
            profile.password.length >= 7 &&
            profile.confirmationPassword.length > 0 &&
            profile.password !== profile.confirmationPassword
          }
        >
          <Alert sx={{ borderRadius: 2, p: 1, mt: 1, mb: 1 }} severity="error">
            Your passwords do not match
          </Alert>
        </Collapse>

        <Collapse
          in={profile.password.length >= 1 && profile.password.length < 7}
        >
          <Alert
            sx={{ borderRadius: 2, p: 1, mt: 1, mb: 1 }}
            severity="warning"
          >
            Your password should be at least 7 characters long
          </Alert>
        </Collapse>

        <Button
          disabled={
            (profile.password.length >= 7 &&
              profile.confirmationPassword.length > 0 &&
              profile.password !== profile.confirmationPassword) ||
            loading ||
            (profile.password.length >= 1 && profile.password.length < 7)
          }
          onClick={() => {
            handJoin();
          }}
          sx={{ mt: 2, height: 55, borderRadius: 2, textTransform: "none" }}
          disableElevation
          variant="contained"
          size="large"
        >
          Create Account
        </Button>
      </Stack>
    </Stack>
  );
};
export default FormContainer;
