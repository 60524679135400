import { useSelector } from "react-redux";

const AuthorizeAction = (permission, type = "button") => {
  let user = useSelector((state) => state.user.user);
  let permissions = user?.role?.permissions;

  if (type === "button") {
    // if index is negative, function will return true, meaning button is disabled
    if (permissions?.length === 0) return false;
    let index = (permissions || []).findIndex((x) => x === permission);

    return index === -1;
  }
  return true;
};

const AllowPageAccess = (accessPermissions) => {
  return true;
  let user = useSelector((state) => state.user.user);
  let permissions = user?.role?.permissions;

  // zero permissions means the account is a super admin
  if (permissions.length === 0) return true;

  let allow = false;

  accessPermissions.forEach((permission) => {
    let index = (permissions || []).findIndex((x) => x === permission);
    if (index !== -1) {
      allow = true;
    }
  });

  return allow;
};

export { AuthorizeAction, AllowPageAccess };
