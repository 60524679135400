import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import userReducer from "../slices/user";
import permissionsReducer from "../slices/permissions";
import snackReducer from "../slices/snack";
import dialogReducer from "../slices/dialog";
import notifyReducer from "../slices/notify";
import progressReducer from "../slices/progress";
import paginationReducer from "../slices/pagination";
import { apiSlice } from "../../api/api.service";
import { combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    "rows",
    "pagination",
    "dialog",
    "snack",
    "uploadProgress",
    "api",
    "notificationCount",
  ],
};

const rootReducer = combineReducers({
  user: userReducer,
  snack: snackReducer,
  uploadProgress: progressReducer,
  permissions: permissionsReducer,
  pagination: paginationReducer,
  dialog: dialogReducer,
  notificationCount: notifyReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});

export const persistor = persistStore(store);

setupListeners(store.dispatch);
