import React, { useState } from "react";
import { useMediaQuery, Tabs, Tab, Box } from "@mui/material";

import { PageContainer } from "../../layouts/common/general/components";
import { motion } from "framer-motion";
import PlatformSettings from "./platform";
import CityManagement from "./cities";
import { useLocation } from "react-router-dom";
import { HiMapPin, HiTruck } from "react-icons/hi2";
import { useSelector } from "react-redux";

const fadeIn = {
  hidden: { opacity: 0, y: 50 }, // starts 50 pixels below the original position
  visible: { opacity: 1, y: 0 }, // animate back to the original position
  exit: { opacity: 0, y: -50 }, // animate 50 pixels above the original position
};

const transition = { duration: 0.5 };

function Settings() {
  // State to handle current active tab
  const [value, setValue] = useState(0);

  const location = useLocation();

  React.useEffect(() => {
    // Function to parse query parameters
    const searchParams = new URLSearchParams(location.search);
    const target = searchParams.get("target");

    // Check if 'target' query parameter is 'subscription'
    if (target === "subscription") {
      setValue(1);
    }
  }, [location.search]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <PageContainer>
      <Box>
        <Tabs
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: "black",
            },
          }}
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="inherit"
        >
          <Tab
            sx={{ textTransform: "none" }}
            iconPosition="start"
            icon={<HiMapPin size={20} />}
            label="Cities"
          />
          <Tab
            sx={{ textTransform: "none", display: "none" }}
            iconPosition="start"
            icon={<HiTruck size={20} />}
            label="Delivery"
          />
        </Tabs>
      </Box>
      <motion.div
        initial="hidden"
        animate="visible"
        exit="exit"
        mode="wait"
        key={value}
        variants={fadeIn}
        transition={transition}
      >
        {value === 0 && <CityManagement isMobile={isMobile} />}
        {value === 1 && <PlatformSettings isMobile={isMobile} />}
      </motion.div>
    </PageContainer>
  );
}

export default Settings;
