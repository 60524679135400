/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { styled } from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { setUser } from "../../state/slices/user";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

const CustomListButton = styled((props) => <ListItemButton {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    "&.Mui-selected": {
      color: "#139E43",
      backgroundColor: "#EAF7EE",
    },

    "&.Mui-focusVisible": {
      backgroundColor: "#144C2B",
    },
  })
);

export default function MenuItem({
  data,

  value,
  defaultIconColor,
}) {
  const dispatch = useDispatch();
  const selected =
    data.id === "" ? value.length === 0 : value.includes(data.id);

  return data.id === "logout" ? (
    <ListItem
      onClick={() => {
        dispatch(setUser({ user: null }));
      }}
      sx={{ mt: 0.75 }}
      key={data.id}
      disablePadding
    >
      <CustomListButton
        selected={selected}
        sx={{ mr: 1, ml: 1, borderRadius: 2 }}
      >
        <ListItemIcon
          sx={{ color: selected ? "#139E43" : defaultIconColor || "white" }}
        >
          {data.icon}
        </ListItemIcon>
        <ListItemText primary={data.title} />
      </CustomListButton>
    </ListItem>
  ) : (
    <Link to={data.id}>
      <ListItem sx={{ mt: 0.75 }} key={data.id} disablePadding>
        <CustomListButton
          selected={selected}
          sx={{ mr: 1, ml: 1, borderRadius: 2 }}
        >
          <ListItemIcon
            sx={{ color: selected ? "#139E43" : defaultIconColor || "white" }}
          >
            {data.icon}
          </ListItemIcon>
          <ListItemText primary={data.title} />
        </CustomListButton>
      </ListItem>
    </Link>
  );
}
