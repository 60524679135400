import { createSlice } from "@reduxjs/toolkit";

const paginationSlice = createSlice({
  name: "pagination",
  initialState: {
    product: {
      rowsPerPage: 50,
      page: 0,
      previous_skip_id: [],
      skip_id: "",
      q: "",
      filters: {},
    },
    order: {
      rowsPerPage: 50,
      page: 0,
      previous_skip_id: [],
      skip_id: "",
      q: "",
      filters: { summaryStatus: "open" },
    },
    trip: {
      rowsPerPage: 50,
      page: 0,
      previous_skip_id: [],
      skip_id: "",
      q: "",
      filters: {
        accessType: "self",
      },
    },
    managerialLine: {},

    butchery: {
      rowsPerPage: 50,
      page: 0,
      skip_id: "",
      previous_skip_id: [],
      q: "",
      filters: {},
    },

    user: {
      rowsPerPage: 50,
      page: 0,
      skip_id: "",
      previous_skip_id: [],
      q: "",
      filters: {},
    },
    driver: {
      rowsPerPage: 50,
      page: 0,
      skip_id: "",
      previous_skip_id: [],
      q: "",
      filters: {},
    },
  },
  reducers: {
    setPage(state, action) {
      state[action.payload.target].page = action.payload.page;
    },
    setRowsPerPage(state, action) {
      state[action.payload.target].rowsPerPage = action.payload.rowsPerPage;
    },
    setFilters(state, action) {
      state[action.payload.target] = {
        ...state[action.payload.target],
        skip_id: "",
        previous_skip_id: [],
        page: 0,
        filters: action.payload.filters,
      };
    },
    setQ(state, action) {
      let target = action.payload.target;
      let q = action.payload.q;

      state[target] = {
        ...state[action.payload.target],
        skip_id: "",
        previous_skip_id: [],
        page: 0,
        q,
      };
    },
    setSkipId(state, action) {
      state[action.payload.target].skip_id = action.payload.skip_id;
      state[action.payload.target].previous_skip_id =
        action.payload.previous_skip_id || [];
    },

    setNeedsReset(state, action) {
      state[action.payload.target].needsReset = action.payload.needsReset;
    },
  },
});

export const {
  setPage,
  setRowsPerPage,
  setSkipId,
  setQ,
  setFilters,
  setNeedsReset,
} = paginationSlice.actions;
export default paginationSlice.reducer;
