import * as React from "react";
import {
  Stack,
  Typography,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import { useLazyGetReportQuery } from "../../api/api-endpoints/report";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import RouterBreadcrumbs from "../../layouts/common/breadcrumbs/view";
import {
  PageTitle,
  PageContainer,
} from "../../layouts/common/general/components";
import ReportComponent from "./reportViewer";
import { responsiveProps } from "../../utils/functions";

export default function Reporting() {
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  const [makeReport, { isLoading: makingReport, data: report, isFetching }] =
    useLazyGetReportQuery();

  const generateReport = async () => {
    try {
      let result = await makeReport({
        startDate,
        endDate,
      }).unwrap();
      console.log(result);
    } catch (e) {
      console.log("e", e.message);
    }
  };

  const handleBlur = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <PageContainer>
      <Stack
        direction="row"
        style={{
          alignItems: "baseline",
          justifyContent: "space-between",
          mt: 4,
        }}
      >
        <Stack>
          <Stack>
            <PageTitle title="Reporting" />
          </Stack>
          <RouterBreadcrumbs />
        </Stack>
      </Stack>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack
          sx={{
            borderStyle: "dashed",
            borderColor: "rgb(0, 0, 0, 0.1)",
            p: responsiveProps(2, 2, 2, 4, 4, 4),
            borderRadius: 3,
            mt: 2,
          }}
        >
          <Stack sx={{}}>
            <Typography variant="body1">Create a new report</Typography>
            <Typography sx={{}} variant="caption">
              Set the start date and end date of your report.
            </Typography>
          </Stack>

          <Stack
            sx={{
              maxWidth: 450,
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  background: "rgba(0, 0, 0, 0.02)",
                  borderRadius: 2,
                  borderColor: "white",
                },
              },
              mt: 2,
            }}
            direction="row"
            spacing={2}
            alignItems="center"
          >
            <DatePicker
              fullWidth
              label="Start date"
              onChange={(newValue) => {
                setStartDate(newValue);
              }}
              value={startDate}
              renderInput={(props) => (
                <TextField
                  required={true}
                  onBlur={handleBlur}
                  fullWidth
                  {...props}
                  error={false}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
              )}
            />
            <DatePicker
              fullWidth
              label="End date"
              onChange={(newValue) => {
                setEndDate(newValue);
              }}
              value={endDate}
              renderInput={(props) => (
                <TextField
                  required={true}
                  onBlur={handleBlur}
                  fullWidth
                  {...props}
                  error={false}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
              )}
            />
          </Stack>

          <Button
            onClick={generateReport}
            startIcon={makingReport ? <CircularProgress size={14} /> : null}
            disabled={!startDate || !endDate || makingReport}
            sx={{
              borderRadius: 32,
              textTransform: "none",
              alignSelf: "flex-start",
              mt: 2,
            }}
          >
            Generate Report
          </Button>
        </Stack>
      </LocalizationProvider>
      {report && startDate && endDate && <ReportComponent report={report} />}
    </PageContainer>
  );
}
