import * as React from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";

import Typography from "@mui/material/Typography";
import MenuHeader from "./menu.header";
import MenuItem from "./menu.item";
import { useSelector } from "react-redux";

import { appMenu, managementMenu } from "../../config/main_menu";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/system";

export default function TemporaryDrawer({ state, setState }) {
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const user = useSelector((state) => state.user);

  const [value, setValue] = React.useState([]);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const location = useLocation();

  React.useEffect(() => {
    handleChange(location.pathname.split("/").filter((x) => x !== ""));
    setState({ left: false });
  }, [location, setState]);

  return (
    <React.Fragment>
      <Drawer
        sx={{
          "& .MuiDrawer-paper": {
            p: 1,
          },
        }}
        anchor="left"
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
      >
        <MenuHeader colors={{ bg: "rgb(0, 0,0,0.1)", txt: "black" }} />
        <Box sx={{ minWidth: "55vw", mt: 2, pb: 3 }}>
          {appMenu(user?.user?.role).length > 0 && (
            <>
              <Typography variant="caption" sx={{ ml: 2, mt: 3 }}>
                <b>Platform</b>
              </Typography>
              <List>
                {appMenu(user?.user?.role).map((data, index) => {
                  return (
                    <MenuItem
                      defaultIconColor="black"
                      data={data}
                      value={value}
                    />
                  );
                })}
              </List>
            </>
          )}

          {managementMenu(user?.user?.role).length > 0 && (
            <>
              <Typography variant="caption" sx={{ ml: 2, mt: 3 }}>
                <b>Manage</b>
              </Typography>
              <List>
                {managementMenu(user?.user?.role).map((data, index) => {
                  return (
                    <MenuItem
                      defaultIconColor="black"
                      data={data}
                      value={value}
                    />
                  );
                })}
              </List>
            </>
          )}
        </Box>
      </Drawer>
    </React.Fragment>
  );
}
