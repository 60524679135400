import {
  FaFileImage,
  FaFilePdf,
  FaFileWord,
  FaFileExcel,
  FaFile,
} from "react-icons/fa";

function truncateUserName(str) {
  return str.length > 16 ? str.substring(0, 13) + "..." : str;
}

export function capitalizeWords(str) {
  return str
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
}

export const responsiveProps = (xs, xsm, sm, md, lg, xl) => ({
  xs,
  xsm: xsm || xs,
  sm: sm || xsm || xs,
  md: md || sm || xsm || xs,
  lg: lg || md || sm || xsm || xs,
  xl: xl || lg || md || sm || xsm || xs,
});

function truncateMessage(str) {
  return str.length > 45 ? str.substring(0, 40) + "..." : str;
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function removeEmptyValues(obj) {
  const filtered = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      // Check if the value is an object and recurse into it
      if (typeof value === "object" && !Array.isArray(value)) {
        const nestedFiltered = removeEmptyValues(value);

        // Only add the property if it's not an empty object
        if (Object.keys(nestedFiltered).length > 0) {
          filtered[key] = nestedFiltered;
        }
      } else if (value !== "") {
        // Add the property if it's not an empty string
        filtered[key] = value;
      }
    }
  }

  return filtered;
}

function formatAddress(addressObj) {
  const { address, city, zip, province, country } = addressObj;
  const addressLines = [address, city, province, zip, country].filter(Boolean);

  return addressLines.join(", ");
}

function determineFileTypeIcon(fileName) {
  const ext = fileName.split(".").pop().toLowerCase();
  switch (ext) {
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
    case "bmp":
    case "tiff":
      return <FaFileImage />;
    case "pdf":
      return <FaFilePdf />;
    case "doc":
    case "docx":
      return <FaFileWord />;
    case "xls":
    case "xlsx":
      return <FaFileExcel />;
    default:
      return <FaFile />;
  }
}

function getFileExtension(file) {
  return file.name.split(".").pop().toLowerCase();
}

function truncateFileName(fileName, maxLength = 25) {
  if (fileName.length <= maxLength) return fileName;

  const extension = fileName.split(".").pop();
  const nameWithoutExtension = fileName.slice(0, -(extension.length + 1)); // +1 for the dot
  const truncatedName = nameWithoutExtension.slice(
    0,
    maxLength - extension.length - 1
  ); // -1 for the dot

  return `${truncatedName}...${extension}`;
}

export {
  truncateMessage,
  truncateUserName,
  numberWithCommas,
  removeEmptyValues,
  formatAddress,
  determineFileTypeIcon,
  getFileExtension,
  truncateFileName,
};
