import React, { useEffect, useState } from "react";

import Typography from "@mui/material/Typography";
import MenuHeader from "./menu.header";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import MenuItem from "./menu.item";
import { useSelector } from "react-redux";
import { appMenu, platformMenu, managementMenu } from "../../config/main_menu";
import { useLocation } from "react-router-dom";

export default function Menu() {
  const drawerWidth = 240;

  const [value, setValue] = useState([]);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const location = useLocation();

  useEffect(() => {
    handleChange(location.pathname.split("/").filter((x) => x !== ""));
  }, [location]);

  const user = useSelector((state) => state.user);

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          //  m: 1,
          boxShadow: "5px 5px 24px 1px rgba(20, 100, 0, 0.09)",
          border: "none",
          //   borderRadius: 3,
          background: "black",
          color: "white",
          p: 1,
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <MenuHeader />

      {appMenu(user?.user?.role).length > 0 && (
        <>
          <Typography variant="caption" sx={{ ml: 2, mt: 3 }}>
            <b>Platform</b>
          </Typography>
          <List>
            {appMenu(user?.user?.role).map((data, index) => {
              return (
                <MenuItem
                  data={data}
                  handleChange={handleChange}
                  value={value}
                />
              );
            })}
          </List>
        </>
      )}

      {platformMenu(user?.user?.role).length > 0 && (
        <>
          <Typography variant="caption" sx={{ ml: 2, mt: 3 }}>
            <b>Platform</b>
          </Typography>
          <List>
            {platformMenu(user?.user?.role).map((data, index) => {
              return (
                <MenuItem
                  data={data}
                  handleChange={handleChange}
                  value={value}
                />
              );
            })}
          </List>
        </>
      )}

      {managementMenu(user?.user?.role).length > 0 && (
        <>
          <Typography variant="caption" sx={{ ml: 2, mt: 3 }}>
            <b>Manage</b>
          </Typography>
          <List>
            {managementMenu(user?.user?.role).map((data, index) => {
              return (
                <MenuItem
                  data={data}
                  handleChange={handleChange}
                  value={value}
                />
              );
            })}
          </List>
        </>
      )}
    </Drawer>
  );
}
