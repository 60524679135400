import { apiSlice } from "../api.service";
import { setUploadProgress } from "../../state/slices/progress";

import { constants } from "../../config/constants";
import axios from "axios";

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    upload: builder.mutation({
      queryFn: async (
        { data, key, controller = null, resource = "trip_add" },
        api
      ) => {
        try {
          const result = await axios.post(
            constants.apiUrl + "/documents",
            data,
            {
              onUploadProgress: (upload) => {
                let uploadloadProgress = Math.round(
                  100 * (upload.loaded / upload.total)
                );
                api.dispatch(
                  setUploadProgress({
                    key,
                    progress: uploadloadProgress,
                    resource,
                  })
                );
              },
              headers: {},
              ...(controller ? { signal: controller.signal } : {}),
            }
          );

          return { data: result.data };
        } catch (axiosError) {
          let err = axiosError;

          api.dispatch(
            setUploadProgress({ key, progress: 0, status: "failed", resource })
          );
          return {
            error: {
              status: err.response?.status,
              data: err.response?.data || err.message,
            },
          };
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const { useUploadMutation } = extendedApi;
