/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import TablePagination from "@mui/material/TablePagination";
import { useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import { useDispatch } from "react-redux";
import {
  setPage,
  setRowsPerPage,
  setSkipId,
} from "../../../state/slices/pagination";
import { apiSlice } from "../../../api/api.service";
import { useGetResourceCountQuery } from "../../../api/api-endpoints/user";

export default function TablePaginationComponent({
  data_size,
  refetch,
  resource,
  loading,
  lastItemId,
  deletions = [],
  src_page,
}) {
  const dispatch = useDispatch();

  const { rowsPerPage, page, skip_id, q, previous_skip_id, filters } =
    useSelector((state) => state.pagination)[resource];

  const loadPage = (toPage) => {
    if (toPage === 0) {
      dispatch(
        setSkipId({ target: resource, skip_id: "", previous_skip_id: [] })
      );
      return;
    }
    if (toPage > page && skip_id !== lastItemId) {
      dispatch(
        setSkipId({
          target: resource,
          skip_id: lastItemId,
          previous_skip_id: [...previous_skip_id, skip_id],
        })
      );
      return;
    }
    if (toPage < page) {
      let temp = [...previous_skip_id];
      temp.pop();
      dispatch(
        setSkipId({
          target: resource,
          skip_id: previous_skip_id[previous_skip_id.length - 1],
          previous_skip_id: temp,
        })
      );
    }
    refetch();
    return;
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(
      setRowsPerPage({
        target: resource,
        rowsPerPage: parseInt(event.target.value),
      })
    );
    dispatch(setPage({ target: resource, page: 0 }));
    loadPage(0);
  };

  const handleChangePage = (event, newPage) => {
    dispatch(setPage({ target: resource, page: newPage }));
    loadPage(newPage);
  };

  const {
    data = null,
    refetch: reloadCount,
    isLoading: loadingCount,
    isFetching,
  } = useGetResourceCountQuery({
    resource,
  });

  const isDisabled = loadingCount || loading || isFetching;

  React.useEffect(() => {
    /**
     * This caters for item deletions, so that pagination does
     * not exceed the number of items available. Page number
     * starts at zero (0)
     */
    if (data) {
      if (data.count <= page * rowsPerPage && page !== 0) {
        dispatch(setPage({ target: resource, page: page - 1 }));
        loadPage(page - 1);
      }
    }
  }, [data, data_size]);

  React.useEffect(() => {
    if (deletions.length > 0) {
      dispatch(
        apiSlice.endpoints.refillList.initiate({
          endpoint: `${resource}s`,
          resource,
          data: deletions.map((x) => x._id),
        })
      );
    }
  }, [deletions]);

  React.useEffect(() => {
    reloadCount();
  }, [data_size, resource, loading, filters, q]);

  return (
    <Stack
      direction="row"
      sx={{ alignContent: "flex-start", justifyContent: "space-between" }}
    >
      <TablePagination
        component="div"
        count={data && data.resource === resource ? data.count : 0}
        page={page}
        onPageChange={handleChangePage}
        backIconButtonProps={
          isDisabled
            ? {
                disabled: isDisabled,
              }
            : undefined
        }
        nextIconButtonProps={
          isDisabled
            ? {
                disabled: isDisabled,
              }
            : undefined
        }
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <div />
    </Stack>
  );
}
