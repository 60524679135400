import * as React from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Grow from "@mui/material/Grow";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ConfirmationDialog from "../../../layouts/common/dialogs/confirm";
import RouterBreadcrumbs from "../../../layouts/common/breadcrumbs/view";
import { useParams } from "react-router-dom";
import {
  useGetButcheryQuery,
  useDeleteButcheryMutation,
} from "../../../api/api-endpoints/butchery";
import { useNavigate } from "react-router-dom";
import {
  Stack,
  Divider,
  Grid,
  Chip,
  Button,
  CircularProgress,
} from "@mui/material";
import ProductAvailability from "./productAvailability";
import { numberWithCommas } from "../../../config/constants";
import { PageContainer } from "../../../layouts/common/general/components";
import AddOrUpdateButchery from "../addOrUpdate";
import { HiBuildingStorefront, HiPencil, HiShoppingBag } from "react-icons/hi2";
import { constants } from "../../../config/constants";
import { IoMdTrash } from "react-icons/io";

const currency = constants.currency;

export default function ManageButchery() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { id } = useParams();

  const {
    data: butchery = null,
    isLoading,
    isFetching,
  } = useGetButcheryQuery(id);

  const [editOpen, setEditOpen] = React.useState(false);

  const [confirmationDialog, setConfirmationDialog] = React.useState({
    open: false,
    msg: "",
    action: () => {},
  });

  const [deleteButchery, { isLoading: isDeleting }] =
    useDeleteButcheryMutation();

  const navigate = useNavigate();

  return (
    <>
      <PageContainer>
        <RouterBreadcrumbs />
        <Divider sx={{ mt: 2, opacity: 0.25 }} />
        {(isLoading || isFetching) && (
          <Grow in={isLoading || isFetching || isDeleting}>
            <LinearProgress sx={{ borderRadius: 32 }} color="inherit" />
          </Grow>
        )}
        <Box sx={{ width: "100%" }}>
          <Tabs
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "black",
              },
            }}
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              sx={{
                textTransform: "none",
                "&.Mui-selected": {
                  // Apply the styles when the tab is selected
                  color: "black", // Success color for the text when active
                },
              }}
              color="inherit"
              iconPosition="start"
              label="Butchery Profile"
              icon={<HiBuildingStorefront />}
            />
            <Tab
              sx={{
                textTransform: "none",
                "&.Mui-selected": {
                  // Apply the styles when the tab is selected
                  color: "black", // Success color for the text when active
                },
              }}
              color="inherit"
              iconPosition="start"
              label="Catalogue"
              icon={<HiShoppingBag />}
            />
          </Tabs>
        </Box>

        {butchery && (
          <>
            {value === 0 && (
              <Stack sx={{}}>
                <Stack
                  spacing={3}
                  sx={{
                    p: 2,
                    pt: 4,
                  }}
                >
                  {[
                    { label: "Butchery Name", value: butchery.name },
                    { label: "Address", value: butchery.address },
                    {
                      label: "Latitude",
                      value: butchery.location?.latitude || "Not set",
                    },
                    {
                      label: "Longitude",
                      value: butchery.location?.longitude || "Not set",
                    },

                    {
                      label: "City",
                      value: butchery.city?.name,
                    },
                    { label: "Contact Person", value: butchery.contact },
                    {
                      label: "Phone Number",
                      value: butchery.phone,
                    },
                    {
                      label: "Operating Hours",
                      component: butchery.operatingHours ? (
                        <Grid spacing={2} container>
                          {Object.keys(butchery.operatingHours).map((key) => {
                            return (
                              <Grid item xs={6} xsm={6} sm={2} md={4} lg={2}>
                                <Stack sx={{ mt: 0.5 }}>
                                  <Typography variant="body2">
                                    {key.toUpperCase()}
                                  </Typography>
                                  <Typography variant="caption">
                                    {butchery.operatingHours[key]?.open}-{" "}
                                    {butchery.operatingHours[key]?.close}
                                  </Typography>
                                </Stack>
                              </Grid>
                            );
                          })}
                        </Grid>
                      ) : (
                        <Typography variant="body1">Not set</Typography>
                      ),
                    },
                    {
                      label: "Delivery Enabled",
                      component: (
                        <Chip
                          color={
                            butchery.deliveryEnabled &&
                            butchery.deliveryEnabled === true
                              ? "primary"
                              : "default"
                          }
                          label={butchery.deliveryEnabled ? "Yes" : "No"}
                          sx={{ alignSelf: "flex-start" }}
                        />
                      ),
                    },
                    {
                      label: "Delivery Radius",
                      value: butchery.deliveryRadius
                        ? `${numberWithCommas(butchery.deliveryRadius)} km`
                        : "Not set",
                    },
                    {
                      label: "Delivery Fee Per Km",
                      value: butchery.deliveryFeePerKm
                        ? `${currency?.symbol}${numberWithCommas(
                            butchery.deliveryFeePerKm.toFixed(2)
                          )}`
                        : "Not set",
                    },
                  ].map(({ label, value, component }) => {
                    return (
                      <Stack>
                        <Typography variant="caption">{label}</Typography>
                        {component || (
                          <Typography variant="body1">{value}</Typography>
                        )}
                      </Stack>
                    );
                  })}
                </Stack>
                <Divider sx={{ my: 2, opacity: 0.25 }} />
                <Stack direction="row" spacing={2} alignItems="center">
                  <Button
                    disabled={isDeleting}
                    onClick={() => {
                      setEditOpen(true);
                    }}
                    startIcon={<HiPencil size={16} />}
                    color="inherit"
                    sx={{ textTransform: "none", borderRadius: 32 }}
                  >
                    Edit Details
                  </Button>
                  <Button
                    disabled={isDeleting}
                    endIcon={isDeleting ? <CircularProgress size={14} /> : null}
                    onClick={() => {
                      setConfirmationDialog({
                        open: true,
                        msg: `Are you sure you would like to delete this butchery?`,
                        action: async () => {
                          setConfirmationDialog((prev) => ({
                            ...prev,
                            open: false,
                          }));
                          try {
                            let result = await deleteButchery(id);
                            if (!result.error) {
                              navigate(-1);
                            }
                          } catch (e) {}
                        },
                      });
                    }}
                    startIcon={<IoMdTrash size={16} />}
                    color="inherit"
                    sx={{ borderRadius: 32, textTransform: "none" }}
                  >
                    Delete Butchery
                  </Button>
                </Stack>
              </Stack>
            )}
            {value === 1 && (
              <Stack
                spacing={3}
                sx={{
                  p: 2,
                  border: 1,
                  borderStyle: "dashed",
                  borderColor: "rgb(0, 0, 0, 0.1)",
                  borderRadius: 3,
                  mt: 2,
                }}
              >
                <ProductAvailability
                  butcheryId={id}
                  productAvailability={butchery?.productAvailability || []}
                />
              </Stack>
            )}
          </>
        )}
      </PageContainer>
      <AddOrUpdateButchery
        open={editOpen}
        populate={butchery}
        _id={id}
        onClose={() => {
          setEditOpen(false);
        }}
      />
      <ConfirmationDialog
        dialog={confirmationDialog}
        setDialog={setConfirmationDialog}
      />
    </>
  );
}
