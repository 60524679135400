import { apiSlice } from "../api.service";

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getReport: builder.query({
      query: (body) => ({
        url: `/report?startDate=${body.startDate}&endDate=${body.endDate}`,
        method: "GET",
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useLazyGetReportQuery } = extendedApi;
