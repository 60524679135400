import * as React from "react";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { useSelector } from "react-redux";
const logo = require("../../assets/mozbife_logo.jpg");

export default function MenuHeader({ colors = null }) {
  const user = useSelector((state) => state.user.user);

  return (
    <Stack>
      <Stack
        sx={{
          background: colors ? "white" : "#181818",
          p: 2,
          m: 1,
          borderRadius: 3,
          mt: 2,
          color: colors?.txt || "white",
          border: colors ? 1 : 0,
          borderColor: colors?.bg || "white",
          borderStyle: "dashed",
        }}
        direction="row"
        spacing={2}
      >
        <Stack
          sx={{
            height: 50,
            width: 50,
            borderRadius: "50%",
            background: "white",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            style={{
              height: 45,
              width: 45,
              borderRadius: "16px",
              objectFit: "contain",
            }}
            src={logo}
            alt=""
          ></img>
        </Stack>
        <Stack direction="column">
          <Typography variant="subtitle2">Welcome,</Typography>
          <Typography
            numberOfLines={1}
            style={{
              fontSize: 16,
              fontWeight: "bold",
              maxWidth: "145px",
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
            }}
          >
            Mozbife Admin
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
