import { apiSlice } from "../api.service";

let params = { limit: 25, skip: 0 };

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.query({
      query: (options) => {
        params = options;
        return {
          url: `/product?${new URLSearchParams(options).toString()}`,
          method: "GET",
        };
      },
      providesTags: [{ type: "Product", id: "LIST" }],
    }),
    getProduct: builder.query({
      query: (productID) => {
        return { url: `/product/${productID}`, method: "GET" };
      },
      providesTags: (result, error, arg) => [{ type: "Product", id: arg }],
    }),

    addNewProduct: builder.mutation({
      query: (product) => ({
        url: "/product",
        method: "POST",
        body: product,
      }),
      invalidatesTags: [{ type: "Product", id: "LIST" }, "Count"],
    }),
    editProduct: builder.mutation({
      query: (data) => ({
        url: `/product/${data._id}`,
        method: "PATCH",
        body: data.product,
      }),
      async onQueryStarted({ _id, product }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedProduct } = await queryFulfilled;

          dispatch(
            apiSlice.util.updateQueryData("getProducts", params, (draft) => {
              const product = draft.find((e) => e._id === updatedProduct._id);
              if (product) {
                Object.keys(updatedProduct).forEach((key) => {
                  product[key] = updatedProduct[key];
                });
              }
            })
          );
          dispatch(
            apiSlice.util.updateQueryData("getProduct", _id, (draft) => {
              Object.assign(draft, updatedProduct);
            })
          );
        } catch {}
      },
    }),

    deleteProduct: builder.mutation({
      query: (_id) => ({
        url: `/product/${_id}`,
        method: "DELETE",
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            apiSlice.util.updateQueryData("getProducts", undefined, (draft) => {
              const index = draft.findIndex((e) => e._id === _id);
              if (index !== -1) {
                draft.splice(index, 1);
              }
            })
          );
        } catch {}
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetProductsQuery,
  useGetProductQuery,
  useAddNewProductMutation,
  useEditProductMutation,
  useDeleteProductMutation,
  useLazyGetProductQuery,
} = extendedApi;
