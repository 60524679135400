import * as React from "react";

import Stack from "@mui/material/Stack";

import { HiPlus } from "react-icons/hi2";
import Button from "@mui/material/Button";
import Table from "./table/view";
import RouterBreadcrumbs from "../../../layouts/common/breadcrumbs/view";
import {
  PageTitle,
  PageContainer,
} from "../../../layouts/common/general/components";

export default function Orders() {
  const [open, setOpen] = React.useState(false);

  return (
    <PageContainer>
      <Stack
        direction="row"
        style={{
          alignItems: "baseline",
          justifyContent: "space-between",
          mt: 4,
        }}
      >
        <Stack>
          <Stack>
            <PageTitle title="Orders" />
          </Stack>
          <RouterBreadcrumbs />
        </Stack>
      </Stack>

      <Table />
    </PageContainer>
  );
}
