import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import * as React from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  RadioGroup,
  Radio,
  InputLabel,
  DialogActions,
  Collapse,
  Alert,
} from "@mui/material";
import { StatusChip } from "./list/table/view";
import Grid from "@mui/material/Grid";
import { useEditOrderMutation } from "../../api/api-endpoints/order";
import { useDispatch } from "react-redux";
import moment from "moment";
import CloseTwoTone from "@mui/icons-material/CloseTwoTone";
import { resetUploadProgress } from "../../state/slices/progress";
import IconButton from "@mui/material/IconButton";
import { Divider } from "@mui/material";

import { HiCheck, HiXMark } from "react-icons/hi2";
import { constants, numberWithCommas } from "../../config/constants";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const GoogleMapsLink = ({ latitude, longitude }) => {
  const url = `https://www.google.com/maps?q=${latitude},${longitude}`;

  return (
    <a
      style={{
        textDecorationLine: "underline",
        color: "blue",
      }}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      {url}
    </a>
  );
};

function calculateOrderTotal(order) {
  if (!order) {
    return 0;
  }
  try {
    let total = 0;

    for (let item of order.items) {
      const price = item.unitCost;

      total += price * item.quantity;
    }

    // Add delivery fee if applicable
    if (order.deliveryFee) {
      total += order.deliveryFee;
    }

    return total;
  } catch (error) {
    console.error(`Error calculating order total: ${error.message}`);
    throw error;
  }
}

export default function AddOrUpdateOrder({
  open,
  onClose,
  order = {},
  _id = null,
}) {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(resetUploadProgress([]));
  }, [dispatch]);

  const [editOrder, { isLoading: isUpdating }] = useEditOrderMutation();

  const saveOrder = async () => {
    try {
      let result = await editOrder({
        _id,
        order: {
          status,
        },
      });

      if (result.error) {
        if (result.error.data) {
          throw new Error(result.error.data.error);
        } else {
          throw new Error("Something went wrong...");
        }
      }

      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 4000);
    } catch (e) {
      console.log(e.message);
    }
  };

  const [showAlert, setShowAlert] = React.useState(false);

  const [status, setStatus] = React.useState("");

  React.useEffect(() => {
    order?.status && setStatus(order.status);
  }, [order]);

  const BasicDetails = () => {
    return (
      <>
        {[
          {
            label: "Customer's name",
            value: order?.customerName,
          },
          {
            label: "Phone Number",
            value: `+${order?.phone}`,
          },
        ].map(({ value, label }) => {
          return (
            <Stack sx={{ mb: 1.5 }}>
              <Typography variant="caption">{label}</Typography>
              <Typography>{value}</Typography>
            </Stack>
          );
        })}
      </>
    );
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      keepMounted={false}
      onClose={onClose}
      open={open}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          borderRadius: 3,
        },
      }}
    >
      <DialogTitle>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
        >
          <Stack>
            <Typography variant="h6">Manage Order</Typography>
            <Stack alignItems="center" spacing={1} direction="row">
              <StatusChip status={order?.status || "received"} />
              <Typography variant="caption">
                <b>{order?.orderNumber}</b> |{" "}
                {moment(order?.createdAt || new Date()).format("LL")}
              </Typography>
            </Stack>
          </Stack>
          <IconButton onClick={onClose} disabled={isUpdating}>
            <HiXMark />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent>
        <Stack
          sx={{
            flex: 1,
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                background: "rgba(0, 0, 0, 0.02)",
                borderRadius: 2,
                borderColor: "white",
              },
            },
          }}
        >
          <Grid sx={{ mt: 1 }} container spacing={2}>
            {order?.latitude && (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <BasicDetails />
                {[
                  {
                    label: "Delivery Address",
                    value: order?.address,
                  },
                  {
                    label: "Delivery Distance",
                    value: `${numberWithCommas(
                      order?.deliveryDistance?.toFixed(1)
                    )} km`,
                  },
                  {
                    label: "Delivery Fee",
                    value: `${constants.currency?.symbol}${numberWithCommas(
                      order?.deliveryFee?.toFixed(2)
                    )}`,
                  },
                ].map(({ value, label }) => {
                  return (
                    <Stack sx={{ mb: 1.5 }}>
                      <Typography variant="caption">{label}</Typography>
                      <Typography>{value}</Typography>
                    </Stack>
                  );
                })}
              </Grid>
            )}

            <Grid item xs={12} sm={12} md={6} lg={6}>
              {[
                {
                  label: "Butchery",
                  value: order?.butchery?.name,
                },
                {
                  label: "City",
                  value: order?.butchery?.city?.name,
                },
                {
                  label: "Contact",
                  value: `${order?.butchery?.contact} | ${order?.butchery?.phone}`,
                },
                {
                  label: <b>Order Total</b>,
                  value: (
                    <b>
                      {constants.currency?.symbol}
                      {numberWithCommas(calculateOrderTotal(order)?.toFixed(2))}
                    </b>
                  ),
                },
              ].map(({ value, label }) => {
                return (
                  <Stack sx={{ mb: 1.5 }}>
                    <Typography variant="caption">{label}</Typography>
                    <Typography>{value}</Typography>
                  </Stack>
                );
              })}
            </Grid>

            {!order?.latitude && (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <BasicDetails />
                {[
                  {
                    label: "Pick up date",
                    value: moment(order?.pickupDate).format("LL"),
                  },
                ].map(({ value, label }) => {
                  return (
                    <Stack sx={{ mb: 1.5 }}>
                      <Typography variant="caption">{label}</Typography>
                      <Typography>{value}</Typography>
                    </Stack>
                  );
                })}
              </Grid>
            )}
          </Grid>
          <Divider sx={{ my: 2, opacity: 0.25 }} />
          {order?.deliveryFee && (
            <>
              <Typography variant="caption">Delivery Location</Typography>

              <GoogleMapsLink
                latitude={order?.latitude}
                longitude={order?.longitude}
              />
              <Divider sx={{ my: 2, opacity: 0.25 }} />
            </>
          )}
          <Grid container spacing={3}>
            {order?.items?.map((item) => {
              const { product, quantity } = item;
              let price = item.unitCost;
              return (
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Stack
                    alignItems="center"
                    sx={{ mb: 2 }}
                    spacing={1}
                    direction="row"
                  >
                    <img
                      style={{
                        height: 60,
                        width: 60,
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                      alt=""
                      src={`${constants.apiUrl}/documents/${product?.gallery[0]}`}
                    />
                    <Stack>
                      <Typography>
                        <b>{product.translation}</b>
                      </Typography>
                      <Typography variant="caption">
                        {numberWithCommas(quantity.toFixed(1))} kg @{" "}
                        {constants?.currency?.symbol}
                        {numberWithCommas(price.toFixed(2))}/kg -{" "}
                        <b>
                          {constants?.currency?.symbol}
                          {numberWithCommas((price * quantity).toFixed(2))}
                        </b>
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              );
            })}
          </Grid>
          <Divider sx={{ my: 2, opacity: 0.25 }} />
          <Stack spacing={2}>
            <Stack sx={{ mt: 3 }}>
              <Typography variant="body2">Order Status</Typography>

              <RadioGroup
                onChange={(e, newValue) => {
                  setStatus(newValue);
                }}
                value={status}
              >
                <Stack spacing={2} direction="row">
                  <InputLabel sx={{}}>
                    <Stack
                      sx={{ cursor: "pointer" }}
                      alignItems="center"
                      spacing={0.5}
                      direction="row"
                    >
                      <Radio
                        sx={{ m: 0 }}
                        value={
                          order?.deliveryFee ? "dispatched" : "readyForPickup"
                        }
                      />
                      <Typography variant="caption">
                        {order?.deliveryFee ? "Dispatched" : "Ready for pickup"}
                      </Typography>
                    </Stack>
                  </InputLabel>
                  <InputLabel sx={{}}>
                    <Stack
                      sx={{ cursor: "pointer" }}
                      alignItems="center"
                      spacing={0.5}
                      direction="row"
                    >
                      <Radio
                        sx={{ m: 0 }}
                        value={order?.deliveryFee ? "delivered" : "pickedUp"}
                      />
                      <Typography variant="caption">
                        {order?.deliveryFee ? "Delivered" : "Picked up"}
                      </Typography>
                    </Stack>
                  </InputLabel>
                </Stack>
                <Stack>
                  <InputLabel sx={{}}>
                    <Stack
                      sx={{ cursor: "pointer" }}
                      alignItems="center"
                      spacing={0.5}
                      direction="row"
                    >
                      <Radio value={"cancelled"} />
                      <Typography variant="caption">Cancelled</Typography>
                    </Stack>
                  </InputLabel>
                </Stack>
              </RadioGroup>
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      <Collapse in={showAlert}>
        <Alert
          sx={{ mt: 3 }}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setShowAlert(false);
              }}
            >
              <CloseTwoTone fontSize="inherit" />
            </IconButton>
          }
        >
          Order Status updated
        </Alert>
      </Collapse>
      <DialogActions>
        <Button
          onClick={saveOrder}
          disabled={order?.status === status || isUpdating}
          startIcon={<HiCheck />}
          endIcon={isUpdating ? <CircularProgress size={14} /> : null}
          disableElevation
          sx={{
            mr: 1,
            ml: 1,
            height: 50,
            borderRadius: 32,
            textTransform: "none",
          }}
        >
          Save Changes
        </Button>
        <Button
          type="reset"
          color="warning"
          onClick={onClose}
          startIcon={<HiXMark />}
          disabled={isUpdating}
          disableElevation
          sx={{
            mr: 1,
            ml: 1,
            height: 50,
            borderRadius: 32,
            textTransform: "none",
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
