import { useRouteError } from "react-router-dom";
import Stack from "@mui/material/Stack";

export default function Unauthorized() {
  return (
    <Stack
      sx={{
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <div id="error-page">
        <h1>Oops!</h1>
        <p>Sorry, you are not allowed to access this page.</p>
      </div>
    </Stack>
  );
}
