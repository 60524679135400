import {
  CiLocationArrow1,
  CiLogout,
  CiMemoPad,
  CiSettings,
  CiShoppingBasket,
  CiUser,
  CiWavePulse1,
  CiGift,
  CiPhone,
} from "react-icons/ci";

const iconSize = 20;

const appMenu = (role) => {
  return [
    {
      id: "orders",
      title: "Orders",
      icon: <CiMemoPad size={iconSize} />,
    },

    {
      id: "products",
      title: "Products",
      icon: <CiShoppingBasket size={iconSize} />,
    },
  ];
};

/**
 *   {
      id: "contacts",
      title: "Contacts",
      icon: <CiPhone size={iconSize} />,
    },
    {
      id: "specials",
      title: "Specials",
      icon: <CiGift size={iconSize} />,
    },
    
 */

/**
 *  
 */

const managementMenu = (role) => {
  return [
    {
      id: "outlets",
      title: "Outlets",
      icon: <CiLocationArrow1 size={iconSize} />,
      permissions: ["manage_approval_routing"],
    },
    {
      id: "users",
      title: "Users",
      icon: <CiUser size={iconSize} />,
      permissions: ["add_user"],
    },
    {
      id: "reporting",
      title: "Reporting",
      icon: <CiWavePulse1 size={iconSize} />,
    },
    {
      id: "settings",
      title: "Settings",
      icon: <CiSettings size={iconSize} />,
      permissions: ["add_user"],
    },
  ];
};

/**
 *   {
      id: "logout",
      title: "Logout",
      icon: <CiLogout size={iconSize} />,
      permissions: ["add_user"],
    },
 */
const platformMenu = (role) => {
  return [];
};

export { platformMenu, appMenu, managementMenu };
