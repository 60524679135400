import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import * as React from "react";
import {
  Button,
  CircularProgress,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
} from "@mui/material";
import { useState } from "react";
import Grid from "@mui/material/Grid";
import { systemRoles } from "../../../config/constants";
import LinearProgress from "@mui/material/LinearProgress";
import {
  useLazyGetManagerialLineResourcesQuery,
  useAddNewManagerialLineMutation,
} from "../../../api/api-endpoints/managerialLine";

import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseTwoTone from "@mui/icons-material/CloseTwoTone";
import Alert from "@mui/material/Alert";
import { Divider } from "@mui/material";

import { HiCheck, HiXMark } from "react-icons/hi2";

const TitleDescription = ({ title, description }) => (
  <>
    <Typography>
      <b>{title}</b>
    </Typography>
    <Typography variant="caption">{description}</Typography>
  </>
);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddOrUpdateManagerialLine({ open, onClose }) {
  const [managerialLine, setManagerialLine] = useState({
    name: "",
    lineManager: null,
  });

  const [addManagerialLine, { isLoading: isSaving }] =
    useAddNewManagerialLineMutation();

  const createManagerialLine = async () => {
    try {
      let result = await addManagerialLine(managerialLine);
      if (result.error) {
        if (result.error.data) {
          throw new Error(result.error.data.error);
        } else {
          throw new Error("Something went wrong...");
        }
      }
      setManagerialLine({
        name: "",
        lineManager: null,
      });

      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 4000);
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleBlur = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const [showAlert, setShowAlert] = React.useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setManagerialLine((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [
    getRes,
    {
      data: managerialLineResources = {
        lineManagers: [],
      },
      isLoading,
    },
  ] = useLazyGetManagerialLineResourcesQuery();

  React.useEffect(() => {
    open && getRes();
  }, [open, getRes]);

  return (
    <Dialog
      TransitionComponent={Transition}
      keepMounted={false}
      onClose={onClose}
      open={open}
      fullWidth
      maxWidth="xs"
      PaperProps={{
        sx: {
          borderRadius: 2,
        },
      }}
    >
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">
            <b>Add a team</b>
          </Typography>
          <IconButton onClick={onClose} disabled={isSaving}>
            <HiXMark />
          </IconButton>
        </Stack>
      </DialogTitle>

      <Divider sx={{ mb: 1, opacity: 0.35 }} />

      <DialogContent>
        <Stack
          sx={{
            flex: 1,

            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                background: "rgba(0, 0, 0, 0.02)",
                borderRadius: 2,
                borderColor: "white",
              },
            },
          }}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              createManagerialLine();
            }}
          >
            <Grid sx={{}} container spacing={3}>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  required
                  fullWidth
                  sx={{ mb: 3 }}
                  onBlur={handleBlur}
                  name="name"
                  label="Team name"
                  value={managerialLine.name}
                  onChange={handleChange}
                />
                <Autocomplete
                  value={
                    managerialLine.lineManager &&
                    managerialLineResources?.users?.find(
                      (a) => a._id === managerialLine.lineManager
                    )
                  }
                  onChange={(e, newValue) => {
                    setManagerialLine((managerialLine) => ({
                      ...managerialLine,
                      lineManager: newValue ? newValue._id : null,
                    }));
                  }}
                  getOptionLabel={(item) =>
                    `${item.firstName} ${item.lastName} -  ${
                      systemRoles.find((x) => x.value === item.role).label
                    }`
                  }
                  options={managerialLineResources?.users || []}
                  loading={isLoading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      onBlur={handleBlur}
                      label="Select line manager"
                      inputPros={{
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {isLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12}>
                <Collapse in={showAlert}>
                  <Alert
                    sx={{ mt: 3 }}
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setShowAlert(false);
                        }}
                      >
                        <CloseTwoTone fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    New team added successfully!
                  </Alert>
                </Collapse>
              </Grid>
            </Grid>

            <Stack sx={{ mt: 3 }}>
              <Collapse in={isLoading || isSaving}>
                <LinearProgress sx={{ borderRadius: 32, mt: 2, mb: 1 }} />
              </Collapse>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                  <Stack spacing={2} justifyContent="flex-end" direction="row">
                    <Button
                      type="submit"
                      endIcon={<HiCheck />}
                      disabled={isSaving}
                      disableElevation
                      sx={{
                        mr: 1,
                        ml: 1,
                        height: 50,
                        borderRadius: 32,
                        textTransform: "none",
                      }}
                      variant="contained"
                    >
                      Save Details
                    </Button>
                    <Button
                      type="reset"
                      color="warning"
                      onClick={onClose}
                      endIcon={<HiXMark />}
                      disabled={isSaving}
                      disableElevation
                      sx={{
                        mr: 1,
                        ml: 1,
                        height: 50,
                        borderRadius: 32,
                        textTransform: "none",
                      }}
                      variant="outlined"
                    >
                      Close
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </form>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
