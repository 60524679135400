import { apiSlice } from "../api/api.service";

/**
 * this module receives push notifications from the server
 * and deletes, updates, or adds items to the specified res
 * list
 */

const mappings = {
  order: {
    tag: {
      type: "Order",
      id: "LIST",
    },
    listFetcher: "getOrders",
    itemFetcher: "getOrder",
    endpoint: "/order",
  },


  user: {
    tag: {
      type: "User",
      id: "LIST",
    },
    listFetcher: "getUsers",
    itemFetcher: "getUser",
    endpoint: "/users",
  },

 
};

const handleSync = (params) => {
  const { verb, payload, pagination, resource } = params;

  const { rowsPerPage = 1000000, skip_id, q, page, filters } = pagination || {};
  let queryOptions =
    resource === "managerialLine"
      ? undefined
      : {
          skip: q.trim() === "" ? skip_id : page * rowsPerPage,
          limit: rowsPerPage,
          ...filters,
          q: q.trim(),
        };

  let endpointQueries = [];
  let matchingQueries = [];
  let keys = Object.keys(params.queries);

  switch (verb) {
    case "delete":
      keys.forEach((key) => {
        if (key.indexOf(mappings[resource].listFetcher) !== -1) {
          endpointQueries.push({ ...params.queries[key] });
        }
      });
      endpointQueries.forEach((query) => {
        let hasMatch = false;
        let matching_ids = [];
        payload.forEach((item) => {
          params.dispatch(
            apiSlice.util.invalidateTags([
              {
                type: mappings[resource].tag.type,
                id: item,
              },
            ])
          );
          if (query.data.findIndex((x) => x._id === item) !== -1) {
            hasMatch = true;
            matching_ids.push(item);
          }
        });
        if (hasMatch) {
          matchingQueries.push({ query, matching_ids });
        }
      });

      matchingQueries.forEach(({ matching_ids, query }) => {
        matching_ids.forEach((_id) => {
          params.dispatch(
            apiSlice.util.updateQueryData(
              mappings[resource].listFetcher,
              query.originalArgs,
              (draft) => {
                const item = draft.find((e) => e._id === _id);
                item._deleted = true;
              }
            )
          );
        });
      });

      refreshCount(params.dispatch);

      break;

    case "bulk":
      params.dispatch(
        apiSlice.util.updateQueryData(
          mappings[resource].listFetcher,
          queryOptions,
          (draft) => {
            let notFull = draft.length < rowsPerPage;
            if (notFull) {
              params.dispatch(
                apiSlice.util.invalidateTags([mappings[resource].tag])
              );
            } else {
              refreshCount(params.dispatch);
            }
          }
        )
      );

      break;
    case "add":
      params.dispatch(
        apiSlice.util.updateQueryData(
          mappings[resource].listFetcher,
          queryOptions,
          (draft) => {
            let notFull = draft.length < rowsPerPage;
            if (notFull) {
              draft.push(payload);
            } else {
              refreshCount(params.dispatch);
            }
          }
        )
      );

      break;
    case "update":
      params.dispatch(
        apiSlice.util.invalidateTags([
          {
            type: mappings[resource].tag.type,
            id: payload._id,
          },
        ])
      );
      keys.forEach((key) => {
        if (key.indexOf(mappings[resource].listFetcher) !== -1) {
          endpointQueries.push({ ...params.queries[key] });
        }
      });
      endpointQueries.forEach((query) => {
        let hasMatch = false;
        let matching_ids = [];
        [payload._id].forEach((item) => {
          if (query.data.findIndex((x) => x._id === item) !== -1) {
            hasMatch = true;
            matching_ids.push(item);
          }
        });
        if (hasMatch) {
          matchingQueries.push({ query, matching_ids });
        }
      });

      matchingQueries.forEach(({ matching_ids, query }) => {
        matching_ids.forEach((_id) => {
          params.dispatch(
            apiSlice.util.updateQueryData(
              mappings[resource].listFetcher,
              query.originalArgs,
              (draft) => {
                const item = draft.find((e) => e._id === _id);
                if (item) {
                  Object.keys(payload).forEach((key) => {
                    item[key] = payload[key];
                  });
                }
              }
            )
          );
        });
      });

      break;
    default:
  }
};

const refreshCount = (dispatch) => {
  dispatch(
    apiSlice.util.invalidateTags([
      {
        type: "Count",
      },
    ])
  );
};

export { handleSync };
