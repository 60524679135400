/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import {
  LinearProgress,
  Chip,
  Typography,
  CircularProgress,
} from "@mui/material";
import TablePaginationComponent from "../../../../layouts/common/pagination/view";

import { IconButton } from "@mui/material";
import { IoArrowForward } from "react-icons/io5";
import {
  TableRow,
  TableHeader,
  TableContainer,
} from "../../../../layouts/common/table/view";
import Stack from "@mui/material/Stack";
import AddOrUpdateProduct from "../../addOrUpdate";
import { capitalizeWords } from "../../../../utils/functions";
import {
  useGetProductsQuery,
  useLazyGetProductQuery,
} from "../../../../api/api-endpoints/product";
import TableOptions from "./table_options";
import { useSelector } from "react-redux";
import { constants } from "../../../../config/constants";
import { numberWithCommas } from "../../../../config/constants";
// Define the resource name for this component.
const resource = "product";

const ProductAction = ({ _id, setEditOpen }) => {
  const [getProduct, state] = useLazyGetProductQuery();

  const { isLoading, isFetching } = state;

  return (
    <IconButton
      onClick={async () => {
        try {
          let result = await getProduct(_id).unwrap();
          setEditOpen({ open: true, _id, populate: result });
        } catch (e) {}
      }}
    >
      {isLoading || isFetching ? (
        <CircularProgress size={15} />
      ) : (
        <IoArrowForward />
      )}
    </IconButton>
  );
};

/**
 * Functional component that renders a table of products with various actions.
 */
export default function Table() {
  // Retrieve pagination and filter settings from Redux state.
  const { rowsPerPage, skip_id, q, page, filters } = useSelector(
    (state) => state.pagination
  ).product;

  // Use a query hook to fetch product data based on the current pagination and filter settings.
  const {
    data: products = [],
    isLoading,
    isFetching,
    refetch,
  } = useGetProductsQuery({
    skip: q.trim() === "" ? skip_id : page * rowsPerPage,
    limit: rowsPerPage,
    ...filters,
    q: q.trim(),
  });

  // Maintain state for selected product items.
  const [selected, setSelected] = React.useState([]);

  // Configuration for the table.
  const tableConfig = {
    headings: [
      {
        title: "Name (ENG)",
        key: "name",
        grid: {
          xs: 6,
          sm: 6,
          md: 4,
          lg: 4,
        },
      },
      {
        title: "Name (PT)",
        key: "translation",
        grid: {
          xs: 0,
          sm: 0,
          md: 2,
          lg: 2,
        },
      },
      {
        title: "Default Price",
        key: "defaultPrice",
        grid: {
          xs: 4,
          sm: 4,
          md: 2,
          lg: 2,
        },
      },
      {
        title: "Unit Type",
        key: "unitType",
        grid: {
          xs: 0,
          sm: 2,
          md: 2,
          lg: 2,
        },
      },

      {
        title: "Action",
        grid: {
          xs: 2,
          sm: 2,
          md: 2,
          lg: 1,
        },
      },
    ],
    actionGrid: {
      xs: 2,
      sm: 2,
      md: 2,
      lg: 1,
    },
    enableCheck: false,
    preprocessColumnData: (item, schemaItem) => {
      let chipColor;
      let chipLabel;
      switch (item.status) {
        case "available":
          chipLabel = "Available";
          chipColor = "primary";
          break;
        case "unavailable":
          chipLabel = "Unavailable";
          chipColor = "warning";
          break;
        case "maintenance":
          chipLabel = "Maintenance";
          chipColor = "warning";
          break;
        default:
          chipColor = "default";
          chipLabel = "Available";
      }
      return schemaItem === "status" ? (
        <Chip color={chipColor} label={chipLabel} />
      ) : schemaItem === "defaultPrice" ? (
        `${constants?.currency?.symbol}${numberWithCommas(
          item.defaultPrice?.toFixed(2)
        )}`
      ) : item[schemaItem] && item[schemaItem] !== "" ? (
        item[schemaItem]
      ) : (
        "Not set"
      );
    },
  };

  const [editOpen, setEditOpen] = React.useState({
    open: false,
    _id: null,
    populate: null,
  });

  return (
    <TableContainer>
      {/* Render table options component */}
      {false && (
        <TableOptions
          filters={filters}
          resource={resource}
          refetch={refetch}
          isLoading={isLoading}
          isDeleting={false}
          selected={selected}
          setSelected={setSelected}
        />
      )}

      {/* Render a linear progress indicator during loading */}
      {(isLoading || isFetching) && <LinearProgress />}

      {/* Render table header */}
      <TableHeader
        enableCheck={tableConfig.enableCheck}
        headings={tableConfig.headings}
        disable={isLoading}
        allSelected={products.length === selected.length}
        setSelected={(all) => {
          if (all) {
            setSelected(products.map((e) => e._id));
          } else {
            setSelected([]);
          }
        }}
      />

      <Stack
        sx={{
          borderTopWidth: 0,
          borderRadius: 2,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
         
        }}
      >
        {/* Render table rows */}
        {products.map((item, index) => {
          return (
            <TableRow
              size={products.length}
              key={item._id}
              firstColumn={() => {
                return (
                  <Stack sx={{ pl: 2 }} spacing={1} direction="row">
                    <img
                      style={{
                        height: 60,
                        width: 60,
                        borderRadius: 12,
                        objectFit: "cover",
                      }}
                      alt=""
                      src={`${constants.apiUrl}/documents/${item.gallery[0]}`}
                    />
                    <Stack>
                      <Typography>
                        <b>{item.name}</b>
                      </Typography>
                      <Typography variant="caption">
                        {capitalizeWords(item.productType)}
                      </Typography>
                    </Stack>
                  </Stack>
                );
              }}
              actionGrid={tableConfig.actionGrid}
              enableCheck={tableConfig.enableCheck}
              index={index}
              schema={tableConfig.headings.filter((x) => x.key != null)}
              disable={isLoading}
              preprocessColumnData={tableConfig.preprocessColumnData}
              setSelected={setSelected}
              selected={selected.includes(item._id)}
              item={item}
              additionalColumn={
                <ProductAction _id={item._id} setEditOpen={setEditOpen} />
              }
            />
          );
        })}
      </Stack>

      <Stack sx={{ pr: 3, mt: 1, mb: 1 }}>
        {/* Render pagination component */}
        <TablePaginationComponent
          loading={isFetching || isLoading}
          deletions={products.filter((product) => product._deleted)}
          filters={filters}
          refetch={refetch}
          lastItemId={
            products.length > 0 ? products[products.length - 1]._id : ""
          }
          resource={resource}
          data_size={products.length}
        />
      </Stack>
      <AddOrUpdateProduct
        _id={editOpen._id}
        populate={editOpen.populate}
        open={editOpen.open}
        onClose={() => {
          setEditOpen((prev) => ({ ...prev, open: false }));
        }}
      />
    </TableContainer>
  );
}
